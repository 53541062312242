























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
import ListeningGroup from '@/models/group/ListeningGroup';
import SmallGroup from '@/components/group/SmallGroup.vue';
import SubscriptionStatus from '@/enums/group/subscriptionStatus';
import TrendingSkeleton from '@/components/skeletons/TrendingSkeleton.vue';

@Component({
  components: {
    SmallGroup,
    TrendingSkeleton,
  },
})
export default class SmallGroupContainer extends Vue {
  @Prop({required: true}) groups!: Array<Group>;
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({default: false}) isMobile!: boolean;
  @Prop({default: ''}) username!: string;

  isLoading = false;
  updatedGroup: Group|undefined = undefined;

  get APPROVED() {
    return SubscriptionStatus.APPROVED;
  }

  get foundGroups(): Array<ListeningGroup> {
    const groups = this.groups.map((grp: Group) => {
      const myGrp = this.myGroups.find((lg: ListeningGroup) => lg.groupId === grp.id);
      const useUpdatedGroup = this.updatedGroup && this.updatedGroup.id === grp.id ? true : false;
      const isNotificationGroup = this.newGroupNotificationNames.some((name) => name === grp.name);
      const newListenerRequests = grp.requestedList ? grp.requestedList.length : 0;
      const listGrp: ListeningGroup = new ListeningGroup({
        group: useUpdatedGroup ? {...this.updatedGroup} : grp,
        groupId: grp.id,
        isFollowing: myGrp && (myGrp.isFollowing || myGrp.status === this.APPROVED),
        isOwner: myGrp && myGrp.isOwner,
        newListenerRequests,
        postsBehind: isNotificationGroup && myGrp && myGrp.postsBehind ? 1 : 0,
        status: myGrp ? myGrp.status : '',
      });

      if (useUpdatedGroup) this.updatedGroup = undefined;
      
      return listGrp;
    });

    groups.sort((a, b) => a.postsBehind > b.postsBehind ? -1 : a.postsBehind < b.postsBehind ? 1 : 0);
    return groups;
  }

  get myGroups(): Array<ListeningGroup> {
    return this.$store.getters['myGroups'];
  }

  get newGroupNotificationNames(): Array<string> {
    return this.$store.getters['newGroupNotificationNames'];
  }

  changeFollowing(id: string) {
    if (!this.isLoggedIn || !id) return;

    this.$store.dispatch('updateFollowingGroup', id)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          this.updatedGroup = this.groups.find((grp) => grp.id === id);
          if (this.updatedGroup) {
            const added = this.myGroups.some((grp) => grp.groupId === id);
            this.updatedGroup.listeners += added ? 0 : -1;
          } else {
            this.updatedGroup = undefined;
          }
        }
      })
      .catch(() => failure(this, {message: 'Error changing listening status. Please try again later.'}));
  }
}
