

















import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class NewSearchSection extends Vue {
  openNewModal() {
    this.$store.dispatch('openNewGroupModal');
  }

  openSearchModal() {
    this.$router.push('/search/groups');
  }
}
