
































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Location extends Vue {
  @Prop({required: true}) currentLocations!: Array<string>;

  location = '';
  locations: Array<string> = [];

  created() {
    this.locations = this.currentLocations ? this.currentLocations : [];
  }

  addLocation() {
    if (!this.location || this.location.trim().length === 0) return;

    this.locations.splice(0, 0, this.location);
    this.location = '';
    this.emitLocations();
  }

  emitLocations() {
    this.$emit('locations', this.locations.map((loc: string) => loc.toLowerCase()));
  }

  removeLocation(location: string) {
    const index = this.locations.findIndex((loc: string) => loc === location);
    if (index > -1) {
      this.locations.splice(index, 1);
    }
    this.emitLocations();
  }
}
