export default class Contact {
  id: string = '';
  message: string = '';
  notes: string = '';
  resolved: boolean = false;
  topic: string = '';
  username: string = '';

  constructor({id, message, notes, resolved, topic, username}: Contact) {
    this.id = id ? id : '';
    this.message = message ? message : '';
    this.notes = notes ? notes : '';
    this.resolved = resolved != null ? resolved : false;
    this.topic = topic ? topic : '';
    this.username = username ? username : '';
  }
}
