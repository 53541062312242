

































import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Chain from '@/models/shout/Chain';
import Echo from '@/components/shout/Echo.vue';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
import Shout from '@/components/shout/Shout.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    Echo,
    MediaSkeleton,
    Shout,
  },
})
export default class ShoutChain extends UserMixin {
  chain: Chain|null = null;
  isLoading = false;

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  created() {
    const params = this.$route.params;
    const id = params ? params.id : '';

    this.getData(id);
  }

  getData(id: string) {
    this.isLoading = true;

    this.$store.dispatch('getChain', id)
      .then((chain: Chain|null) => {
        if (chain && chain.originalShout) {
          this.chain = chain;
        } else {
          failure(this, {message: 'Error loading shout chain. Please try again later.'})
        }
      })
      .catch(() => failure(this, {message: 'Error loading shout chain. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
