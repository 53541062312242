







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ReportHeader extends Vue {
  @Prop({required: true}) reason!: string;
}
