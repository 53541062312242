
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import BasicLogin from '@/models/login/BasicLogin';
import ConfirmForm from '@/components/login/ConfirmForm.vue';
import SignUpForm from '@/components/login/SignUpForm.vue';

@Component({
  components: {
    ConfirmForm,
    SignUpForm,
  },
})
export default class SignUp extends Vue {
  basicLogin: BasicLogin | null = null;
  isModalActive = false;
  showConfirmBox = false;

  get isSignUpModalActive() {
		return this.$store.getters['isSignUpModalActive'];
	}

  @Watch('isSignUpModalActive')
	modalActiveChanged(newVal: boolean) {
		this.isModalActive = newVal;
	}

  close() {
    this.$store.dispatch('closeSignUpModal');
    this.showConfirmBox = false;
	}

  validatedUser(basicLogin: BasicLogin) {
    if (!basicLogin) return;
    this.basicLogin = basicLogin;
    this.showConfirmBox = true;
  }
}
