import FromAmountCall from '@/models/calls/FromAmountCall';
import FullPost from '@/models/shout/FullPost';
import Group from '@/models/group/Group';
import GroupUsers from '@/models/group/GroupUsers';
import ListeningGroup from '@/models/group/ListeningGroup';
import Search from '@/models/search/Search';
import User from '@/models/user/User';

const Groups = {
  getters: {
    isEditGroupModalActive: (state: any) => state.isEditGroupModalActive,
    isNewGroupModalActive: (state: any) => state.isNewGroupModalActive,
    isUserGroupModalActive: (state: any) => state.isUserGroupModalActive,
    myGroups: (state: any) => state.myGroups,
  },
  actions: {
    addGroup(context: any, group: ListeningGroup) {
      context.commit('addGroup', group);
    },
    addNewGroup(context: any, group: Group) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAddNewGroup', group)
            .then((group: Group) => resolve(group));
        } catch {
          reject(null);
        }
      });
    },
    approveGroupUser(context: any, { username, groupId }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverApproveGroupUser', {username, groupId})
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    checkGroupName(context: any, name: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverCheckGroupName', name)
            .then((isTaken: boolean) => resolve(isTaken));
        } catch {
          reject(true);
        }
      });
    },
    closeEditGroupModal(context: any) {
      context.commit('setIsEditGroupModalActive', {isOpen: false, group: null});
    },
    closeNewGroupModal(context: any) {
      context.commit('setIsNewGroupModalActive', false);
    },
    closeUserGroupModal(context: any) {
      context.commit('setIsUserGroupModalActive', {isOpen: false, group: null, groupId: null});
    },
    deleteGroup(context: any, groupId: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverDeleteGroup', groupId)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    denyGroupUser(context: any, { username, groupId }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverDenyGroupUser', {username, groupId})
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    getGroup(context: any, name: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetGroupByName', name)
            .then((listGroup: ListeningGroup) => resolve(listGroup));
        } catch {
          reject(null);
        }
      });
    },
    getGroupForEdit(context: any, id: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetGroupForEdit', id)
            .then((group: Group) => resolve(group));
        } catch {
          reject(null);
        }
      });
    },
    getGroupUsers(context: any, name: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetGroupUsers', name)
            .then((users: Array<string>) => resolve(users));
        } catch {
          reject(new GroupUsers({}));
        }
      });
    },
    getMyGroups(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMyGroups')
            .then((groups: Array<Group>) => resolve(groups));
        } catch {
          reject([]);
        }
      });
    },
    getGroupsByUser(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetGroups', username)
            .then((groups: Array<Group>) => resolve(groups));
        } catch {
          reject([]);
        }
      });
    },
    getPopularGroupPosts(context: any, name: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetPopularGroupPosts', name)
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch {
          reject([]);
        }
      });
    },
    getRecentGroupPosts(context: any, name: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetRecentGroupPosts', name)
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch {
          reject([]);
        }
      });
    },
    openEditGroupModal(context: any, name: string) {
      context.commit('setIsEditGroupModalActive', {isOpen: true, group: name});
    },
    openNewGroupModal(context: any) {
      context.commit('setIsNewGroupModalActive', true);
    },
    openUserGroupModal(context: any, { name, groupId }: any) {
      context.commit('setIsUserGroupModalActive', {isOpen: true, group: name, groupId: groupId});
    },
    removeGroup(context: any, groupId: string) {
      context.commit('removeGroup', groupId);
    },
    reportGroup(context: any, { id, reportReason, username }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverReportGroup', {id, reportReason, username})
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    searchGroups(context: any, search: Search) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverSearchGroups', search)
            .then((results: Search) => resolve(results));
        } catch {
          reject(null);
        }
      });
    },
    setMyGroups(context: any, groups: Array<ListeningGroup>) {
      context.commit('setMyGroups', groups);
    },
    updateFollowingGroup(context: any, id: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateFollowingGroup', id)
            .then((isFollowing: boolean) => resolve(isFollowing));
        } catch {
          reject(false);
        }
      });
    },
    updateGroup(context: any, group: Group) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateGroup', group)
            .then((group: Group) => resolve(group));
        } catch {
          reject(null);
        }
      });
    },
    updateGroupSubscriptionNotification(context: any, name: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateGroupSubscriptionNotification', name)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    uploadGroupBanner(context: any, { file, groupId }) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUploadGroupBanner', {file, groupId})
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
  },
  mutations: {
    addGroup(state: any, group: ListeningGroup) {
      const groups = [...state.myGroups];
      if (!state.myGroups.some((grp: ListeningGroup) => grp.groupId === group.groupId)) {
        groups.splice(0, 0, group);
        state.myGroups = [...groups];
      }
    },
    removeGroup(state: any, groupId: string) {
      const groups = state.myGroups;
      const index = groups.findIndex((grp: ListeningGroup) => grp.id === groupId);
      if (index > -1) {
        groups.splice(index, 1);
        state.myGroups = [...groups];
      }
    },
    setIsEditGroupModalActive(state: any, { isOpen, group }: any) {
      state.isEditGroupModalActive = {isOpen, group};
    },
    setIsNewGroupModalActive(state: any, isOpen: boolean) {
      state.isNewGroupModalActive = isOpen;
    },
    setIsUserGroupModalActive(state: any, { isOpen, group, groupId }: any) {
      state.isUserGroupModalActive = {isOpen, group, groupId};
    },
    setMyGroups(state: any, groups: Array<ListeningGroup>) {
      state.myGroups = [...groups];
    },
  },
  state: {
    isEditGroupModalActive: {isOpen: false, group: null},
    isNewGroupModalActive: false,
    isUserGroupModalActive: {isOpne: false, group: null, groupId: null},
    myGroups: [],
  },
};

export default Groups;
