
























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import { setShortDateFormat } from '@/utility/main';
import ActionTaken from '@/enums/admin/actionTaken';
import AdminEditProfile from '@/components/admin/AdminEditProfile.vue';
// eslint-disable-next-line no-unused-vars
import ReportedProfile from '@/models/admin/ReportedProfile';

@Component({
  components: {
    AdminEditProfile,
  },
})
export default class ReportedProfileEdit extends Vue {
  @Prop({required: true}) isActive!: boolean;
  @Prop({required: true}) profile!: ReportedProfile;

  actionTaken = false;
  createdAt = '';
  isAdminEditActive = false;
  isLoading = false;
  isModalActive = false;
  notes = '';
  reportedBy = '';
  reportReason = '';
  resolved = false;
  successMsg = '';
  username = '';

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
    if (newVal) this.resetData();
  }

  @Watch('profile')
  contactChanged(newVal: ReportedProfile) {
    if (newVal) this.resetData();
  }

  created() {
    this.isModalActive = this.isActive;
    this.resetData();
  }

  close(isSuccess: boolean = false) {
    this.$emit('close', isSuccess);
  }

  editProfileClosed(wasUpdated: any) {
    this.isAdminEditActive = false;

    if (wasUpdated === true) {
      this.actionTaken = true;
    }
  }

  report() {
    // This will report the user.
    const reportUser = () => {
      this.isLoading = true;

      this.$store.dispatch('adminReportUser', {username: this.profile.username, profileId: this.profile.id})
        .then((isSuccess: boolean) => {
          if (isSuccess) {
            this.successMsg = 'Profile reported!';
            this.updateReportedProfile(true);
          } else {
            this.isLoading = false;
            failure(this, {message: 'Error reporting the user. Reporting cancelled.'});
          }
        })
        .catch(() => {
          this.isLoading = false;
          failure(this, {message: 'Error reporting the user. Reporting cancelled.'});
        });
    };

    this.$buefy.dialog.confirm({
      title: 'Reporting User',
      message: 'Are you sure you want to <b>report</b> this User? This cannot be undone.',
      confirmText: 'Report User',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: reportUser,
    });
  }

  resetData() {
    if (!this.profile) return;

    this.actionTaken = !!this.profile.actionTaken;
    this.createdAt = setShortDateFormat(new Date(this.profile['createdAt']).getTime());
    this.notes = this.profile.notes;
    this.reportedBy = this.profile.reportedBy;
    this.reportReason = this.profile.reportReason;
    this.resolved = this.profile.resolved;
    this.username = this.profile.username;
  }

  updateReportedProfile(wasReported = false) {
    this.isLoading = true;

    const actionTaken = wasReported ? ActionTaken.REPORTED :
      this.profile.actionTaken ? this.profile.actionTaken :
        this.actionTaken ? ActionTaken.EDITED : '';

    const updatedProfile = {
      actionTaken,
      id: this.profile.id,
      notes: this.notes,
      reportedBy: this.profile.reportedBy,
      reportReason: this.profile.reportReason,
      resolved: this.resolved,
      username: this.profile.username,
    };

    this.$store.dispatch('updateAdminReportedProfile', updatedProfile)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          if (!this.successMsg) this.successMsg = 'Profile updated!';
          success(this, {message: this.successMsg});
          this.close(true);
        } else {
          failure(this, {message: 'Error updating the profile. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the profile. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
