import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

import awsconfig from '@/aws-exports';

import * as custom from '@/graphql/custom';

import * as sorts from '@/enums/calls/sortBy';
import { MyHashtags, Notification } from '@/models/user/MyHashtags';
import { PostStatus } from '@/models';
import { FILE_SIZE_LIMIT, SERVER_SIDE_ERROR_CATCHING } from '@/config/main';
import BasicLogin from '@/models/login/BasicLogin';
import Blocks from '@/models/user/Blocks';
import Chain from '@/models/shout/Chain';
import Contact from '@/models/admin/Contact';
import EchoNotification from '@/models/shout/EchoNotification';
import ErrorCatch from '@/models/admin/ErrorCatch';
import FullPost from '@/models/shout/FullPost';
import Group from '@/models/group/Group';
import GroupTrending from '@/models/trending/Group';
import GroupUsers from '@/models/group/GroupUsers';
import Hashtag from '@/models/trending/Hashtag';
import HashtagNotification from '@/models/search/HashtagNotification';
import HashtagResults from '@/models/search/HashtagResults';
import Listen from '@/models/user/Listen';
import Listening from '@/models/user/Listening';
import ListeningGroup from '@/models/group/ListeningGroup';
import Mention from '@/models/trending/Mention';
import Mentions from '@/models/user/Mentions';
import MyEchoNotifications from '@/models/shout/MyEchoNotifications';
import MyNotifications from '@/models/user/MyNotifications';
import OfficialPost from '@/models/shout/OfficialPost';
import Post from '@/models/shout/Post';
import PostFamily from '@/models/shout/PostFamily';
import PostNumbers from '@/models/shout/PostNumbers';
import Profile from '@/models/user/Profile';
import ReportedGroup from '@/models/admin/ReportedGroup';
import ReportedNumbers from '@/models/admin/ReportedNumbers';
import ReportedPost from '@/models/admin/ReportedPost';
import ReportedProfile from '@/models/admin/ReportedProfile';
import ReportedUser from '@/models/admin/ReportedUser';
import Search from '@/models/search/Search';
import SubscriptionStatus from '@/enums/group/subscriptionStatus';
import Trending from '@/models/trending/Trending';
import Type from '@/enums/shout/type';
import User from '@/models/user/User';
import ViewOption from '@/enums/profile/viewOption';

// Server sepecific constants
const API_KEY = ('API_KEY' as any);
const IMAGE_LOC = `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_cognito_region}.amazonaws.com/public/`;

// Server specific calls
const CAN_POST_TO_GROUP = 'serverCanPostToGroup';
const CATCH_ERROR = 'serverCatchError';
const CHECK_FOR_HASHTAG_SUB = 'serverCheckForHashtagSubscription';
const CREATE_NEW_ECHO_NOTIFICATION = 'serverCreateNewEchoNotification';
const CREATE_NEW_GROUP_SUBSCRIPTION = 'serverCreateNewGroupSubscription';
const CREATE_NEW_MENTION_NOTIFICATION = 'serverCreateNewMentionNotification';
const CREATE_NEW_POST_STATUS = 'serverCreateNewPostStatus';
const CREATE_NEW_POST_NUMBERS = 'serverCreateNewPostNumbers';
const CREATE_OR_UPDATE_HASHTAG = 'serverCreateOrUpdateHashtag';
const CREATE_PROFILE = 'serverCreateProfile';
const DELETE_GROUP_SUBSCRIPTION = 'serverDeleteGroupSubscription';
const GET_BLOCKS = 'serverGetBlocks';
const GET_GROUP = 'serverGetGroup';
const GET_GROUPS = 'serverGetGroups';
const GET_GROUP_SUBSCRIPTION = 'serverGetGroupSubscription';
const GET_GROUP_BY_ID = 'serverGetGroupById';
const GET_GROUP_BY_NAME = 'serverGetGroupByName';
const GET_HASHTAG_SUBS = 'serverGetHashtagSubscriptions';
const GET_LISTEN_DATA = 'serverGetListenData';
const GET_MY_GROUPS = 'serverGetMyGroups';
const GET_MY_LISTENING_TO = 'serverGetMyListeningTo';
const GET_MY_NOTIFICATIONS = 'serverGetMyNotifications';
const GET_MY_POST_STATUSES = 'serverGetMyPostStatuses';
const GET_POST = 'serverGetPost';
const GET_POSTS = 'serverGetPosts';
const GET_POST_NUMBERS = 'serverGetPostNumbers';
const GET_PROFILE = 'serverGetProfile';
const GET_PROFILES = 'serverGetProfiles';
const GET_RECENT_POSTS = 'serverGetRecentPosts';
const PUT_TOGETHER_POSTS = 'serverPutTogetherPosts';
const UPDATE_ECHO_NOTIFICATION = 'serverUpdateEchoNotification';
const UPDATE_GROUP = 'serverUpdateGroup';
const UPDATE_GROUP_SUBSCRIPTION = 'serverUpdateGroupSubscription';
const UPDATE_LISTEN = 'serverUpdateListen';
const UPDATE_LISTENING_TO = 'serverUpdateListeningTo';
const UPDATE_POST = 'serverUpdatePost';
const UPDATE_POST_NUMBERS = 'serverUpdatePostNumbers';
const UPDATE_PROFILE = 'serverUpdateProfile';
const UPDATE_TRENDING_GROUP = 'serverUpdateTrendingGroup';
const UPDATE_TRENDING_HASHTAG = 'serverUpdateTrendingHashtag';
const UPDATE_TRENDING_MENTION = 'serverUpdateTrendingMention';

const OFFICIAL_GROUP_ID = '7752ad30-3ae3-438e-8032-79bf27b65a9e';
const OFFICIAL_GROUP_NAME = 'shout!';
const SPACE_REGEX = /(\n\n+)\n+/g;

class HashtagGroupMentions {
  groups: Array<string> = [];
  hashtags: Array<Hashtag> = [];
  mentions: Array<string> = [];

  constructor({groups, hashtags, mentions}: any) {
    this.groups = groups ? groups : [];
    this.hashtags = hashtags ? hashtags : [];
    this.mentions = mentions ? mentions : [];
  }
}

/**
 * canUploadImage
 * 
 * Checks to see if an image can pass the tests to be uploaded.
 * 
 * @param file (File): the file to check
 * @returns (boolean)
 */
const canUploadImage = (file: File): boolean => {
  if (!file) return false;
  
  try {
    const size = file.size;
    const type = file.type.split('/')[0];

    if (type !== 'image' || size > FILE_SIZE_LIMIT) return false;
  } catch {
    return false;
  }

  return true;
}

/**
 * checkForData
 * 
 * Checks a response from the server for data and returns that data, an empty array, or null.
 * 
 * @param data (any): the data to check (the response from the server)
 * @param lastProp (string): the last prop in the chain to check that would be returned: i.e. "updatePostNumber"
 * @param isArray (boolean)[false]: if is an array, returns the proper "items" part of the return statement
 * @returns (any)
 */
const checkForData = (data: any, lastProp: string, isArray = false): any => {
  if (!data || !data.data || !lastProp || !data.data[lastProp]) return null;
  return isArray ? data.data[lastProp].items : data.data[lastProp];
};

/**
 * checkForHashtagGroupMentions
 * 
 * Checks an incoming post for hashtags, groups, and mentions and returns any found.
 * 
 * @param text (string) - the text to check
 * @returns (HashtagGroupMentions)
 */
const checkForHashtagGroupMentions = (text: string): HashtagGroupMentions => {
  if (!text) return new HashtagGroupMentions({});
  const regex = /[^0-9a-zA-Z]+/g;
  const regexSplit = /[^#@&0-9a-zA-Z!]+/g;
  const regexMentionSplit = /[^0-9a-zA-Z!]+/g;
  const words = text.split(regexSplit);
  const groups: Array<string> = [];
  const hashtags: Array<Hashtag> = [];
  const mentions: Array<string> = [];

  words.forEach((word) => {
    if (word.indexOf('#') === 0) hashtags.splice(0, 0, new Hashtag({hashtag: word.replaceAll(regex, '')}));
    else if (word.indexOf('@') === 0) mentions.splice(0, 0, word.replaceAll(regexMentionSplit, ''));
    else if (word.indexOf('&') === 0) groups.splice(0, 0, word.replaceAll(regex, ''));
  });
  return new HashtagGroupMentions({groups, hashtags, mentions});
};

const Server = {
  getters: {

  },
  actions: {
    async serverAddNewGroup(context: any, group: Group): Promise<Group|null> {
      try {
        const newGroup = {
          banner: group.banner ? `${IMAGE_LOC}${group.id}_group_banner` : '',
          categories: group.categories,
          createdBy: group.createdBy,
          description: group.description,
          id: group.id,
          isOwnerOnly: group.isOwnerOnly,
          listeners: group.listeners,
          locations: group.locations,
          name: group.name,
          preferredName: group.preferredName,
          posts: group.posts,
        };
        const data = await API.graphql({query: mutations.createGroup, variables: {input: newGroup}});
        const createdGroup: Group = checkForData(data, 'createGroup');

        if (!createdGroup) return null;

        // Update the trending...
        context.dispatch(UPDATE_TRENDING_GROUP, group.name.toLowerCase());

        await context.dispatch(CREATE_NEW_GROUP_SUBSCRIPTION, {groupId: newGroup.id, status: SubscriptionStatus.APPROVED});
        return createdGroup;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverAddNewGroup'});
        return null;
      }
    },
    async serverAdminDeleteContact(context: any, id: string): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: mutations.deleteContact, variables: {input: {id}}});
        return !!checkForData(data, 'deleteContact');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverAdminDeleteContact'});
        return false;
      }
    },
    async serverAdminDeleteError(context: any, id: string): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: mutations.deleteErrorCatch, variables: {input: {id}}});
        return !!checkForData(data, 'deleteErrorCatch');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverAdminDeleteError'});
        return false;
      }
    },
    async serverAdminEditGroup(context: any, updatedGroup: Group): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const removeBanner = !updatedGroup.banner ? true : false;

        const fixedGroup = {
          ...updatedGroup,
          banner: removeBanner ? '' : updatedGroup.banner,
        };
        const group: Group = await context.dispatch(UPDATE_GROUP, fixedGroup);

        if (!group) return false;

        // Remove any associated banner if warranted...
        if (removeBanner) {
          const fileName = `${group.id}_group_banner`;
          await Storage.remove(fileName);
        }

        return true;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverAdminEditGroup'});
        return false;
      }
    },
    async serverAdminEditProfile(context: any, updatedProfile: Profile): Promise<boolean> {
      try {
        const lower = updatedProfile.username.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const removeBanner = !updatedProfile.banner ? true : false;
        const removeIcon = !updatedProfile.profileIcon ? true : false;

        const fixedProfile = {
          ...updatedProfile,
          banner: removeBanner ? '' : updatedProfile.banner,
          profileIcon: removeIcon ? '' : updatedProfile.profileIcon,
        };
        const profile = await context.dispatch(UPDATE_PROFILE, fixedProfile);

        if (!profile) return false;

        // Remove any associated image/banner if warranted...
        if (removeBanner) {
          const fileName = `${lower}_banner`;
          await Storage.remove(fileName);
        }
        if (removeIcon) {
          const fileName = `${lower}_profile_icon`;
          await Storage.remove(fileName);
        }

        return true;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverAdminEditProfile'});
        return false;
      }
    },
    async serverAdminGetReportedNumbers(context: any): Promise<ReportedNumbers|null> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return null;

        const filter = {resolved: {eq: false}};
        const userFilter = {count: {gt: 2}};
        const groupData = await API.graphql({query: queries.listReportGroups, variables: {filter}});
        const postData = await API.graphql({query: queries.listReportPosts, variables: {filter}});
        const profileData = await API.graphql({query: queries.listReportProfiles, variables: {filter}});
        const userData = await API.graphql({query: queries.listReportUsers, variables: {userFilter}});

        const groups: Array<any> = checkForData(groupData, 'listReportGroups', true);
        const posts: Array<any> = checkForData(postData, 'listReportPosts', true);
        const profiles: Array<any> = checkForData(profileData, 'listReportProfiles', true);
        const users: Array<any> = checkForData(userData, 'listReportUsers', true);

        return new ReportedNumbers({
          groups: groups ? groups.length : 0,
          posts: posts ? posts.length : 0,
          profiles: profiles ? profiles.length : 0,
          users: users ? users.length : 0,
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverAdminGetReportedNumbers'});
        return null;
      }
    },
    async serverAdminGetReportedUser(context: any, username: string): Promise<ReportedUser|null> {
      try {
        const lower = username.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return null;

        const groupData = await API.graphql({query: queries.reportGroupByUsername, variables: {username: lower}});
        const postData = await API.graphql({query: queries.reportPostByUsername, variables: {username: lower}});
        const profileData = await API.graphql({query: queries.reportProfileByUsername, variables: {username: lower}});

        return new ReportedUser({
          reportGroup: checkForData(groupData, 'reportGroupByUsername', true),
          reportPost: checkForData(postData, 'reportPostByUsername', true),
          reportProfile: checkForData(profileData, 'reportProfileByUsername', true),
          username: lower,
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverAdminGetReportedUser'});
        return null;
      }
    },
    async serverAdminReportUser(context: any, { username, groupId, postId, profileId }: any): Promise<boolean> {
      try {
        const lower = username.toLowerCase();
        const totalCount = (groupId ? 1 : 0) + (postId ? 1 : 0) + (profileId ? 1 : 0);
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const filter = {username: {eq: lower}};
        const data = await API.graphql({query: queries.listReportUsers, variables: {filter,}});
        const dataArr: Array<ReportedUser> = checkForData(data, 'listReportUsers', true);
        const reportedUser = dataArr && dataArr.length > 0 ? dataArr[0] : null;

        if (!reportedUser) {
          // We don't have a reported user for this user... create one!
          const reportedCreate = await API.graphql({query: mutations.createReportUser, variables: {input: {
            count: totalCount,
            username: lower,
          }}});

          return checkForData(reportedCreate, 'createReportUser') ? true : false;
        } else {
          const reportedData = await API.graphql({query: mutations.updateReportUser, variables: {input: {
            count: reportedUser.count + totalCount,
            id: reportedUser.id,
            username: reportedUser.username,
          }}});

          return checkForData(reportedData, 'updateReportUser') ? true : false;
        }
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverAdminReportUser'});
        return false;
      }
    },
    async serverApproveGroupUser(context: any, { username, groupId }: any): Promise<boolean> {
      try {
        const sub = await context.dispatch(GET_GROUP_SUBSCRIPTION, {username, groupId});
        if (!sub || !sub.group || !sub.group.items || sub.group.items.length === 0) return false;

        const lower = username.toLowerCase();
        const group = sub.group.items[0];
        const requestedList = group.requestedList.filter((user) => user.toLowerCase() !== lower);
        
        await context.dispatch(UPDATE_GROUP_SUBSCRIPTION, {
          groupId: sub.groupId,
          id: sub.id,
          lastCheckedPostNumber: sub.lastCheckedPostNumber,
          status: SubscriptionStatus.APPROVED,
          username: sub.username,
        });
        await context.dispatch(UPDATE_GROUP, {
          banner: group.banner,
          categories: group.categories,
          createdBy: group.createdBy,
          description: group.description,
          id: group.id,
          isOwnerOnly: group.isOwnerOnly,
          listeners: group.listeners + 1,
          locations: group.locations,
          name: group.name,
          preferredName: group.preferredName,
          posts: group.posts,
          requestedList: requestedList,
        });

        return true;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverApproveGroupUser'});
        return false;
      }
    },
    async serverBlockUser(context: any, username: string): Promise<boolean> {
      try {
        const lower = username.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        let success = false;

        // Get any previous blacklist...
        const block: Blocks = await context.dispatch(GET_BLOCKS);

        if (!block) {
          // Create a new entry...
          const newBlock = new Blocks({
            blackList: [lower],
            username: user.username,
          });

          const data = await API.graphql({query: mutations.createBlockUser, variables: {input: newBlock}});
          success = checkForData(data, 'createBlockUser') ? true : false;
        } else if (!block.blackList.some((user: string) => user === lower)) {
          // Update the existing block...
          const newBlock = new Blocks({
            blackList: [...block.blackList, lower],
            id: block.id,
            username: block.username,
          });
          const data = await API.graphql({query: mutations.updateBlockUser, variables: {input: newBlock}});
          success = checkForData(data, 'updateBlockUser') ? true : false;
        }

        // Remove them as a listener, if applicable...
        const listening: Array<Listening> = await context.dispatch(GET_MY_LISTENING_TO);
        if (listening && listening.length > 0) {
          if (listening.some((listen) => listen.profile.id === lower && listen.isListening)) {
            context.dispatch(UPDATE_LISTENING_TO, lower);
          }
        }

        return success;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverBlockUser'});
        return false;
      }
    },
    async serverCanPostToGroup(context: any, groupName: string): Promise<Group|null> {
      try {
        const lower = groupName.toLowerCase();
        const user: User = context.getters['getUser'];

        if (!user) return null;

        const groups: Array<Group> = await context.dispatch(GET_MY_GROUPS);
        const found = groups.find((grp) => grp.name === lower);

        return found && (!found.isOwnerOnly || found.createdBy === user.preferredUsername) ? found : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCanPostToGroup'});
        return null;
      }
    },
    async serverCatchError(context: any, { error, func }: any): Promise<boolean> {
      try {
        const errorMsg = error + '';
        const user: User = context.getters['getUser'];
        const newError = {
          error: errorMsg,
          function: func,
          resolved: false,
          username: user ? user.username : '',
        };
        const data = await API.graphql({query: mutations.createErrorCatch, variables: {input: newError}, authMode: API_KEY});
        return checkForData(data, 'createErrorCatch') ? true : false;
      } catch {
        return false;
      }
    },
    async serverCheckForHashtagSubscription(context: any, hashtag: string): Promise<HashtagNotification|null> {
      try {
        const lower = hashtag.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return null;

        const data = await API.graphql({query: queries.hashtagsByUsername, variables: {
          username: user.username,
          hashtag: {eq: lower},
        }});
        const notificationsArray = checkForData(data, 'hashtagsByUsername', true);
        return notificationsArray && notificationsArray.length > 0 ? notificationsArray[0] : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCheckForHashtagSubscription'});
        return null;
      }
    },
    async serverCheckGroupName(context: any, name: string): Promise<boolean> {
      try {
        const filter = {name: {eq: name.toLowerCase()}};
        const data = await API.graphql({query: queries.listGroups, variables: {filter}});
        const groups: Array<any> = checkForData(data, 'listGroups', true);
        return groups && groups.length > 0 ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCheckGroupName'});
        return true;
      }
    },
    async serverConfirmConfirmationCode(context: any, { username, code }: any): Promise<boolean> {
      try {
        return await Auth.confirmSignUp(username, code);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverConfirmConfirmationCode'});
        return false;
      }
    },
    async serverConfirmForgotCode(context: any, { code, password, username }: any): Promise<any> {
      try {
        return await Auth.forgotPasswordSubmit(username, code, password);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverConfirmForgotCode'});
        return error;
      }
    },
    async serverCreateNewEcho(context: any, { echo, parentUsername }: any): Promise<FullPost|null> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return null;

        const message = echo.message.replace(SPACE_REGEX, ' ');
        const parentId = echo.parentId;
        const newEcho = new Post({
          ...echo,
          isOfficial: user.isOfficial,
          isVerified: user.isVerified,
          message,
          profileIcon: user.profileIcon,
          username: user.preferredUsername,
        });
        const hashtagGroupMentions = checkForHashtagGroupMentions(echo.message);
        const { groups, hashtags, mentions } = hashtagGroupMentions;
        const firstGroup = groups && groups.length > 0 ? groups[0] : ''; // Right now only support one group per post...

        let group: Group|null = null;
        if (firstGroup) {
          group = await context.dispatch(CAN_POST_TO_GROUP, firstGroup);
          if (group) newEcho.groupId = group.name;
        }

        const data = await API.graphql({query: mutations.createPost, variables: {input: newEcho}});
        const post: Post = checkForData(data, 'createPost');

        if (!post) return null;

        await context.dispatch(CREATE_NEW_POST_NUMBERS, {postId: post.id, groupId: newEcho.groupId});
        await context.dispatch(UPDATE_POST_NUMBERS, {postId: newEcho.parentId, echoes: 1});
        const posts = await context.dispatch(PUT_TOGETHER_POSTS, [post]);

        // Update any group posts...
        if (group) {
          context.dispatch(UPDATE_GROUP, {...group, posts: group.posts + 1});
        }

        // Create/update the hashtags, if any...
        if (hashtags && hashtags.length > 0) {
          hashtags.forEach((tag) => context.dispatch(CREATE_OR_UPDATE_HASHTAG, {postId: post.id, tag: tag.hashtag}));
        }
        // Create mention notifications, if any...
        if (mentions && mentions.length > 0) {
          mentions.forEach((mention) => context.dispatch(CREATE_NEW_MENTION_NOTIFICATION, {postId: post.id, mention,}));
        }

        // Create/update any notification...
        if (!(await context.dispatch(UPDATE_ECHO_NOTIFICATION, parentId))) {
          context.dispatch(CREATE_NEW_ECHO_NOTIFICATION, { postId: parentId, username: parentUsername});
        }

        return posts && posts.length > 0 ? posts[0] : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateNewEcho'});
        return null;
      }
    },
    async serverCreateNewEchoNotification(context: any, { postId, username }: any): Promise<EchoNotification|null> {
      try {
        const lower = username.toLowerCase();
        const data = await API.graphql({query: mutations.createEchoNotifications, variables: {input: new EchoNotification({
          newNotifications: 1,
          postId,
          username: lower,
        })}});
        return checkForData(data, 'createEchoNotifications');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateNewEchoNotification'});
        return null;
      }
    },
    async serverCreateNewGroupSubscription(context: any, { groupId, status }: any): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: mutations.createGroupSubscriptions, variables: {input: {
          groupId,
          lastCheckedPostNumber: 0,
          status,
          username: user.username,
        }}});
        const newSub: any = checkForData(data, 'createGroupSubscriptions');

        if (!newSub) return false;
        
        context.dispatch('addGroup', new ListeningGroup({
          group: newSub.group,
          groupId: newSub.groupId,
          id: newSub.id,
          isFollowing: status === SubscriptionStatus.APPROVED,
          isOwner: status === SubscriptionStatus.APPROVED,
          status,
        }));
        
        const lg: ListeningGroup = await context.dispatch(GET_GROUP_BY_ID, groupId);
        if (!lg || !lg.group) return false;

        // Only update the group if the creator is not what caused this call.
        if (status !== SubscriptionStatus.APPROVED) {
          const requestedList = lg.group.requestedList ? [...lg.group.requestedList] : [];
          requestedList.push(user.preferredUsername);

          context.dispatch(UPDATE_GROUP, {...lg.group, requestedList: [...requestedList]});
        } else {
          // Just update the amount for the group.
          context.dispatch(UPDATE_GROUP, {...lg.group, listeners: lg.group.listeners + 1});
        }
        return true;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateNewGroupSubscription'});
        return false;
      }
    },
    async serverCreateNewMentionNotification(context: any, { postId, mention }: any): Promise<boolean> {
      try {
        const lower = mention.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        // Make sure this user isn't blocked by the @Mention being created...
        const blocks: Blocks = await context.dispatch(GET_BLOCKS, lower);
        if (blocks && blocks.blackList.length > 0 && blocks.blackList.some((name) => name.toLowerCase() === user.username)) {
          return false;
        }

        // Create the actual mention first...
        const dataLastMention = await API.graphql({query: queries.mentionsByUsername, variables: {
          username: lower,
          postNumber: {},
          sortDirection: 'DESC',
          limit: 1,
        }});
        const lastMentions: Array<any> = checkForData(dataLastMention, 'mentionsByUsername', true);
        const lastMentionNum = lastMentions && lastMentions.length > 0 ? lastMentions[0].postNumber : 0;
        const dataMention = await API.graphql({query: mutations.createMentions, variables: {input: {
          postId,
          postNumber: lastMentionNum + 1,
          username: lower,
        }}});
        const newMention = checkForData(dataMention, 'createMentions');

        if (!newMention) return false;

        // ...then create the notification...
        const dataLastNotification = await API.graphql({query: queries.mentionNotificationsByUsername, variables: {
          username: lower,
          postNumber: {},
          sortDirection: 'DESC',
          limit: 1,
        }});
        const lastNotifications: Array<any> = checkForData(dataLastNotification, 'mentionNotificationsByUsername', true);
        const lastNotificationNum = lastNotifications && lastNotifications.length > 0 ? lastNotifications[0].postNumber : 0;
        const dataNotification = await API.graphql({query: mutations.createMentionNotifications, variables: {input: {
          postId,
          postNumber: lastNotificationNum + 1,
          username: lower,
        }}});

        // Update the trending...
        context.dispatch(UPDATE_TRENDING_MENTION, lower);

        return checkForData(dataNotification, 'createMentionNotifications') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateNewMentionNotification'});
        return false;
      }
    },
    async serverCreateNewPostStatus(context: any, postId: string): Promise<PostStatus|null> {
      try {
        const data = await API.graphql({query: mutations.createPostStatus, variables: {input: {
          addedListen: false,
          isHidden: false,
          postId,
        }}});
        return checkForData(data, 'createPostStatus');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateNewPostStatus'});
        return null;
      }
    },
    async serverCreateNewShout(context: any, shout: Post): Promise<FullPost|null> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return null;

        const message = shout.message.replace(SPACE_REGEX, ' ');
        const newShout = new Post({
          ...shout,
          isOfficial: user.isOfficial,
          isVerified: user.isVerified,
          message,
          parentId: ' ', // Need this for the @key part of the dynamoDb table... empty space string for Shouts.
          profileIcon: user.profileIcon,
          username: user.preferredUsername,
        });
        const hashtagGroupMentions = checkForHashtagGroupMentions(newShout.message);
        const { groups, hashtags, mentions } = hashtagGroupMentions;
        const firstGroup = groups && groups.length > 0 ? groups[0] : ''; // Can only support one group per post right now.

        let group: Group|null = null;
        if (firstGroup) {
          group = await context.dispatch(CAN_POST_TO_GROUP, firstGroup);
          if (group) newShout.groupId = group.name;
        }

        // Upload any associated image...
        if (shout.image && shout.image instanceof File) {
          const file: File = shout.image;
          const fileName = `${newShout.id}_image`;
          const fileType = file.type;

          if (canUploadImage(file)) {
            const image = await Storage.put(fileName, file, {
              level: 'public',
              contentType: fileType,
            });

            if (image) {
              const imagePath = `${IMAGE_LOC}${fileName}`;
              newShout.image = imagePath;
            } else {
              newShout.image = '';
            }
          }
      }

        const data = await API.graphql({query: mutations.createPost, variables: {input: newShout}});
        const post: Post = checkForData(data, 'createPost');

        if (!post) return null;

        await context.dispatch(CREATE_NEW_POST_NUMBERS, {postId: post.id, groupId: newShout.groupId});
        const posts = await context.dispatch(PUT_TOGETHER_POSTS, [post]);

        // Update any group posts...
        if (group) {
          context.dispatch(UPDATE_GROUP, {...group, posts: group.posts + 1});
        }

        // Create/update the hashtags, if any...
        if (hashtags && hashtags.length > 0) {
          hashtags.forEach((tag) => context.dispatch(CREATE_OR_UPDATE_HASHTAG, {postId: post.id, tag: tag.hashtag}));
        }
        // Create mention notifications, if any...
        if (mentions && mentions.length > 0) {
          mentions.forEach((mention) => context.dispatch(CREATE_NEW_MENTION_NOTIFICATION, {postId: post.id, mention,}));
        }

        return posts && posts.length > 0 ? posts[0] : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateNewShout'});
        return null;
      }
    },
    async serverCreateNewPostNumbers(context: any, { postId, groupId = null }: any): Promise<PostNumbers|null> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return null;

        const numbers = new PostNumbers({postId, groupId, username: user.username});
        const data = await API.graphql({query: mutations.createPostNumbers, variables: {input: numbers}});

        return checkForData(data, 'createPostNumbers');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateNewPostNumbers'});
        return null;
      }
    },
    async serverCreateOrUpdateHashtag(context: any, { postId, tag }: any): Promise<boolean> {
      try {
        // Check for existing hashtag...
        const lower = tag.toLowerCase();
        const data = await API.graphql({query: queries.hashtagPostsByHashtag, variables: {hashtagId: lower}});
        const existingPosts: Array<any> = checkForData(data, 'hashtagPostsByHashtag', true);

        if (existingPosts && existingPosts.length > 0) {
          if (existingPosts.some((post) => post.postId === postId)) {
            return true;
          }
          const postData = await API.graphql({query: mutations.createHashtagPosts, variables: {input: {
            hashtagId: lower,
            postId,
          }}});
          const isSuccess = checkForData(postData, 'createHashtagPosts') ? true : false;

          if (!isSuccess) return false;

          const subData = await API.graphql({query: queries.listHashtagNotificationss, variables: {
            hashtag: lower,
            username: {},
            sortDirection: 'ASC',
          }});
          const subs: Array<HashtagNotification> = checkForData(subData, 'listHashtagNotificationss', true);

          if (!subs || subs.length === 0) return true;

          subs.forEach((sub) => {
            API.graphql({query: mutations.updateHashtagNotifications, variables: {input: new HashtagNotification({
              ...sub,
              newNotifications: sub.newNotifications + 1,
            })}});
          });

          // Update the trending...
          context.dispatch(UPDATE_TRENDING_HASHTAG, lower);

          return true;
        } else {
          // Make a new hashtag...
          const hashData = await API.graphql({query: mutations.createHashtag, variables: {input: {id: lower}}});
          const hashtag = checkForData(hashData, 'createHashtag');
          const postData = await API.graphql({query: mutations.createHashtagPosts, variables: {input: {
            hashtagId: hashtag.id,
            postId,
          }}});

          // Update the trending...
          context.dispatch(UPDATE_TRENDING_HASHTAG, lower);

          return checkForData(postData, 'createHashtagPosts') ? true : false;
        }
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateOrUpdateHashtag'});
        return false;
      }
    },
    async serverCreateProfile(context: any, username: string): Promise<any> {
      try {
        const filename = `${username.toLowerCase()}_profile_icon`;
        const profile = new Profile({
          addShadow: false,
          groupImageViewOption: ViewOption.ALL,
          imageViewOption: ViewOption.LISTENING_TO,
          profileIcon: `${IMAGE_LOC}${filename}`,
          username: username,
        });
        const listen = new Listen({
          username: username,
        });

        const newProfile = await API.graphql(graphqlOperation(mutations.createProfile, {input: profile}));
        context.dispatch('setUser');
        context.dispatch('setProfileProgress', 25);

        // Setup the groups and listens...
        await API.graphql(graphqlOperation(mutations.createListen, {input: listen}));
        context.dispatch('setProfileProgress', 50);
        await context.dispatch(CREATE_NEW_GROUP_SUBSCRIPTION, {groupId: OFFICIAL_GROUP_ID, status: SubscriptionStatus.APPROVED});
        context.dispatch('setProfileProgress', 75);
        await context.dispatch(UPDATE_LISTENING_TO, OFFICIAL_GROUP_NAME);
        context.dispatch('setProfileProgress', 100);

        return newProfile;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverCreateProfile'});
        return error;
      }
    },
    async serverDeleteGroup(context: any, groupId: string): Promise<boolean> {
      try {
        const data = await API.graphql({query: mutations.deleteGroup, variables: {input: {id: groupId}}});
        const isSuccess = checkForData(data, 'deleteGroup') ? true : false;

        if (isSuccess) {
          // Delete the banner image, if any.
          const fileName = `${groupId}_group_banner`;
          Storage.remove(fileName);

          // Delete all of the associated subscriptions.
          const filter = {
            groupId: {eq: groupId},
          };
          const subData = await API.graphql({query: queries.listGroupSubscriptionss, variables: {filter}});
          const subs: Array<any> = checkForData(subData, 'listGroupSubscriptionss', true);

          if (subs) {
            subs.forEach((sub) => {
              API.graphql({query: mutations.deleteGroupSubscriptions, variables: {input: {id: sub.id}}});
            });
          }
        }

        return isSuccess;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverDeleteGroup'});
        return false;
      }
    },
    async serverDeleteGroupSubscription(context: any, id: string): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        let myGroups: Array<ListeningGroup> = context.getters['myGroups'];

        if (!myGroups || myGroups.length === 0) {
          await context.dispatch(GET_MY_GROUPS);
          myGroups = context.getters['myGroups'];
        }

        const found = myGroups.find((lg) => lg.groupId === id);
        if (!found) return true;

        const deleteData = await API.graphql({query: mutations.deleteGroupSubscriptions, variables: {input: {id: found.id}}});
        const deleted = checkForData(deleteData, 'deleteGroupSubscriptions');
        if (deleted) {
          context.dispatch('removeGroup', found.id);
          
          const group = deleted.group.items[0];
          if (found.status === SubscriptionStatus.APPROVED) {
            // Update the group's listeners by removing one.
            context.dispatch(UPDATE_GROUP, {...group, listeners: group.listeners - 1});
          } else if (found.status === SubscriptionStatus.REQUESTED) {
            // Remove the request from the group.
            const requestedList = group.requestedList.filter((name: string) => name !== user.preferredUsername);
            context.dispatch(UPDATE_GROUP, {...group, requestedList,});
          }
          return true;
        }
        return false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverDeleteGroupSubscription'});
        return false;
      }
    },
    async serverDeletePost(context: any, { id, reason }: any): Promise<FullPost|null> {
      try {
        const post = await context.dispatch(GET_POST, id);

        if (!post) return null;

        const deletePost = new Post({
          ...post,
          deleteReason: reason,
          image: '',
          type: Type.Deleted,
        });

        await context.dispatch(UPDATE_POST, deletePost);
        const posts = await context.dispatch(PUT_TOGETHER_POSTS, [deletePost]);

        // Remove any associated image...
        try {
          const fileName = `${id}_image`;
          Storage.remove(fileName);
        } catch {
          // Silently fail here... probably didn't have an image associated.
        }

        return posts && posts.length > 0 ? posts[0] : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverDeletePost'});
        return null;
      }
    },
    async serverDenyGroupUser(context: any, { username, groupId }: any): Promise<boolean> {
      try {
        const sub = await context.dispatch(GET_GROUP_SUBSCRIPTION, {username, groupId});
        if (!sub || !sub.group || !sub.group.items || sub.group.items.length === 0) return false;

        const lower = username.toLowerCase();
        const group = sub.group.items[0];
        const requestedList = group.requestedList.filter((user) => user.toLowerCase() !== lower);
        
        // We want to either delete the subscription if removing a user or update it if denying...
        if (sub.status === SubscriptionStatus.APPROVED) {
          await API.graphql({query: mutations.deleteGroupSubscriptions, variables: {input: {id: sub.id}}});
        } else if (sub.status === SubscriptionStatus.REQUESTED) {
          await context.dispatch(UPDATE_GROUP_SUBSCRIPTION, {
            groupId: sub.groupId,
            id: sub.id,
            lastCheckedPostNumber: sub.lastCheckedPostNumber,
            status: SubscriptionStatus.DENIED,
            username: sub.username,
          });
        } else return true;

        await context.dispatch(UPDATE_GROUP, {
          banner: group.banner,
          categories: group.categories,
          createdBy: group.createdBy,
          description: group.description,
          id: group.id,
          isOwnerOnly: group.isOwnerOnly,
          listeners: sub.status === SubscriptionStatus.APPROVED ? group.listeners - 1 : group.listeners,
          locations: group.locations,
          name: group.name,
          preferredName: group.preferredName,
          posts: group.posts,
          requestedList: requestedList,
        });

        return true;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverDenyGroupUser'});
        return false;
      }
    },
    async serverEditPost(context: any, { id, message, reason }: any): Promise<FullPost|null> {
      try {
        const post: Post = await context.dispatch(GET_POST, id);
        if (!post) return null;

        // We don't want to check for mentions, groups, etc. if they have already been added...
        const origPost = checkForHashtagGroupMentions(post.originalPost);
        const prevPost = checkForHashtagGroupMentions(post.message);
        const allTags = new HashtagGroupMentions({
          groups: [...origPost.groups, ...prevPost.groups],
          hashtags: [...origPost.hashtags, ...prevPost.hashtags],
          mentions: [...origPost.mentions, ...prevPost.mentions],
        });
        let msgToCheck = message.toLowerCase();
        Object.keys(allTags).forEach((key: string) => {
          allTags[key].forEach((value: string) => {
            const val = (key === 'groups' ? '&' : key === 'hashtags' ? '#' : '@') + value.toLowerCase();
            msgToCheck = msgToCheck.replaceAll(val, '');
          });
        });

        const hashtagGroupMentions = checkForHashtagGroupMentions(msgToCheck);
        const { groups, hashtags, mentions } = hashtagGroupMentions;
        const firstGroup = groups && groups.length > 0 ? groups[0] : ''; // Can only support one group per post right now.
        const originalPost = post.originalPost ? post.originalPost : post.message;
        const editedPost = new Post({
          ...post,
          editReason: reason,
          message,
          originalPost,
          type: Type.Edited,
        });

        let group: Group|null = null;
        if (firstGroup) {
          group = await context.dispatch(CAN_POST_TO_GROUP, firstGroup);
          if (group) editedPost.groupId = group.name;
        }

        await context.dispatch(UPDATE_POST, editedPost);
        const posts = await context.dispatch(PUT_TOGETHER_POSTS, [editedPost]);

        // Update any group posts...
        if (group) {
          context.dispatch(UPDATE_GROUP, {...group, posts: group.posts + 1});
          // We also want to update the Post Numbers to show it has an updated groupId...
          const numbers: Array<PostNumbers> = await context.dispatch(GET_POST_NUMBERS, [group.id]);
          const number = numbers && numbers.length > 0 ? numbers[0] : null;

          if (number) {
            context.dispatch(UPDATE_POST_NUMBERS, {...number, groupId: group.name});
          }
        }

        // Create/update the hashtags, if any...
        if (hashtags && hashtags.length > 0) {
          hashtags.forEach((tag) => context.dispatch(CREATE_OR_UPDATE_HASHTAG, {postId: post.id, tag: tag.hashtag}));
        }
        // Create mention notifications, if any...
        if (mentions && mentions.length > 0) {
          mentions.forEach((mention) => context.dispatch(CREATE_NEW_MENTION_NOTIFICATION, {postId: post.id, mention,}));
        }

        return posts && posts.length > 0 ? posts[0] : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverEditPost'});
        return null;
      }
    },
    async serverGetAdminContacts(context: any): Promise<Array<Contact>> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return [];

        const data = await API.graphql({query: queries.listContacts});
        const contacts: Array<Contact> = checkForData(data, 'listContacts', true);

        return contacts ? contacts.sort((a, b) => a.resolved ? 1 : -1) : [];
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetAdminContacts'});
        return [];
      }
    },
    async serverGetAdminErrors(context: any): Promise<Array<ErrorCatch>> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return [];

        const data = await API.graphql({query: queries.listErrorCatchs});
        const errors: Array<ErrorCatch> = checkForData(data, 'listErrorCatchs', true);

        return errors ? errors.sort((a, b) => a.resolved ? 1 : -1) : [];
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetAdminErrors'});
        return [];
      }
    },
    async serverGetAdminReportedGroups(context: any): Promise<Array<ReportedGroup>> {
      try {
        const data = await API.graphql({query: queries.listReportGroups});
        const groups: Array<ReportedGroup> = checkForData(data, 'listReportGroups', true);

        return groups ? groups.sort((a, b) => a['createdAt'] > b['createdAt'] ? 1 : -1) : [];
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetAdminReportedGroups'});
        return [];
      }
    },
    async serverGetAdminReportedPosts(context: any): Promise<Array<ReportedPost>> {
      try {
        const data = await API.graphql({query: queries.listReportPosts});
        const posts: Array<ReportedPost> = checkForData(data, 'listReportPosts', true);

        return posts ? posts.sort((a, b) => a['createdAt'] > b['createdAt'] ? 1 : -1) : [];
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetAdminReportedPosts'});
        return [];
      }
    },
    async serverGetAdminReportedProfiles(context: any): Promise<Array<ReportedProfile>> {
      try {
        const data = await API.graphql({query: queries.listReportProfiles});
        const profiles: Array<ReportedPost> = checkForData(data, 'listReportProfiles', true);

        return profiles ? profiles.sort((a, b) => a['createdAt'] > b['createdAt'] ? 1 : -1) : [];
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetAdminReportedProfiles'});
        return [];
      }
    },
    async serverGetAdminReportedUsers(context: any): Promise<Array<ReportedUser>> {
      try {
        const data = await API.graphql({query: queries.listReportUsers});
        const users: Array<ReportedUser> = checkForData(data, 'listReportUsers', true);

        return users ? users.sort((a, b) => a.username > b.username ? 1 : -1) : [];
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetAdminReportedUsers'});
        return [];
      }
    },
    async serverGetAmIListeningTo(context: any, profileUsername: string): Promise<boolean> {
      try {
        const lower = profileUsername.toLowerCase();
        const user = context.getters['getUser'];
        if (!user) return false;

        const listen: Listen | null = await context.dispatch(GET_LISTEN_DATA, user.username);
        if (listen) {
          return listen.listeningTo.findIndex((username: string) => username.toLowerCase() === lower) > -1;
        }
        return false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetAmIListeningTo'});
        return false;
      }
    },
    async serverGetBlocks(context: any, username: string = ''): Promise<Blocks|null> {
      try {
        let usernameToUse = username;

        if (!username) {
          const user: User = context.getters['getUser'];
          if (!user) return null;
          usernameToUse = user.username;
        }

        const data = await API.graphql({query: queries.blockUserByUsername, variables: {
          username: usernameToUse,
        }});
        const dataArr: Array<Blocks> = checkForData(data, 'blockUserByUsername', true);
        return dataArr && dataArr.length > 0 ? dataArr[0] : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetBlocks'});
        return null;
      }
    },
    async serverGetChain(context: any, postId: string): Promise<Chain|null> {
      try {
        const filter = {type: {ne: Type.Deleted}};
        const data = await API.graphql({query: queries.postsByGenerationZeroId, variables: {
          generationZeroId: postId,
          filter,
        }, authMode: API_KEY});
        const posts: Array<Post> = checkForData(data, 'postsByGenerationZeroId', true);

        if (!posts) return null;

        const fullPosts: Array<FullPost> = await context.dispatch(PUT_TOGETHER_POSTS, posts);

        if (!fullPosts || fullPosts.length === 0) return null;

        fullPosts.sort((a, b) => a.post.parentId === null ? 1 : a.post['createdAt'] > b.post['createdAt'] ? 1 : -1);

        return new Chain({
          echoes: fullPosts.length > 1 ? fullPosts.slice(1) : [],
          originalShout: fullPosts[0],
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetChain'});
        return null;
      }
    },
    async serverGetFullPost(context: any, postId: string): Promise<FullPost|null> {
      try {
        const post = await context.dispatch(GET_POST, postId);
        const posts = await context.dispatch(PUT_TOGETHER_POSTS, [post]);
        
        return posts && posts.length > 0 ? posts[0] : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetFullPost'});
        return null;
      }
    },
    async serverGetGroup(context: any, filter: any): Promise<ListeningGroup|null> {
      try {
        const groupData = await API.graphql({query: queries.listGroups, variables: {filter}, authMode: API_KEY});
        const groups: Array<Group> = checkForData(groupData, 'listGroups', true);
        const group = groups && groups.length > 0 ? groups[0] : null;

        if (!group) return null;

        let myGroups: Array<ListeningGroup> = context.getters['myGroups'];
        if (!myGroups || myGroups.length === 0) {
          await context.dispatch(GET_MY_GROUPS);
          myGroups = context.getters['myGroups'];
        }
        const foundGroup = myGroups.find((grp) => grp.groupId === group.id);

        return new ListeningGroup({
          group,
          groupId: group.id,
          id: foundGroup ? foundGroup.id : '',
          isFollowing: foundGroup ? foundGroup.isFollowing : false,
          isOwner: foundGroup ? foundGroup.isOwner : false,
          newListenerRequests: foundGroup ? foundGroup.newListenerRequests : 0,
          status: foundGroup ? foundGroup.status : '',
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetGroup'});
        return null;
      }
    },
    async serverGetGroupById(context: any, id: string): Promise<ListeningGroup|null> {
      return await context.dispatch('serverGetGroup', {id: {eq: id}});
    },
    async serverGetGroupByName(context: any, name: string): Promise<ListeningGroup|null> {
      return await context.dispatch('serverGetGroup', {name: {eq: name.toLowerCase()}});
    },
    async serverGetGroupForEdit(context: any, id: string): Promise<Group|null> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return null;

        const data = await API.graphql({query: custom.getGroupForEdit, variables: {id}});
        const group: Group = checkForData(data, 'getGroup');
        return group && group.createdBy.toLowerCase() === user.username ? group : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetGroupForEdit'});
        return null;
      }
    },
    async serverGetGroups(context: any, username = ''): Promise<Array<Group>> {
      try {
        const user: User = context.getters['getUser'];
        let usernameToUse = '';

        if (!username) {
          if (!user) return [];
          usernameToUse = user.username;
        } else {
          usernameToUse = username.toLowerCase();
        }

        const subData = await API.graphql({query: custom.groupSubscriptionsByUsername, variables: {
          username: usernameToUse,
        }, authMode: API_KEY});
        const subs: Array<any> = checkForData(subData, 'groupSubscriptionsByUsername', true);
        
        if (!subs || subs.length === 0) return [];
        
        // Add the subs to the store, but only if getting "my groups".
        if (!username) {
          context.dispatch('setMyGroups', subs.map((sub) => {
            const grp = sub.group.items[0];
            const postsBehind = grp.posts - sub.lastCheckedPostNumber;
            return new ListeningGroup({
              group: grp,
              groupId: sub.groupId,
              id: sub.id,
              isFollowing: sub.status === SubscriptionStatus.APPROVED,
              isOwner: grp.createdBy.toLowerCase() === user.username,
              newListenerRequests: grp.requestedList ? grp.requestedList.length : 0,
              postsBehind,
              status: sub.status,
            });
          }));
        }

        return subs.filter((sub) => sub.status !== SubscriptionStatus.DENIED)
          .map((sub) => {
            const grp: Group = sub.group.items[0];
            return new Group({
              banner: grp.banner,
              categories: grp.categories,
              createdBy: grp.createdBy,
              description: grp.description,
              id: grp.id,
              isOfficial: grp.isOfficial,
              isOwnerOnly: grp.isOwnerOnly,
              isVerified: grp.isVerified,
              listeners: grp.listeners,
              locations: grp.locations,
              preferredName: grp.preferredName,
              postIds: grp.postIds,
              posts: grp.posts,
              requestedList: grp.requestedList,
            });
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetGroups'});
        return [];
      }
    },
    async serverGetGroupSubscription(context: any, { username, groupId }: any): Promise<any|null> {
      try {
        const lower = username.toLowerCase();
        const filter = {groupId: {eq: groupId}};
        const data = await API.graphql({query: custom.groupSubscriptionsByUsername, variables: {
          username: lower,
          filter,
        }});
        const subs: Array<any> = checkForData(data, 'groupSubscriptionsByUsername', true);
        return subs && subs.length > 0 ? subs[0] : null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetGroupSubscription'});
        return null;
      }
    },
    async serverGetGroupUsers(context: any, name: string): Promise<GroupUsers> {
      try {
        const groupUsers = new GroupUsers({});
        const user: User = context.getters['getUser'];
        if (!user) return groupUsers;

        const lg: ListeningGroup = await context.dispatch(GET_GROUP, {name: {eq: name.toLowerCase()}});
        if (!lg || !lg.group || lg.group.createdBy !== user.preferredUsername) return groupUsers;

        const filter = {
          groupId: {eq: lg.group.id},
          status: {eq: SubscriptionStatus.APPROVED},
        };
        const data = await API.graphql({query: queries.listGroupSubscriptionss, variables: {filter}});
        const subs: Array<any> = checkForData(data, 'listGroupSubscriptionss', true);

        groupUsers.usersRequesting = [...lg.group.requestedList.sort()];
        groupUsers.currentUsers = subs.map((sub: any) => sub.username).sort();

        return groupUsers;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetGroupUsers'});
        return new GroupUsers({});
      }
    },
    async serverGetHashtag(context: any, hashtag: string): Promise<HashtagResults|null> {
      try {
        const lower = hashtag.toLowerCase();
        const data = await API.graphql({query: queries.hashtagPostsByHashtag, variables: {
          hashtagId: lower,
          createdAt: {},
          sortDirection: 'DESC',
        }, authMode: API_KEY});
        const hashtagInfo: Array<any> = checkForData(data, 'hashtagPostsByHashtag', true);
        const posts = await context.dispatch(GET_POSTS, hashtagInfo.map((info) => info.postId));
        const fullPosts = await context.dispatch(PUT_TOGETHER_POSTS, posts);
        const isSubscribed = await context.dispatch(CHECK_FOR_HASHTAG_SUB, lower);
        return new HashtagResults({
          hashtag,
          fullPosts: fullPosts.sort((a, b) => a.post.createdAt > b.post.createdAt ? -1 : 1),
          isSubscribed
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetHashtag'});
        return null;
      }
    },
    async serverGetHashtags(context: any): Promise<MyHashtags|null> {
      try {
        const notifications: Array<HashtagNotification> = await context.dispatch(GET_HASHTAG_SUBS);

        if (!notifications || notifications.length === 0) return new MyHashtags({});

        return new MyHashtags({
          hashtags: notifications.map((not) => not.hashtag),
          notifications: notifications.filter((not) => not.newNotifications > 0).map((not) => new Notification({
            hashtag: not.hashtag,
            newPosts: not.newNotifications,
          })),
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetHashtags'});
        return null;
      }
    },
    async serverGetHashtagSubscriptions(context: any): Promise<Array<HashtagNotification>> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return [];
        
        const data = await API.graphql({query: queries.hashtagsByUsername, variables: {
          username: user.username,
          hashtag: {},
          sortDirection: 'ASC',
        }});
        const notifications: Array<HashtagNotification> = checkForData(data, 'hashtagsByUsername', true);

        return notifications ? notifications : [];
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetHashtagSubscriptions'});
        return [];
      }
    },
    async serverGetListenData(context: any, username: string): Promise<Listen|null> {
      try {
        const lower = username.toLowerCase();
        const data = await API.graphql({query: queries.getListen, variables: {id: lower}, authMode: API_KEY});
        return checkForData(data, 'getListen');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetListenData'});
        return null;
      }
    },
    async serverGetMentions(context: any): Promise<Mentions|null> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return null;

        const dataOld = await API.graphql({query: queries.mentionsByUsername, variables: {
          username: user.username,
          postNumber: {},
          sortDirection: 'ASC',
        }});
        const oldMentions: Array<any> = checkForData(dataOld, 'mentionsByUsername', true);

        if (!oldMentions || oldMentions.length === 0) return new Mentions({});

        const dataNew = await API.graphql({query: queries.mentionNotificationsByUsername, variables: {
          username: user.username,
          postNumber: {},
          sortDirection: 'DESC',
        }});
        const newMentions: Array<any> = checkForData(dataNew, 'mentionNotificationsByUsername', true);

        const posts: Array<Post> = await context.dispatch(GET_POSTS, oldMentions.map((mention) => mention.postId));
        if (!posts) return null;

        const fullPosts: Array<FullPost> = await context.dispatch(PUT_TOGETHER_POSTS, posts);
        if (!fullPosts) return null;

        let mentionsUpdate: Array<FullPost> = [];
        let newMentionsUpdate: Array<FullPost> = [];

        oldMentions.forEach((mention) => {
          const fullPost: FullPost|undefined = fullPosts.find((full) => full.post.id === mention.postId);
          const found = newMentions.find((newMention) => newMention.postId === mention.postId);
          
          if (found && fullPost) newMentionsUpdate.splice(0, 0, fullPost); 
          else if (fullPost) mentionsUpdate.splice(0, 0, fullPost);
        });

        return new Mentions({
          mentions: mentionsUpdate.sort((a, b) => a.post['createdAt'] > b.post['createdAt'] ? -1 : 1),
          newMentions: newMentionsUpdate.sort((a, b) => a.post['createdAt'] > b.post['createdAt'] ? 1 : -1),
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMentions'});
        return null;
      }
    },
    async serverGetMyEchoNotifications(context: any): Promise<Array<MyEchoNotifications>> {
      try {
        const user: User = context.getters['getUser'];
        const myNotifications: Array<MyEchoNotifications> = [];
        if (!user) return [];

        const data = await API.graphql({query: queries.echoesByUsername, variables: {
          username: user.username,
          newNotifications: {},
          sortDirection: 'DESC',
        }});
        const notifications: Array<EchoNotification> = checkForData(data, 'echoesByUsername', true);

        if (!notifications) return [];

        const posts: Array<Post> = await context.dispatch(GET_POSTS, notifications.map((not) => not.postId));

        if (!posts || posts.length === 0) return [];

        const fullPosts: Array<FullPost> = await context.dispatch(PUT_TOGETHER_POSTS, posts);
        if (!fullPosts || fullPosts.length === 0) return [];

        notifications.forEach((not) => {
          const found = fullPosts.find((full) => full.post.id === not.postId);
          if (found) myNotifications.splice(0, 0, new MyEchoNotifications({fullPost: found, notifications: not.newNotifications}));
        });

        return myNotifications;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMyEchoNotifications'});
        return [];
      }
    },
    async serverGetMyGroups(context: any): Promise<Array<Group>> {
      return await context.dispatch(GET_GROUPS);
    },
    async serverGetMyListeners(context: any): Promise<Array<Listening>> {
      try {
        const lower = context.getters['getUser'].username.toLowerCase();
        const listenData: Listen = await context.dispatch(GET_LISTEN_DATA, lower);

        if (!listenData) return [];

        const profiles: Array<Profile> = await context.dispatch(GET_PROFILES, listenData.listeners);
        return profiles.map((profile) => {
          const isListening = listenData.listeningTo.findIndex((id: string) => id === profile.id) > -1;
          return new Listening({isListening, profile});
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMyListeners'});
        return [];
      }
    },
    async serverGetMyListeningTo(context: any): Promise<Array<Listening>> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return [];

        const listenData: Listen = await context.dispatch(GET_LISTEN_DATA, user.username);

        if (!listenData) {
          context.dispatch('setListeningTo', []); // Set our Listening To for viewing options...
          return [];
        }

        const profiles: Array<Profile> = await context.dispatch(GET_PROFILES, listenData.listeningTo);

        // Set our Listening To for viewing options...
        context.dispatch('setListeningTo', profiles.map((profile: Profile) => profile.username));

        return profiles.map((profile: Profile) => new Listening({isListening: true, profile}));
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMyListeningTo'});
        return error;
      }
    },
    async serverGetMyNotifications(context: any): Promise<MyNotifications|null> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return null;

        await context.dispatch(GET_MY_GROUPS);

        const mentionData = await API.graphql({query: queries.mentionNotificationsByUsername, variables: {
          username: user.username,
          postNumber: {},
        }});
        const mentions: Array<any> = checkForData(mentionData, 'mentionNotificationsByUsername', true);
        const mentionNums = mentions ? mentions.length : 0;

        const hashtagData = await API.graphql({query: queries.hashtagsByUsername, variables: {
          username: user.username,
          hashtag: {},
        }});
        const hashtags: Array<any> = checkForData(hashtagData, 'hashtagsByUsername', true);

        const echoesData = await API.graphql({query: queries.echoesByUsername, variables: {
          username: user.username,
          newNotifications: {},
        }});
        const echoes: Array<any> = checkForData(echoesData, 'echoesByUsername', true);
        const echoNums = echoes ? echoes.length : 0;

        const groupData = await API.graphql({query: custom.groupSubscriptionsByUsername, variables: {
          username: user.username,
        }});
        const groupSubs: Array<any> = checkForData(groupData, 'groupSubscriptionsByUsername', true);
        let groupNames: Array<string> = [];
        let notificationNames: Array<string> = [];
        groupSubs.forEach((sub) => {
          if (sub.status !== SubscriptionStatus.APPROVED) return;
          const group = sub.group.items[0];
          const isOwner = group.owner === user.username;
          groupNames.push(group.name);
          // Add a notification if we are posts behind or the owner with requests.
          if ((isOwner && group.requestedList.length > 0) || sub.lastCheckedPostNumber !== group.posts) {
            notificationNames.push(group.name);
          }
        });

        return new MyNotifications({
          echoNums,
          groupNames,
          hashtags,
          mentionNums,
          notificationNames,
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMyNotifications'});
        return null;
      }
    },
    async serverGetMyPopularPosts(context: any): Promise<Array<FullPost>> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return [];

        const blocks: Blocks = await context.dispatch(GET_BLOCKS);
        const listenTos: Array<Listening> = await context.dispatch(GET_MY_LISTENING_TO);
        const filter = {
          or: [
            {username: {eq: user.username}},
            ...listenTos.map((listen) => ({username: {eq: listen.profile.id}}))
          ],
        };
        if (blocks && blocks.blackList.length > 0) {
          filter['and'] = [...blocks.blackList.map((user) => ({username: {ne: user}}))];
        }
        const data = await API.graphql({query: queries.byAllBoth, variables: {
          exists: 'true',
          echoesListens: {},
          filter,
          sortDirection: 'DESC',
        }});
        const postNumbers: Array<PostNumbers> = checkForData(data, 'byAllBoth', true);

        if (!postNumbers) return [];

        const dataFilter = {
          or: [...postNumbers.map((nums) => ({id: {eq: nums.postId}}))],
        };
        const dataPosts = await API.graphql({query: queries.listPosts, variables: {filter: dataFilter}});
        const posts: Array<Post> = checkForData(dataPosts, 'listPosts', true);

        if (!posts) return [];

        const fullPosts: Array<FullPost> = await context.dispatch(PUT_TOGETHER_POSTS, posts);
        fullPosts.sort((a,b) => a.echoes - b.echoes > 0 ? -1 : a.echoes - b.echoes < 0 ? 1 : a.listens - b.listens > 0 ? -1 : a.listens - b.listens < 0 ? 1 : 0);
        return fullPosts;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMyPopularPosts'});
        return [];
      }
    },
    async serverGetMyPosts(context: any, { sort, sortType }: any): Promise<Array<FullPost>> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return [];

        const filter = {};
        let numbersData: Array<PostNumbers> = [];

        if (sort === sorts.Sort.Echoes) {
          const data = await API.graphql({query: queries.byUsernameEchoes, variables: {
            username: user.username,
            echoes: {},
            sortDirection: 'DESC',
          }});
          numbersData = checkForData(data, 'byUsernameEchoes', true);
        } else if (sort === sorts.Sort.Listens) {
          const data = await API.graphql({query: queries.byUsernameListens, variables: {
            username: user.username,
            listens: {},
            sortDirection: 'DESC',
          }});
          numbersData = checkForData(data, 'byUsernameListens', true);
        }

        if (sort !== sorts.Sort.Recent || sortType !== sorts.Type.All) {
          //We have sort stuff here... a little more complex.
          if (sort !== sorts.Sort.Recent) filter['or'] = [...numbersData.map((data) => ({id: {eq: data.postId}}))];
          if (sortType !== sorts.Type.All) filter['type'] = {eq: sortType};

          const data = await API.graphql({query: queries.postsByUsername, variables: {
            username: user.preferredUsername,
            createdAt: {},
            filter,
            sortDirection: 'DESC',
          }});
          const posts = checkForData(data, 'postsByUsername', true);
          const fullPosts: Array<FullPost> = await context.dispatch(PUT_TOGETHER_POSTS, posts);

          if (sort === sorts.Sort.Echoes) {
            fullPosts.sort((a, b) => a.echoes > b.echoes ? -1 : a.echoes < b.echoes ? 1 : 0);
          } else if (sort === sorts.Sort.Listens) {
            fullPosts.sort((a, b) => a.listens > b.listens ? -1 : a.listens < b.listens ? 1 : 0);
          }
          
          return fullPosts;
        }
        
        // No sorting... just return the most recent stuff.
        const data = await API.graphql({query: queries.postsByUsername, variables: {
          username: user.preferredUsername,
          createdAt: {},
          sortDirection: 'DESC',
        }});
        const posts = checkForData(data, 'postsByUsername', true);
        return await context.dispatch(PUT_TOGETHER_POSTS, posts);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMyPosts'});
        return [];
      }
    },
    async serverGetMyPostStatuses(context: any, ids: Array<string>): Promise<Array<PostStatus>> {
      try {
        if (!context.getters['getUser']) return [];

        const filter = {
          or: [...ids.map((id) => ({postId: {eq: id}}))],
        };

        const data = await API.graphql({query: queries.listPostStatuss, variables: {filter,}});
        return checkForData(data, 'listPostStatuss', true);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMyPostStatuses'});
        return [];
      }
    },
    async serverGetMyRecentPosts(context: any): Promise<Array<FullPost>> {
      try {
        const blocks: Blocks = await context.dispatch(GET_BLOCKS);
        const user: User = context.getters['getUser'];
        if (!user) return [];

        const listenTos: Array<Listening> = await context.dispatch(GET_MY_LISTENING_TO);
        const ors = [
          {username: {eq: user.preferredUsername}},
          ...listenTos.map((listen) => ({username: {eq: listen.profile.username}})),
        ];

        const filter = {
          type: {ne: Type.Deleted},
          or: ors,
        };
        if (blocks && blocks.blackList.length > 0) {
          filter['and'] = [...blocks.blackList.map((user) => ({username: {ne: user}}))];
        }

        const data = await API.graphql({query: queries.postsByDate, variables: {
          exists: 'true',
          createdAt: {
            // beginsWith: '2021', <-- Left here to show an example
          },
          sortDirection: 'DESC',
          filter,
        }});
        const posts: Array<Post> = checkForData(data, 'postsByDate', true);

        if (!posts) return [];

        return await context.dispatch(PUT_TOGETHER_POSTS, posts);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetMyRecentPosts'});
        return [];
      }
    },
    async serverGetOfficialPost(context: any): Promise<OfficialPost|null> {
      try {
        const data = await API.graphql({query: queries.officialPostsByDate, variables: {
          isOfficial: 'true',
          createAt: {},
          sortDirection: 'DESC',
          limit: 1,
        }, authMode: API_KEY});
        const dataArr: Array<OfficialPost> = checkForData(data, 'officialPostsByDate', true);
        const officialPost: OfficialPost|null = dataArr && dataArr.length > 0 ? dataArr[0] : null;
        return officialPost;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetOfficialPost'});
        return null;
      }
    },
    async serverGetPopularGroupPosts(context: any, name: string): Promise<Array<FullPost>> {
      try {
        const lower = name.toLowerCase();
        const blocks: Blocks = await context.dispatch(GET_BLOCKS);
        const numbersData = await API.graphql({query: queries.byGroupBoth, variables: {
          groupId: lower,
          echoesListens: {},
          sortDirection: 'DESC',
        }, authMode: API_KEY});
        const numbers: Array<PostNumbers> = checkForData(numbersData, 'byGroupBoth', true);

        if (!numbers || numbers.length === 0) return [];

        const filter = {
          type: {ne: Type.Deleted},
          or: [...numbers.map((nums) => ({id: {eq: nums.postId}}))],
        };
        if (blocks && blocks.blackList.length > 0) {
          filter['and'] = [...blocks.blackList.map((user) => ({username: {ne: user}}))];
        }

        const data = await API.graphql({query: queries.listPosts, variables: {filter}, authMode: API_KEY});
        const posts: Array<Post> = checkForData(data, 'listPosts', true);
        
        const fullPosts: Array<FullPost> = await context.dispatch(PUT_TOGETHER_POSTS, posts);
        fullPosts.sort((a,b) => a.echoes - b.echoes > 0 ? -1 : a.echoes - b.echoes < 0 ? 1 : a.listens - b.listens > 0 ? -1 : a.listens - b.listens < 0 ? 1 : 0);
        return fullPosts;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetPopularGroupPosts'});
        return [];
      }
    },
    async serverGetPopularPosts(context: any, username: string = ''): Promise<Array<FullPost>> {
      try {
        let numbers: Array<PostNumbers> = [];

        const blocks: Blocks = await context.dispatch(GET_BLOCKS);

        if (username) {
          const data = await API.graphql({query: queries.byUsernameBoth, variables: {
            username: username.toLowerCase(),
            echoesListens: {},
            sortDirection: 'DESC',
          }, authMode: API_KEY});
          numbers = checkForData(data, 'byUsernameBoth', true);
        } else {
          const data = await API.graphql({query: queries.byAllBoth, variables: {
            exists: 'true',
            echoesListens: {},
            sortDirection: 'DESC',
          }, authMode: API_KEY});
          numbers = checkForData(data, 'byAllBoth', true);
        }

        if (!numbers) return [];

        const filter = {
          type: {ne: Type.Deleted},
          or: [...numbers.map((nums) => ({id: {eq: nums.postId}}))],
        };
        if (blocks && blocks.blackList.length > 0) {
          filter['and'] = [...blocks.blackList.map((user) => ({username: {ne: user}}))];
        }

        const data = await API.graphql({query: queries.listPosts, variables: {filter}, authMode: API_KEY});
        const posts: Array<Post> = checkForData(data, 'listPosts', true);

        const fullPosts: Array<FullPost> = await context.dispatch(PUT_TOGETHER_POSTS, posts);

        await context.dispatch(GET_MY_LISTENING_TO);

        fullPosts.sort((a,b) => a.echoes - b.echoes > 0 ? -1 : a.echoes - b.echoes < 0 ? 1 : a.listens - b.listens > 0 ? -1 : a.listens - b.listens < 0 ? 1 : 0);
        return fullPosts;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetPopularPosts'});
        return [];
      }
    },
    async serverGetPost(context: any, postId: string): Promise<Post|null> {
      try {
        const blocks: Blocks = await context.dispatch(GET_BLOCKS);
        const data = await API.graphql({query: queries.getPost, variables: {id: postId}, authMode: API_KEY});
        const post: Post = checkForData(data, 'getPost');

        // Check to see if this post is on a blacklist... don't send if it is...
        if (blocks && post) {
          const username = post.username.toLowerCase();
          if (blocks.blackList.some((user) => user === username)) return null;
        }

        return post;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetPost'});
        return null;
      }
    },
    async serverGetPostAndFamily(context: any, postId: string): Promise<PostFamily|null> {
      try {
        const filter = {type: {ne: Type.Deleted}};
        const origPost: Post = await context.dispatch(GET_POST, postId);

        if (!origPost) return null;

        // Get the parent, if any...
        const parentPost: Post = origPost.parentId ? await context.dispatch(GET_POST, origPost.parentId) : null;
        
        // Get any children...
        const childrenData = await API.graphql({query: queries.postsByParentId, variables: {
          parentId: postId,
          createdAt: {},
          filter,
          sortDirection: 'DESC',
        }, authMode: API_KEY});
        const children: Array<Post> = checkForData(childrenData, 'postsByParentId', true);
        const parents: Array<FullPost> = parentPost ? await context.dispatch(PUT_TOGETHER_POSTS, [parentPost]) : null;
        const parent = parents && parents.length > 0 ? parents[0] : null;
        const fullPosts: Array<FullPost> = origPost ? await context.dispatch(PUT_TOGETHER_POSTS, [origPost]) : null;
        const fullPost = fullPosts && fullPosts.length > 0 ? fullPosts[0] : null;

        if (!fullPost) return null;

        return new PostFamily({
          children: children && children.length > 0 ? await context.dispatch(PUT_TOGETHER_POSTS, children) : [],
          fullPost,
          parent,
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetPostAndFamily'});
        return null;
      }
    },
    async serverGetPosts(context: any, ids: Array<string>): Promise<Array<Post>> {
      try {
        const blocks: Blocks = await context.dispatch(GET_BLOCKS);
        const filter = {
          or: [...ids.map((id) => ({id: {eq: id}}))],
        };
        if (blocks && blocks.blackList.length > 0) {
          filter['and'] = [...blocks.blackList.map((user) => ({username: {ne: user}}))];
        }
        const data = await API.graphql({query: queries.listPosts, variables: {filter}, authMode: API_KEY});
        return checkForData(data, 'listPosts', true);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetPosts'});
        return [];
      }
    },
    async serverGetPostNumbers(context: any, ids: Array<string>): Promise<Array<PostNumbers>> {
      try {
        const filter = {
          or: [...ids.map((id) => ({postId: {eq: id}}))],
        };
        const data = await API.graphql({query: queries.listPostNumberss, variables: {filter,}, authMode: API_KEY});
        return checkForData(data, 'listPostNumberss', true);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetPostNumbers'});
        return [];
      }
    },
    async serverGetProfile(context: any, username: string): Promise<Profile|null> {
      try {
        const lower = username.toLowerCase();
        const data = await API.graphql({query: queries.getProfile, variables: {id: lower}, authMode: API_KEY});
        const profile = checkForData(data, 'getProfile');

        if (!profile) return null;

        const listen: Listen = await context.dispatch(GET_LISTEN_DATA, lower);
        return {
          ...profile,
          listeners: listen ? listen.listenersNum : 0,
          listeningTo: listen ? listen.listeningToNum : 0,
        };
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetProfile'});
        return null;
      }
    },
    async serverGetProfiles(context: any, usernames: Array<string>): Promise<Array<Profile>> {
      try {
        const filter = {
          or: [...usernames.map((name) => ({id: {eq: name.toLowerCase()}}))],
        };
        const data = await API.graphql({query: queries.searchProfiles, variables: {filter,}, authMode: API_KEY});
        return checkForData(data, 'searchProfiles', true);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetProfiles'});
        return [];
      }
    },
    async serverGetRecentEchoes(context: any, username: string): Promise<Array<FullPost>> {
      try {
        const filter = {
          type: {ne: Type.Deleted},
          parentId: {ne: ' '},
        };
        const data = await API.graphql({query: queries.postsByUsername, variables: {
          username,
          createdAt: {},
          filter,
          sortDirection: 'DESC',
        }, authMode: API_KEY});
        const posts = checkForData(data, 'postsByUsername', true);

        return await context.dispatch(PUT_TOGETHER_POSTS, posts);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetRecentEchoes'});
        return [];
      }
    },
    async serverGetRecentGroupPosts(context: any, name: string): Promise<Array<FullPost>> {
      try {
        const lower = name.toLowerCase();
        const blocks: Blocks = await context.dispatch(GET_BLOCKS);
        const filter = {
          type: {ne: Type.Deleted},
        };
        if (blocks && blocks.blackList.length > 0) {
          filter['and'] = [...blocks.blackList.map((user) => ({username: {ne: user}}))];
        }

        const data = await API.graphql({query: queries.byGroup, variables: {
          groupId: lower,
          createdAt: {},
          sortDirection: 'DESC',
          filter,
        }, authMode: API_KEY});
        const posts: Array<Post> = checkForData(data, 'byGroup', true);
        return await context.dispatch(PUT_TOGETHER_POSTS, posts);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetRecentGroupPosts'});
        return [];
      }
    },
    async serverGetRecentPosts(context: any, username: string = ''): Promise<Array<FullPost>> {
      try {
        const blocks: Blocks = await context.dispatch(GET_BLOCKS);
        const filter = {
          type: {ne: Type.Deleted},
        };
        if (blocks && blocks.blackList.length > 0) {
          filter['and'] = [...blocks.blackList.map((user) => ({username: {ne: user}}))];
        }
        let posts: any = null;

        if (username) {
          const data = await API.graphql({query: queries.postsByUsername, variables: {
            username: username,
            createdAt: {},
            sortDirection: 'DESC',
            filter,
          }, authMode: API_KEY});
          posts = checkForData(data, 'postsByUsername', true);
        } else {
          const data = await API.graphql({query: queries.postsByDate, variables: {
            exists: 'true',
            createdAt: {},
            sortDirection: 'DESC',
            filter,
          }, authMode: API_KEY});
          posts = checkForData(data, 'postsByDate', true);
        }

        if (!posts) return [];

        await context.dispatch(GET_MY_LISTENING_TO);

        return await context.dispatch(PUT_TOGETHER_POSTS, posts);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetRecentPosts'});
        return [];
      }
    },
    async serverGetRecentShouts(context: any, username: string): Promise<Array<FullPost>> {
      try {
        const filter = {
          type: {ne: Type.Deleted},
          parentId: {eq: ' '},
        };
        const data = await API.graphql({query: queries.postsByUsername, variables: {
          username,
          createdAt: {},
          filter,
          sortDirection: 'DESC',
        }, authMode: API_KEY});
        const posts = checkForData(data, 'postsByUsername', true);

        return await context.dispatch(PUT_TOGETHER_POSTS, posts);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetRecentShouts'});
        return [];
      }
    },
    async serverGetTrending(context: any): Promise<Trending|null> {
      try {
        const grpData = await API.graphql({query: queries.trendingByDate, variables: {
          type: 'GROUP',
          createdAt: {},
          sortDirection: 'DESC',
          limit: 30,
        }, authMode: API_KEY});
        const tagData = await API.graphql({query: queries.trendingByDate, variables: {
          type: 'HASHTAG',
          createdAt: {},
          sortDirection: 'DESC',
          limit: 50,
        }, authMode: API_KEY});
        const mentionData = await API.graphql({query: queries.trendingByDate, variables: {
          type: 'MENTION',
          createdAt: {},
          sortDirection: 'DESC',
          limit: 20,
        }, authMode: API_KEY});

        const grps: Array<any> = checkForData(grpData, 'trendingByDate', true);
        const tags: Array<any> = checkForData(tagData, 'trendingByDate', true);
        const mentions: Array<any> = checkForData(mentionData, 'trendingByDate', true);

        return new Trending({
          groups: grps.map((grp: any) => new GroupTrending({description: grp.description, group: grp.value})),
          hashtags: tags.map((tag: any) => new Hashtag({hashtag: tag.value})),
          mentions: mentions.map((mention: any) => new Mention({mention: mention.value, profileIcon: mention.icon})),
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverGetTrending'});
        return null;
      }
    },
    async serverHideOrShowPost(context: any, postId: string): Promise<boolean> {
      try {
        const statuses: Array<PostStatus> = await context.dispatch(GET_MY_POST_STATUSES, [postId]);
        let status: PostStatus|null = statuses && statuses.length > 0 ? statuses[0] : null;
        let hide = true;

        // Update or create the PostStatus for the user...
        if (!status) {
          status = await context.dispatch(CREATE_NEW_POST_STATUS, postId);
          if (!status) return false;
        } else {
          hide = !status.isHidden;
        }
        
        const updatedStatus = {
          addedListen: status.addedListen,
          id: status.id,
          isHidden: hide,
          postId: status.postId,
          reportReason: status.reportReason,
          _version: status['_version']
        };
        const data = await API.graphql({query: mutations.updatePostStatus, variables: {input: updatedStatus}});
        const postData = checkForData(data, 'updatePostStatus');

        return postData ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverHideOrShowPost'});
        return false;
      }
    },
    async serverLogin(context: any, loginInfo: BasicLogin): Promise<any> {
      try {
        const login = await Auth.signIn(loginInfo.username, loginInfo.password);

        if (login && login.username) {
          //Check to make sure we have a profile for this user...
          context.dispatch(GET_PROFILE, login.username)
            .then((profile: Profile) => {
              if (!profile) {
                const { attributes } = login;
                const username = attributes.preferred_username;
                context.dispatch(CREATE_PROFILE, username)
                  .then(() => context.dispatch('setUser'))
                  .then(() => context.dispatch(GET_MY_LISTENING_TO));
              }
            })
            .finally(() => context.dispatch('setUser'));
        }
        return login;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverLogin'});
        return error;
      }
    },
    async serverPutTogetherPosts(context: any, posts: Array<Post>): Promise<Array<FullPost>> {
      try {
        if (!posts || posts.length === 0) return [];

        const hasParents = posts.filter((post) => post.parentId.trim());
        const parentPosts = hasParents && hasParents.length > 0 ? await context.dispatch(GET_POSTS, hasParents.map((post) => post.parentId)) : [];
        const totalPosts: Array<Post> = [...posts, ...parentPosts];
        const ids = totalPosts.map((post) => post.id);
        const statuses = await context.dispatch(GET_MY_POST_STATUSES, ids);
        const numbers = await context.dispatch(GET_POST_NUMBERS, ids);

        return posts.map((post) => {
          const foundNums: PostNumbers | undefined = numbers.find((nums: PostNumbers) => nums.postId === post.id);
          const foundStatus: PostStatus | undefined = statuses.find((status: PostStatus) => status.postId === post.id);
          const foundParent: Post | undefined = post.parentId ? parentPosts.find((p: Post) => post.parentId === p.id) : undefined;

          return new FullPost({
            addedListen: foundStatus ? foundStatus.addedListen : false,
            associatedPost: foundParent ? foundParent : null,
            echoes: foundNums ? foundNums.echoes : 0,
            isHidden: foundStatus ? foundStatus.isHidden : false,
            listens: foundNums ? foundNums.listens : 0,
            post,
            reportReason: foundStatus && foundStatus.reportReason ? foundStatus.reportReason : '',
          });
        });
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverPutTogetherPosts'});
        return [];
      }
    },
    async serverRemoveEchoNotification(context: any, postId: string): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: queries.echoesByUsername, variables: {
          username: user.username,
          newNotifications: {},
        }});
        const notifications: Array<EchoNotification> = checkForData(data, 'echoesByUsername', true);

        if (!notifications || notifications.length === 0) return true;

        const found: EchoNotification|undefined = notifications.find((not) => not.postId === postId);
        if (!found) return true;

        API.graphql({query: mutations.deleteEchoNotifications, variables: {input: {id: found.id}}});
        return true;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverRemoveEchoNotification'});
        return false;
      }
    },
    async serverRemoveMentionNotifications(context: any): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: queries.mentionNotificationsByUsername, variables: {
          username: user.username,
          postNumber: {},
        }});
        const notifications: Array<any> = checkForData(data, 'mentionNotificationsByUsername', true);

        if (!notifications || notifications.length === 0) return true;

        notifications.forEach((notification) =>
          API.graphql({query: mutations.deleteMentionNotifications, variables: {input: {id: notification.id}}})
        );
        return true;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverRemoveMentionNotifications'});
        return false;
      }
    },
    async serverReportGroup(context: any, { id, reportReason, username }: any): Promise<boolean> {
      try {
        const lower = username.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        // Make sure this user hasn't already added a report for this group that has yet to be addressed.
        const filter = {reportedBy: {eq: user.username}, resolved: {eq: false}};
        const hasData = await API.graphql({query: queries.reportGroupByUsername, variables: {
          username: lower,
          filter,
        }});
        const hasReportedData: Array<any> = checkForData(hasData, 'reportGroupByUsername', true);
        
        if (hasReportedData && hasReportedData.length > 0) return true; // Have already reported this profile.

        const newReport = {
          groupId: id,
          notes: '',
          reportedBy: user.username,
          reportReason,
          resolved: false,
          username: lower,
        };

        const data = await API.graphql({query: mutations.createReportGroup, variables: {input: newReport}});
        return checkForData(data, 'createReportGroup') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverReportGroup'});
        return false;
      }
    },
    async serverReportProfile(context: any, { username, reportReason }: any): Promise<boolean> {
      try {
        const lower = username.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        // Make sure this user hasn't already added a report for this profile that has yet to be addressed.
        const filter = {reportedBy: {eq: user.username}, resolved: {eq: false}};
        const hasData = await API.graphql({query: queries.reportProfileByUsername, variables: {
          username: lower,
          filter,
        }});
        const hasReportedData: Array<any> = checkForData(hasData, 'reportProfileByUsername', true);
        
        if (hasReportedData && hasReportedData.length > 0) return true; // Have already reported this profile.

        const newReport = {
          notes: '',
          reportedBy: user.username,
          reportReason,
          resolved: false,
          username: lower,
        };

        const data = await API.graphql({query: mutations.createReportProfile, variables: {input: newReport}});
        return checkForData(data, 'createReportProfile') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverReportProfile'});
        return false;
      }
    },
    async serverReportPost(context: any, { id, reportReason }: any): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        // Grab the associated post...
        const post: Post = await context.dispatch(GET_POST, id);

        if (!post) return false;

        // Make sure this user hasn't already added a report for this profile that has yet to be addressed.
        const filter = {reportedBy: {eq: user.username}, resolved: {eq: false}, postId: {eq: id}};
        const hasData = await API.graphql({query: queries.reportPostByUsername, variables: {
          username: post.username,
          filter,
        }});
        const hasReportedData: Array<any> = checkForData(hasData, 'reportPostByUsername', true);

        if (hasReportedData && hasReportedData.length > 0) return true; // Have already reported this post.

        const newReport = {
          notes: '',
          postId: id,
          reportedBy: user.username,
          reportReason,
          resolved: false,
          username: post.username,
        };

        const data = await API.graphql({query: mutations.createReportPost, variables: {input: newReport}});
        const isSuccess = checkForData(data, 'createReportPost') ? true : false;

        // Update this user's post status to hide it.
        const statusArr: Array<PostStatus> = await context.dispatch(GET_MY_POST_STATUSES, [id]);
        if (statusArr && statusArr.length > 0) {
          const status = statusArr[0];
          const updatedStatus = {
            addedListen: status.addedListen,
            id: status.id,
            isHidden: true,
            postId: status.postId,
            reportReason,
            _version: status['_version']
          };
          API.graphql({query: mutations.updatePostStatus, variables: {input: updatedStatus}});
        } else {
          await API.graphql({query: mutations.createPostStatus, variables: {input: {
            addedListen: false,
            isHidden: true,
            postId: id,
            reportReason,
          }}});
        }

        return isSuccess;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverReportPost'});
        return false;
      }
    },
    async serverResendConfirmationCode(context: any, username: string): Promise<any> {
      try {
        return await Auth.resendSignUp(username);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverResendConfirmationCode'});
        return error;
      }
    },
    async serverSearch(context: any, search: Search): Promise<Search|null> {
      try {
        const terms = search.searchString.split(' ').map((term: string) => term.toLowerCase());
        const option = search.searchOption ? search.searchOption : 'All';

        if (!search || !search.searchString) {
          throw('NO Search!');
        } else {
          let tags: Array<string> = [];
          let posts: Array<FullPost> = [];
          let profiles: Array<Profile> = [];

          if (option === 'All' || option === 'Profiles') {
            const filter = {};
            let ors: Array<any> = [];

            for (let term of terms) {
              const wildcard = {wildcard: `*${term.toLowerCase()}*`};
              ors.push({username: wildcard});
              ors.push({firstName: wildcard});
              ors.push({lastName: wildcard});
              ors.push({blurb: wildcard});
            }
            filter['or'] = [...ors];
            const data = await API.graphql({query: queries.searchProfiles, variables: {filter,}, authMode: API_KEY});

            profiles = checkForData(data, 'searchProfiles', true);
          }
          if (option === 'All' || option === 'Hashtags') {
            const filter = {
              or: [...terms.map((term) => ({id: {contains: term}}))],
            };
            const data = await API.graphql({query: queries.listHashtags, variables: {filter}, authMode: API_KEY});
            const hashtags = checkForData(data, 'listHashtags', true);

            tags = [...hashtags.map((tag) => tag.id)];
          }
          if (option === 'All' || option === 'Shouts') {
            const filter = {
              type: {ne: Type.Deleted},
              or: [...terms.map((term) => ({message: {contains: term}}))],
            };
            const data = await API.graphql({query: queries.listPosts, variables: {filter,}, authMode: API_KEY});
            const postsData = checkForData(data, 'listPosts', true);
            
            posts = await context.dispatch(PUT_TOGETHER_POSTS, postsData);
          }

          const results = new Search({...search});
          results.hashtags = tags;
          results.posts = posts;
          results.profiles = profiles;

          return results;
        }
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverSearch'});
        return null;
      }
    },
    async serverSearchGroups(context: any, search: Search): Promise<Search|null> {
      try {
        const terms = search.searchString.split(' ').map((term: string) => term.toLowerCase());
        const filter = {};
        const allResults = new Search({...search});
        let ors: Array<any> = [];

        for (let term of terms) {
          ors.push({description: {contains: term.toLowerCase()}});
          ors.push({name: {contains: term.toLowerCase()}});
        }
        filter['or'] = [...ors];

        if (search.category) {
          if (!search.include) filter['and'] = {categories: {ne: search.category}};
          else filter['and'] = {categories: {eq: search.category}};
        }

        for (let x = 0; x <= search.locations.length; x++) {
          if (search.locations[x]) {
            filter['and'] = {...filter['and'], locations: {contains: search.locations[x]}};
          }

          const data = await API.graphql({query: queries.listGroups, variables: {filter}, authMode: API_KEY});
          const groups: Array<Group> = checkForData(data, 'listGroups', true);
          
          if (groups && groups.length) {

            const myGroups: Array<Group> = context.getters['myGroups'];
            if (!myGroups || myGroups.length === 0) await context.dispatch(GET_MY_GROUPS);
            
            groups.forEach((grp) => {
              if (!allResults.groups.some((g) => g.id === grp.id)) {
                allResults.groups.push(grp);
              }
            });
          }
        }

        return allResults;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverSearchGroups'});
        return null;
      }
    },
    async serverSendContact(context: any, { message, topic }: any): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];

        const newContact = {
          message,
          notes: '',
          resolved: false,
          topic,
          username: user ? user.username : '',
        };
        
        const data = await API.graphql({query: mutations.createContact, variables: {input: newContact}, authMode: API_KEY});
        return checkForData(data, 'createContact') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverSendContact'});
        return false;
      }
    },
    async serverSendForgotEmail(context: any, username: string): Promise<any> {
      try {
        return await Auth.forgotPassword(username);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverSendForgotEmail'});
        return null;
      }
    },
    async serverSignUp(context: any, loginInfo: BasicLogin): Promise<any> {
      try {
        const response = await Auth.signUp({
          password: loginInfo.password,
          username: loginInfo.username,
          attributes: {
            email: loginInfo.email,
            picture: '',
            preferred_username: loginInfo.preferredUsername,
          }
        });
        return response.user;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverSignUp'});
        return error;
      }
    },
    async serverUpdateAdminContact(context: any, updatedContact: Contact): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: mutations.updateContact, variables: {input: updatedContact}});
        return checkForData(data, 'updateContact') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateAdminContact'});
        return false;
      }
    },
    async serverUpdateAdminError(context: any, updatedError): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: mutations.updateErrorCatch, variables: {input: updatedError}});
        return checkForData(data, 'updateErrorCatch') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateAdminError'});
        return false;
      }
    },
    async serverUpdateAdminReportedGroup(context: any, updatedGroup: ReportedGroup): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: mutations.updateReportGroup, variables: {input: updatedGroup}});
        return checkForData(data, 'updateReportGroup') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateAdminReportedGroup'});
        return false;
      }
    },
    async serverUpdateAdminReportedPost(context: any, updatedPost: ReportedPost): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: mutations.updateReportPost, variables: {input: updatedPost}});
        return checkForData(data, 'updateReportPost') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateAdminReportedPost'});
        return false;
      }
    },
    async serverUpdateAdminReportedProfile(context: any, updatedProfile: ReportedProfile): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: mutations.updateReportProfile, variables: {input: updatedProfile}});
        return checkForData(data, 'updateReportProfile') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateAdminReportedProfile'});
        return false;
      }
    },
    async serverUpdateAdminReportedUser(context: any, updatedUser: ReportedUser): Promise<boolean> {
      try {
        const lower = updatedUser.username.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const filter = {username: {eq: lower}};
        const data = await API.graphql({query: queries.listReportUsers, variables: {filter}});
        const reportedUsers: Array<ReportedUser> = checkForData(data, 'listReportUsers', true);
        const reportedUser = reportedUsers && reportedUsers.length > 0 ? reportedUsers[0] : null;

        if (!reportedUser) return false;

        const updatedData = await API.graphql({query: mutations.updateReportUser, variables: {input: {
          ...updatedUser,
          count: reportedUser.count,
        }}});
        return checkForData(updatedData, 'updateReportUser') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateAdminReportedUser'});
        return false;
      }
    },
    async serverUpdateEchoNotification(context: any, postId: string): Promise<boolean> {
      try {
        const filter = {postId: {eq: postId}};
        const data = await API.graphql({query: queries.listEchoNotificationss, variables: {filter}});
        const notifications: Array<EchoNotification> = checkForData(data, 'listEchoNotificationss', true);
        const notification = notifications && notifications.length > 0 ? notifications[0] : null;

        if (!notification) return false;

        const updateData = await API.graphql({query: mutations.updateEchoNotifications, variables: {input: new EchoNotification({
          ...notification,
          newNotifications: notification.newNotifications + 1,
        })}});
        return checkForData(updateData, 'updateEchoNotifications') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateEchoNotification'});
        return false;
      }
    },
    async serverUpdateFollowingGroup(context: any, id: string): Promise<boolean> {
      try {
        const user: User = context.getters['getUser'];
        if (!user) return false;

        let myGroups: Array<ListeningGroup> = context.getters['myGroups'];

        if (!myGroups || myGroups.length === 0) {
          await context.dispatch(GET_MY_GROUPS);
          myGroups = context.getters['myGroups'];
        }

        const foundSub = myGroups.find((lg) => lg.groupId === id);
        if (foundSub) {
          // If we have a status of DENIED, don't do anything!
          if (foundSub.status === SubscriptionStatus.DENIED) return false;

          // Remove the subscription...
          return await context.dispatch(DELETE_GROUP_SUBSCRIPTION, id);
        }

        // Add a subscription
        return await context.dispatch(CREATE_NEW_GROUP_SUBSCRIPTION, {groupId: id, status: SubscriptionStatus.REQUESTED});
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateFollowingGroup'});
        return false;
      }
    },
    async serverUpdateGroup(context: any, group: Group): Promise<Group|null> {
      try {
        const updatedGroup = {
          banner: group.banner ? `${IMAGE_LOC}${group.id}_group_banner` : '',
          categories: group.categories,
          createdBy: group.createdBy,
          description: group.description,
          id: group.id,
          isOwnerOnly: group.isOwnerOnly,
          listeners: group.listeners < 0 ? 0 : group.listeners,
          locations: group.locations,
          name: group.name,
          preferredName: group.preferredName,
          posts: group.posts,
          requestedList: group.requestedList,
        };
        const data = await API.graphql({query: mutations.updateGroup, variables: {input: updatedGroup}});
        return checkForData(data, 'updateGroup');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateGroup'});
        return null;
      }
    },
    async serverUpdateGroupSubscription(context: any, updatedSub: any): Promise<any|null> {
      try {
        const data = await API.graphql({query: mutations.updateGroupSubscriptions, variables: {input: updatedSub}});
        return checkForData(data, 'updateGroupSubscriptions');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateGroupSubscription'});
        return null;
      }
    },
    async serverUpdateGroupSubscriptionNotification(context: any, name: string): Promise<boolean> {
      try {
        const lower = name.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const subData = await API.graphql({query: custom.groupSubscriptionsByUsername, variables: {
          username: user.username,
        }});
        const subs: Array<any> = checkForData(subData, 'groupSubscriptionsByUsername', true);
        const sub: ListeningGroup = subs.find((sub) => sub.group.items[0].name === lower);
        const group: Group|null = sub && sub.group && sub.group['items'] ? sub.group['items'][0] : null;

        if (!sub || !group) return true;

        let myGroups = context.getters['myGroups'];
        if (!myGroups || myGroups.length === 0) {
          myGroups = await context.dispatch(GET_MY_GROUPS);
        }

        const myGroup: ListeningGroup = myGroups.find((grp) => grp.groupId === group.id);
        if (!myGroup) return true;

        const updatedSub = {
          groupId: group.id,
          id: myGroup.id,
          lastCheckedPostNumber: group.posts,
          status: sub.status,
          username: user.username,
        };
        API.graphql({query: mutations.updateGroupSubscriptions, variables: {input: updatedSub}});
        return true;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateGroupSubscriptionNotification'});
        return false;
      }
    },
    async serverUpdateHashtagNotification(context: any, hashtag: string): Promise<boolean> {
      try {
        const lower = hashtag.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const data = await API.graphql({query: queries.hashtagsByUsername, variables: {
          username: user.username,
          hashtag: {eq: lower},
        }});
        const notifications: Array<HashtagNotification> = checkForData(data, 'hashtagsByUsername', true);
        const notification = notifications && notifications.length > 0 ? notifications[0] : null;

        if (!notification) return false;

        const updateData = await API.graphql({query: mutations.updateHashtagNotifications, variables: {input: new HashtagNotification({
          ...notification,
          newNotifications: 0,
        })}});
        return checkForData(updateData, 'updateHashtagNotifications') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateHashtagNotification'});
        return false;
      }
    },
    async serverUpdateHashtagSub(context: any, hashtag: string): Promise<boolean> {
      try {
        const lower = hashtag.toLowerCase();
        const user: User = context.getters['getUser'];
        if (!user) return false;

        const notification = await context.dispatch(CHECK_FOR_HASHTAG_SUB, lower);

        if (notification) {
          // Unsubscribe... basically delete it.
          const deleteData = await API.graphql({query: mutations.deleteHashtagNotifications, variables: {input: notification}});
          return checkForData(deleteData, 'deleteHashtagNotifications') ? true : false;
        } else {
          // Subscribe!
          const subData = await API.graphql({query: mutations.createHashtagNotifications, variables: {input: {
            hashtag: lower,
            newNotifications: 0,
            username: user.username,
          }}});
          return checkForData(subData, 'createHashtagNotifications') ? true : false;
        }
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateHashtagSub'});
        return false;
      }
    },
    async serverUpdateListen(context: any, listen: Listen): Promise<Listen|null> {
      try {
        const data = await API.graphql(graphqlOperation(mutations.updateListen, {input: listen}));
        return checkForData(data, 'updateListen');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateListen'});
        return null;
      }
    },
    async serverUpdateListening(context: any, id: string): Promise<boolean> {
      try {
        const statuses: Array<PostStatus> = await context.dispatch(GET_MY_POST_STATUSES, [id]);
        let status: PostStatus|null = statuses && statuses.length > 0 ? statuses[0] : null;
        let addedListen = true;

        // Update or create the PostStatus for the user...
        if (!status) {
          status = await context.dispatch(CREATE_NEW_POST_STATUS, id);
          if (!status) return false;
        } else {
          addedListen = !status.addedListen;
        }
        
        const updatedStatus = {
          addedListen,
          id: status.id,
          isHidden: status.isHidden,
          postId: status.postId,
          reportReason: status.reportReason,
          _version: status['_version']
        };
        const data = await API.graphql({query: mutations.updatePostStatus, variables: {input: updatedStatus}});
        const savedStatus = checkForData(data, 'updatePostStatus');

        if (!savedStatus) return false;
        
        // Update the actual count for the Post...
        const numbers: Array<PostNumbers> = await context.dispatch(GET_POST_NUMBERS, [id]);
        const number: PostNumbers|null = numbers && numbers.length > 0 ? numbers[0] : null;

        if (!number) return false;

        const updatedNumber = {
          postId: id,
          listens: addedListen ? 1 : -1,
        };

        return await context.dispatch(UPDATE_POST_NUMBERS, updatedNumber);
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateListening'});
        return false;
      }
    },
    async serverUpdateListeningTo(context: any, profileUsername: string): Promise<boolean> {
      try {
        const lower = profileUsername.toLowerCase();
        const user: User = context.getters['getUser'];
        const userLower = user.username.toLowerCase();
        const myListen: Listen | null = await context.dispatch(GET_LISTEN_DATA, user.username);
        const theirListen: Listen | null = await context.dispatch(GET_LISTEN_DATA, lower);
        
        if (!myListen || !theirListen) return false;
        
        const myFoundIndex = myListen.listeningTo.findIndex((username: string) => username.toLowerCase() === lower);
        const theirFoundIndex = theirListen.listeners.findIndex((username: string) => username.toLowerCase() === userLower);

        //Update my stuff first...
        if (myFoundIndex === -1) {
          myListen.listeningTo.splice(0, 0, lower);
          myListen.listeningToNum += 1;
        } else {
          myListen.listeningTo.splice(myFoundIndex, 1);
          myListen.listeningToNum -= 1;
          if (myListen.listeningToNum < 0) myListen.listeningToNum = 0;
        }

        //Now update their stuff...
        if (theirFoundIndex === -1) {
          theirListen.listeners.splice(0, 0, user.username.toLowerCase());
          theirListen.listenersNum += 1;
        } else {
          theirListen.listeners.splice(theirFoundIndex, 1);
          theirListen.listenersNum -= 1;
          if (theirListen.listenersNum < 0) theirListen.listenersNum = 0;
        }

        //Save it now.
        const mySave = await context.dispatch(UPDATE_LISTEN, new Listen({...myListen}));
        const theirSave = await context.dispatch(UPDATE_LISTEN, new Listen({...theirListen}));

        return mySave && theirSave ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateListeningTo'});
        return false;
      }
    },
    async serverUpdatePost(context: any, post: Post): Promise<Post|null> {
      try {
        const message = post.message.replace(SPACE_REGEX, ' ');
        const data = await API.graphql({query: mutations.updatePost, variables: {input: {...post, message,}}});
        const updatedPost: Post = checkForData(data, 'updatePost');

        if (!updatedPost) return null;

        // If we are report and removing this post, remove any associated image...
        if (post.reportReason && post.image) {
          const fileName = `${post.id}_image`;
          await Storage.remove(fileName);
        }

        return updatedPost;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdatePost'});
        return null;
      }
    },
    async serverUpdatePostNumbers(context: any, { postId, echoes, listens}): Promise<PostNumbers|null> {
      try {
        const postNumbsArr: Array<PostNumbers> = await context.dispatch(GET_POST_NUMBERS, [postId]);
        const postNumbers = postNumbsArr && postNumbsArr.length > 0 ? postNumbsArr[0] : null;

        if (!postNumbers) return null;

        const newNumbers = new PostNumbers({
          ...postNumbers,
          echoes: echoes != undefined ? postNumbers.echoes += echoes : postNumbers.echoes,
          listens: listens != undefined ? postNumbers.listens += listens : postNumbers.listens,
        });

        const data = await API.graphql(graphqlOperation(mutations.updatePostNumbers, {input: newNumbers}));
        return checkForData(data, 'updatePostNumbers');
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdatePostNumbers'});
        return null;
      }
    },
    async serverUpdateProfile(context: any, profile: Profile): Promise<Profile|null> {
      try {
        const updatedProfile = new Profile({
          ...profile,
        });
        const data = await API.graphql(graphqlOperation(mutations.updateProfile, {input: updatedProfile}));
        const newProfile = checkForData(data, 'updateProfile');

        if (newProfile) context.dispatch('setUser');
        return newProfile;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateProfile'});
        return null;
      }
    },
    async serverUpdateTrendingGroup(context: any, name: string): Promise<boolean> {
      try {
        const lower = name.toLowerCase();
        const filter = {value: {eq: lower}};
        const data = await API.graphql({query: queries.trendingByDate, variables: {
          type: 'GROUP',
          createdAt: {},
          filter,
        }});
        const foundArr = checkForData(data, 'trendingByDate', true);
        const found = foundArr && foundArr.length > 0;

        if (found) return true;

        const lg: ListeningGroup = await context.dispatch(GET_GROUP_BY_NAME, lower);
        const { group } = lg;

        if (!group) return true;

        const newTrending = await API.graphql({query: mutations.createTrending, variables: {input: {
          description: group.description,
          type: 'GROUP',
          value: lower,
        }}});
        return checkForData(newTrending, 'createTrending') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateTrendingGroup'});
        return false;
      }
    },
    async serverUpdateTrendingHashtag(context: any, tag: string): Promise<boolean> {
      try {
        const lower = tag.toLowerCase();
        const filter = {value: {eq: lower}};
        const data = await API.graphql({query: queries.trendingByDate, variables: {
          type: 'HASHTAG',
          createdAt: {},
          filter,
        }});
        const foundArr = checkForData(data, 'trendingByDate', true);
        const found = foundArr && foundArr.length > 0;

        if (found) return true;

        const newTrending = await API.graphql({query: mutations.createTrending, variables: {input: {
          type: 'HASHTAG',
          value: lower,
        }}});
        return checkForData(newTrending, 'createTrending') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateTrendingHashtag'});
        return false;
      }
    },
    async serverUpdateTrendingMention(context: any, mention: string): Promise<boolean> {
      try {
        const lower = mention.toLowerCase();
        const filter = {value: {eq: lower}};
        const data = await API.graphql({query: queries.trendingByDate, variables: {
          type: 'MENTION',
          createdAt: {},
          filter,
        }});
        const foundArr = checkForData(data, 'trendingByDate', true);
        const found = foundArr && foundArr.length > 0;

        if (found) return true;

        const profile: Profile = await context.dispatch(GET_PROFILE, lower);

        if (!profile) return true;

        const newTrending = await API.graphql({query: mutations.createTrending, variables: {input: {
          icon: profile.profileIcon,
          type: 'MENTION',
          value: lower,
        }}});
        return checkForData(newTrending, 'createTrending') ? true : false;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUpdateTrendingMention'});
        return false;
      }
    },
    async serverUploadBannerImage(context: any, file: File): Promise<Profile|string|null> {
      try {
        const user = context.getters['getUser'];

        if (!user) return 'No logged in user.';

        const fileName = `${user.username}_banner`;
        const fileType = file.type;

        if (!canUploadImage(file)) return 'Error uploading the image.';

        const image = await Storage.put(fileName, file, {
          level: 'public',
          contentType: fileType,
        });

        if (!image) return 'Error uploading the image.';
        else {
          const imagePath = `${IMAGE_LOC}${fileName}`;

          //Update the user's profile...
          const profile = await context.dispatch(GET_PROFILE, user.username);
          return await context.dispatch(UPDATE_PROFILE, {...profile, banner: imagePath});
        }
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUploadBannerImage'});
        return null;
      }
    },
    async serverUploadGroupBanner(context: any, { file, groupId }): Promise<boolean> {
      try {
        if (!context.getters['getUser']) return false;

        const fileName = `${groupId}_group_banner`;
        const fileType = file.type;

        if (!canUploadImage(file)) return false;

        const image = await Storage.put(fileName, file, {
          level: 'public',
          contentType: fileType,
        });

        return image != null;
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUploadGroupBanner'});
        return false;
      }
    },
    async serverUploadIconImage(context: any, file: File): Promise<Profile|string|null> {
      try {
        const user = context.getters['getUser'];

        if (!user) return 'No logged in user.';

        const fileName = `${user.username}_profile_icon`;
        const fileType = file.type;

        if (!canUploadImage(file)) return 'Error uploading the image.';

        const image = await Storage.put(fileName, file, {
          level: 'public',
          contentType: fileType,
        });

        if (!image) return 'Error uploading the image.';
        else {
          const imagePath = `${IMAGE_LOC}${fileName}`;

          //Update the user's profile...
          const profile = await context.dispatch(GET_PROFILE, user.username);
          return await context.dispatch(UPDATE_PROFILE, {...profile, profileIcon: imagePath});
        }
      } catch(error) {
        if (SERVER_SIDE_ERROR_CATCHING) await context.dispatch(CATCH_ERROR, {error, func: 'serverUploadIconImage'});
        return error;
      }
    },
  },
  mutations: {

  },
  state: {

  },
};

export default Server;
