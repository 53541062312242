


















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import ReportedPost from '@/models/admin/ReportedPost';
import ReportedPostEdit from '@/components/admin/ReportedPostEdit.vue';

@Component({
  components: {
    ReportedPostEdit,
  },
})
export default class ReportedPosts extends Vue {
  @Prop({required: true}) reload!: boolean;

  data: Array<ReportedPost> = [];
  defaultSortDirection = 'asc';
  dontOpen = false;
  filteredData: Array<ReportedPost> = [];
  isModalActive = false;
  record: ReportedPost|null = null;
  selected: any = null;
  viewResolved = 'unresolved';

  get size() {
    return this.$store.getters['isLargeMode'] ? 'is-normal' : 'is-small';
  }

  @Watch('reload')
  reloadChanged(newVal: boolean) {
    if (newVal) this.getData();
  }

  @Watch('selected')
  selectedChanged(newVal: ReportedPost) {
    if (this.dontOpen) {
      this.dontOpen = false;
      return;
    } else {
      this.record = newVal;
      this.isModalActive = true;
    }
  }

  @Watch('viewResolved')
  viewResolvedChanged(newVal: string) {
    if (!newVal) return;

    this.filterData();
  }

  close(isSuccess: boolean) {
    this.record = null;
    this.isModalActive = false;

    if (isSuccess) this.getData();
  }

  filterData() {
    this.filteredData = [...this.data.filter((post) => this.viewResolved === 'resolved' ? post.resolved : !post.resolved)];
  }

  getData() {
    this.$store.dispatch('getAdminReportedPosts')
      .then((posts: Array<ReportedPost>) => {
        this.data = posts;
        this.filterData();
      })
      .catch(() => failure(this, {message: 'Error retrieving the posts. Please try again later.'}))
      .finally(() => this.$emit('done-loading', true));
  }

  openPost(postId: string) {
    this.dontOpen = true;
    if (!postId) return;

    this.$router.push(`/shout/${postId}`);
  }

  openProfile(username: string) {
    this.dontOpen = true;
    if (!username) return;

    this.$router.push(`/profile/${username}`);
  }
}
