






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
import Group from '@/components/search/Group.vue';
import Hashtag from '@/components/search/Hashtag.vue';
import Mention from '@/components/search/Mention.vue';
// eslint-disable-next-line no-unused-vars
import ModelTrending from '@/models/trending/Trending';
import TrendingSkeleton from '@/components/skeletons/TrendingSkeleton.vue';

@Component({
  components: {
    Group,
    Hashtag,
    Mention,
    TrendingSkeleton,
  },
})
export default class Trending extends Vue {
  @Prop({default: ''}) reload!: string;
  
  isLoading = false;
  trending: ModelTrending|null = null;

  get groups() {
    return this.trending ? this.trending.groups : [];
  }

  get hashtags() {
    return this.trending ? this.trending.hashtags : [];
  }

  get label() {
    return 'Trending';
  }

  get mentions() {
    return this.trending ? this.trending.mentions : [];
  }

  @Watch('reload')
  reloadChanged(newVal: string) {
    if (newVal && newVal === this.label) this.getData();
  }

  getData() {
    this.isLoading = true;

    this.$store.dispatch('getTrending')
      .then((trending: ModelTrending) => this.trending = trending)
      .catch(() => failure(this, {message: 'Error retrieving trending data.'}))
      .finally(() => this.isLoading = false);
  }
}
