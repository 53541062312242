






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Hashtag extends Vue {
  @Prop({required: true}) tag!: string;

  openHashtag() {
    if (this.$route.fullPath === `/hashtag/${this.tag}`) return;
    this.$router.push(`/hashtag/${this.tag}`);
  }
}
