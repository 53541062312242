import { render, staticRenderFns } from "./ShoutImage.vue?vue&type=template&id=a8a5ab38&scoped=true&"
import script from "./ShoutImage.vue?vue&type=script&lang=ts&"
export * from "./ShoutImage.vue?vue&type=script&lang=ts&"
import style0 from "./ShoutImage.vue?vue&type=style&index=0&id=a8a5ab38&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8a5ab38",
  null
  
)

export default component.exports