










































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
import Category from '@/enums/calls/category';
import Echo from '@/components/shout/Echo.vue';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
import NoResults from '@/components/search/NoResults.vue';
// eslint-disable-next-line no-unused-vars
import OfficialPost from '@/models/shout/OfficialPost';
import OfficialShout from '@/components/shout/OfficialShout.vue';
// eslint-disable-next-line no-unused-vars
import Post from '@/models/shout/Post';
import Shout from '@/components/shout/Shout.vue';

@Component({
  components: {
    Echo,
    MediaSkeleton,
    NoResults,
    OfficialShout,
    Shout,
  },
})
export default class CategoryTab extends Vue {
  @Prop({default: false}) getOfficial!: boolean;
  @Prop({required: true}) icon: any;
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({default: false}) isMine!: boolean;
  @Prop({required: true}) label: any;
  @Prop({required: false}) noResultsTitle!: string;
  @Prop({default: ''}) reload!: string;
  @Prop({required: true}) storeCall: any;
  @Prop({required: false}) username!: string;

  isLoading = false;
  fullPosts: Array<FullPost> = [];
  officialPost: OfficialPost|null = null;

  get editedPost() {
    return this.$store.getters['getEditedPost'];
  }

  get hiddenPost() {
    return this.$store.getters['getHiddenPost'];
  }

  get recentPost() {
    return this.isMine && this.label === 'Recent' ? this.$store.getters['getRecentPost'] : null;
  }

  get removeUser() {
    return this.$store.getters['getRemoveUser'];
  }

  @Watch('editedPost')
  editedPostChanged(newVal: FullPost) {
    if (newVal == null) return;
    this.updateExistingPost(newVal);
  }

  @Watch('hiddenPost')
  hiddenPostChanged(newVal: Post) {
    if (!newVal) return;
    this.hidePost(newVal);
  }

  @Watch('isLoggedIn')
  isLoggedInChanged(newVal: boolean) {
    if (newVal) this.getData();
  }

  @Watch('recentPost')
  recentPostChanged(newVal: FullPost) {
    if (newVal == null) return;
    this.fullPosts.splice(0, 0, newVal);
  }

  @Watch('reload')
  reloadChanged(newVal: string) {
    if (newVal && newVal === this.label) this.getData();
  }

  @Watch('removeUser')
  removeUserChanged(newVal: string) {
    if (!newVal) return;
    const lower = newVal.toLowerCase();

    // Remove the "user" from any records here...
    if (this.fullPosts.some((full) => full.post.username.toLowerCase() === lower)) {
      this.fullPosts = [...this.fullPosts.filter((full) => full.post.username.toLowerCase() !== lower)];
    }
  }

  created() {
    if (this.label === 'Recent' || this.label === 'Shouts') this.getData();
  }

  getData() {
    this.isLoading = true;

    const path = this.$route.fullPath.split('/');
    const cat = (path.length > 1 ? path[1] : Category.ALL).toLowerCase();
    const username = this.username && (cat === Category.PROFILE || Category.GROUP) ? this.username : '';

    if (this.getOfficial) {
      this.$store.dispatch('getOfficialPost')
        .then((post: OfficialPost) => this.officialPost = post);
    }

    this.$store.dispatch(this.storeCall, username)
      .then((posts: Array<FullPost>) => this.fullPosts = posts)
      .catch(() => failure(this, {message: 'Error retrieving Posts.'}))
      .finally(() => this.isLoading = false);
  }

  hidePost(post: Post) {
    if (!post) return;

    const index = this.fullPosts.findIndex((p) => p.post.id === post.id);
    if (index > -1) {
      const hiddenPost: Post = {...post, isHidden: true};
      const fullPost = {...this.fullPosts[index], isHidden: true, post: hiddenPost};
      const fullPosts = [...this.fullPosts];
      fullPosts.splice(index, 1, fullPost);

      this.fullPosts = [...fullPosts];
    }
  }

  updateExistingPost(fullPost: FullPost) {
    if (!fullPost || !fullPost.post) return;

    // Check to see if we have this post anywhere in this tab...
    const index = this.fullPosts.findIndex((post: FullPost) => fullPost.post.id === post.post.id);
    if (index > -1) {
      // If it is deleted, just remove it... otherwise, update it.
      if (fullPost.post.deleteReason) this.fullPosts.splice(index, 1);
      else this.fullPosts.splice(index, 1, fullPost);
    }
  }
}
