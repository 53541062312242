import Chain from '@/models/shout/Chain';
import FullPost from '@/models/shout/FullPost';
import ModelPost from '@/models/shout/Post';
import MyEchoNotifications from '@/models/shout/MyEchoNotifications';
import OfficialPost from '@/models/shout/OfficialPost';
import PostFamily from '@/models/shout/PostFamily';

const Post = {
  getters: {
    getAddListItems: (state: any) => state.addListItems,
    getEditedPost: (state: any) => state.editedPost,
    getHiddenPost: (state: any) => state.hiddenPost,
    getRecentPost: (state: any) => state.recentPost,
    isAddListModalActive: (state: any) => state.isAddListModalActive,
    isDeleteModalActive: (state: any) => state.isDeleteModalActive,
    isEchoModalActive: (state: any) => state.isEchoModalActive,
    isEditModalActive: (state: any) => state.isEditModalActive,
    isReportPostModalActive: (state: any) => state.isReportPostModalActive,
    lastUpdated: (state: any) => state.lastUpdated,
  },
  actions: {
    addEditedPost(context: any, post: FullPost) {
      context.commit('addEditedPost', post);
    },
    addHiddenPost(context: any, post: ModelPost) {
      context.commit('addHiddenPost', post);
    },
    addListItems(context: any, list: Array<string>) {
      context.commit('addListItems', list);
    },
    addRecentPost(context: any, post: FullPost) {
      context.commit('addRecentPost', post);
    },
    closeAddListModal(context: any) {
      context.commit('setIsAddListModalActive', {isOpen: false, category: null});
    },
    closeDeleteModal(context: any) {
      context.commit('setIsDeleteModalActive', {isOpen: false, post: null});
    },
    closeEchoModal(context: any) {
      context.commit('setIsEchoModalActive', {isOpen: false, post: null});
    },
    closeEditModal(context: any) {
      context.commit('setIsEditModalActive', {isOpen: false, post: null});
    },
    closeReportPostModal(context: any) {
      context.commit('setIsReportPostModalActive', {isOpen: false, postId: null});
    },
    createNewEcho(context: any, { echo, parentUsername }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverCreateNewEcho', {echo, parentUsername})
            .then((post: FullPost) => resolve(post));
        } catch {
          reject(null);
        }
      });
    },
    createNewShout(context: any, shout: ModelPost) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverCreateNewShout', shout)
            .then((post: FullPost) => resolve(post));
        } catch {
          reject(null);
        }
      });
    },
    deletePost(context: any, { id, reason }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverDeletePost', {id, reason})
            .then((post: FullPost) => resolve(post));
        } catch {
          reject(false);
        }
      });
    },
    editPost(context: any, { id, message, reason }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverEditPost', {id, message, reason})
            .then((post: FullPost) => resolve(post));
        } catch {
          reject(false);
        }
      });
    },
    getChain(context: any, postId: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetChain', postId)
            .then((chain: Chain) => resolve(chain));
        } catch {
          reject(null);
        }
      });
    },
    getMyEchoNotifications(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMyEchoNotifications')
            .then((notifications: Array<MyEchoNotifications>) => resolve(notifications));
        } catch {
          reject([]);
        }
      });
    },
    getMyPopularPosts(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMyPopularPosts')
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch(err) {
          reject(err);
        }
      });
    },
    getMyRecentPosts(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMyRecentPosts')
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch(err) {
          reject(err);
        }
      });
    },
    getMyShouts(context: any, { sort, sortType }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMyPosts', {sort, sortType})
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch {
          reject([]);
        }
      });
    },
    getOfficialPost(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetOfficialPost')
            .then((post: OfficialPost) => resolve(post));
        } catch {
          reject(null);
        }
      });
    },
    getPopularPosts(context: any, username: string = '') {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetPopularPosts', username)
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch {
          reject([]);
        }
      });
    },
    getPost(context: any, postId: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetPost', postId)
            .then((post: ModelPost) => resolve(post));
        } catch {
          reject(null);
        }
      });
    },
    getPostAndFamily(context: any, postId: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetPostAndFamily', postId)
            .then((postFamily: PostFamily) => resolve(postFamily));
        } catch {
          reject(null);
        }
      });
    },
    getRecentEchoes(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetRecentEchoes', username)
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch {
          reject([]);
        }
      });
    },
    getRecentPosts(context: any, username: string = '') {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetRecentPosts', username)
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch {
          reject([]);
        }
      });
    },
    getRecentShouts(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetRecentShouts', username)
            .then((posts: Array<FullPost>) => resolve(posts));
        } catch {
          reject([]);
        }
      });
    },
    hideOrShowPost(context: any, postId: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverHideOrShowPost', postId)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    openAddListModal(context: any, category: string) {
      context.dispatch('setGlobalLoading', true);
      context.commit('setIsAddListModalActive', {isOpen: true, category});
    },
    openDeleteModal(context: any, postId: string) {
      context.dispatch('setGlobalLoading', true);
      context.dispatch('serverGetFullPost', postId)
        .then((post: FullPost) => {
          if (post) context.commit('setIsDeleteModalActive', {isOpen: true, post});
        });
    },
    openEchoModal(context: any, postId: string) {
      context.dispatch('setGlobalLoading', true);
      context.dispatch('serverGetFullPost', postId)
        .then((post: FullPost) => {
          if (post) context.commit('setIsEchoModalActive', {isOpen: true, post});
        });
    },
    openEditModal(context: any, postId: string) {
      context.dispatch('setGlobalLoading', true);
      context.dispatch('serverGetFullPost', postId)
        .then((post: FullPost) => {
          if (post) context.commit('setIsEditModalActive', {isOpen: true, post});
        });
    },
    openReportPostModal(context: any, id: string) {
      context.dispatch('setGlobalLoading', true);
      context.commit('setIsReportPostModalActive', {isOpen: true, postId: id});
    },
    removeEchoNotification(context: any, postId: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverRemoveEchoNotification', postId)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    reportPost(context: any, { id, reportReason }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverReportPost', {id, reportReason})
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    updateListen(context: any, id: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateListening', id)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    }
  },
  mutations: {
    addEditedPost(state: any, post: FullPost) {
      state.editedPost = post;
    },
    addHiddenPost(state: any, post: ModelPost) {
      state.hiddenPost = post;
    },
    addListItems(state: any, list: Array<string>) {
      state.addListItems = [...list];
    },
    addRecentPost(state: any, post: FullPost) {
      state.recentPost = post;
    },
    setIsAddListModalActive(state: any, { isOpen, category }: any) {
      state.isAddListModalActive = {isOpen, category};
    },
    setIsDeleteModalActive(state: any, { isOpen, post }: any) {
      state.isDeleteModalActive = {isOpen, post};
    },
    setIsEchoModalActive(state: any, { isOpen, post }: any) {
      state.isEchoModalActive = {isOpen, post};
    },
    setIsEditModalActive(state: any, { isOpen, post }: any) {
      state.isEditModalActive = {isOpen, post};
    },
    setIsReportPostModalActive(state: any, { isOpen, postId }: any) {
      state.isReportPostModalActive = {isOpen, postId};
    },
  },
  state: {
    addListItems: [],
    editedPost: null,
    hiddenPost: null,
    isAddListModalActive: {isOpen: false, category: null},
    isDeleteModalActive: {isOpen: false, post: null},
    isEchoModalActive: {isOpen: false, post: null},
    isEditModalActive: {isOpen: false, post: null},
    isReportPostModalActive: {isOpen: false, postId: null},
    lastUpdated: null,
    recentPost: null,
  },
};

export default Post;
