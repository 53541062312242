import FullPost from '@/models/shout/FullPost';

export default class Group {
  public banner: File|string = '';
  public categories: string = '';
  public createdBy: string = '';
  public description: string = '';
  public groupId: string|null = null;
  public id: string = '';
  public isOfficial: boolean = false;
  public isOwnerOnly: boolean = false;
  public isVerified: boolean = false;
  public listeners: number = 0;
  public locations: string = '';
  public name: string = '';
  public preferredName: string = '';
  public postIds: Array<FullPost> = [];
  public posts: number = 0;
  public requestedList: Array<string> = [];

  constructor({banner, categories, createdBy, description, groupId, id, isOfficial, isOwnerOnly, isVerified, listeners, locations, preferredName, postIds, posts, requestedList}: any) {
    this.banner = banner ? banner : '';
    this.categories = categories ? categories : '';
    this.createdBy = createdBy ? createdBy : '';
    this.description = description ? description : '';
    this.groupId = groupId ? groupId : null;
    this.id = id ? id : '';
    this.isOfficial = isOfficial ? isOfficial : false,
    this.isOwnerOnly = isOwnerOnly ? isOwnerOnly : false;
    this.isVerified = isVerified ? isVerified : false;
    this.listeners = listeners ? listeners : 0;
    this.locations = locations ? locations : '';
    this.name = preferredName ? preferredName.toLowerCase() : '';
    this.preferredName = preferredName ? preferredName : '';
    this.postIds = postIds ? postIds : [];
    this.posts= posts ? posts : 0;
    this.requestedList = requestedList ? requestedList : [];
  }
}
