/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://g72cdo2mr6.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ipmgrvfhcba4rpts3u6ujzjmty.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-oeusmobmivetvm5t3cnrmfzb6i",
    "aws_cognito_identity_pool_id": "us-east-2:5c27997b-c172-4eb1-b00d-e7e0a255c43d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_xVUYgFZDD",
    "aws_user_pools_web_client_id": "27d7g6dgcmr2qssn92i5kifnv7",
    "oauth": {},
    "aws_user_files_s3_bucket": "shout-storage-bucket145733-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
