import FullPost from '@/models/shout/FullPost';
import Group from '@/models/group/Group';
import Profile from '@/models/user/Profile';

export default class Search {
  public category: string = '';
  public groups: Array<Group> = [];
  public hashtags: Array<string> = [];
  public include: boolean = true;
  public locations: Array<string> = [];
  public posts: Array<FullPost> = [];
  public profiles: Array<Profile> = [];
  public searchOption: string = 'All';
  public searchString: string = '';

  constructor({category, include, locations, searchOption, searchString}: any) {
    this.category = category ? category : '';
    this.include = include;
    this.locations = locations ? locations : [];
    this.searchOption = searchOption ? searchOption : 'All';
    this.searchString = searchString ? searchString : '';
  }
}
