
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class OpenUser extends Vue {
  /**
   * Make sure that the functions in here are kept up with the associated other mixins!!!
   */

  get user() {
    const user = this.$store.getters['getUser'];
    if (user == null) this.$store.dispatch('setUser');
    return user;
  }

  openLink(link: string) {
    if (!link) return;
    if (this.$route.fullPath === link) return;
    this.$router.push(link);
  }
}
