
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class User extends Vue {
  get user() {
    const user = this.$store.getters['getUser'];
    if (user == null) this.$store.dispatch('setUser');
    return user;
  }
}
