import IconLocation from '@/enums/profile/iconLocation';
import ViewOption from '@/enums/profile/viewOption';

export default class Profile {
  public addShadow: boolean = false;
  public banner: string = '';
  public blurb: string = '';
  public cannotRemove: boolean = false;
  public firstName: string = '';
  public groupImageViewOption: string = ViewOption.NONE;
  public iconLocation: IconLocation = IconLocation.TOP_LEFT;
  public id: string = '';
  public imageViewOption: string = ViewOption.NONE;
  public isOfficial: boolean = false;
  public isVerified: boolean = false;
  public lastName: string = '';
  public listeners: number = 0;
  public listeningTo: number = 0;
  public profileIcon: string = '';
  public username: string = '';

  constructor({addShadow, banner, blurb, cannotRemove, firstName, groupImageViewOption, iconLocation, imageViewOption, isOfficial, isVerified, lastName, listeners, listeningTo, profileIcon, username}: any) {
    this.addShadow = addShadow != null ? addShadow : false;
    this.banner = banner ? banner : '';
    this.blurb = blurb ? blurb : '';
    this.cannotRemove = cannotRemove ? cannotRemove : false;
    this.firstName = firstName ? firstName : '';
    this.groupImageViewOption = groupImageViewOption ? groupImageViewOption : ViewOption.NONE;
    this.iconLocation = iconLocation ? iconLocation : IconLocation.TOP_LEFT;
    this.id = username ? username.toLowerCase() : '';
    this.imageViewOption = imageViewOption ? imageViewOption : ViewOption.NONE;
    this.isOfficial = isOfficial ? isOfficial : false;
    this.isVerified = isVerified ? isVerified : false;
    this.lastName = lastName ? lastName : '';
    this.listeners = listeners ? listeners : 0;
    this.listeningTo = listeningTo ? listeningTo : 0;
    this.profileIcon = profileIcon ? profileIcon : '';
    this.username = username ? username : '';
  }
}
