








































import { Component } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
// eslint-disable-next-line no-unused-vars
import PostFamily from '@/models/shout/PostFamily';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
import ShoutPost from '@/components/shout/Shout.vue';
import User from '@/mixins/User.vue';

@Component({
  components: {
    MediaSkeleton,
    ShoutPost,
  }
})
export default class Shout extends User {
  children: Array<FullPost> = [];
  fullPost: FullPost|null = null;
  parent: FullPost|null = null;
  postFamily: PostFamily|null = null;
  id = '';
  isLoading = false;

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get isLoggedIn() {
    return this.user != null;
  }

  created() {
    const params = this.$route.params;
    this.id = params.id;

    this.getData();
  }

  getData() {
    if (!this.id) return;

    this.isLoading = true;

    this.$store.dispatch('getPostAndFamily', this.id)
      .then((family: PostFamily) => {
        if (family && family.fullPost) {
          this.postFamily = family;
          this.fullPost = family.fullPost;
          this.children = family.children;
          this.parent = family.parent;

          this.updateNotification();
        } else {
          failure(this, {message: 'Error opening post. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error opening post. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  updateNotification() {
    if (!this.isLoggedIn) return;

    if (this.fullPost && this.fullPost.post && this.fullPost.post.username === this.user.preferredUsername) {
      this.$store.dispatch('removeEchoNotification', this.id);
    }
  }
}
