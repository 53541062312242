










import Component from 'vue-class-component';

import CategoryTabs from '@/components/ui/CategoryTabs.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    CategoryTabs,
  },
})
export default class Home extends UserMixin {
  get size() {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'] ? 'is-small' : 'is-normal';
  }
}
