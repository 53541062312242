/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBlockUser = /* GraphQL */ `
  query GetBlockUser($id: ID!) {
    getBlockUser(id: $id) {
      blackList
      id
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBlockUsers = /* GraphQL */ `
  query ListBlockUsers(
    $filter: ModelBlockUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        blackList
        id
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const blockUserByUsername = /* GraphQL */ `
  query BlockUserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelBlockUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockUserByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        blackList
        id
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      message
      notes
      resolved
      topic
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        notes
        resolved
        topic
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getEchoNotifications = /* GraphQL */ `
  query GetEchoNotifications($id: ID!) {
    getEchoNotifications(id: $id) {
      id
      newNotifications
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEchoNotificationss = /* GraphQL */ `
  query ListEchoNotificationss(
    $filter: ModelEchoNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEchoNotificationss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        newNotifications
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const echoesByUsername = /* GraphQL */ `
  query EchoesByUsername(
    $username: String
    $newNotifications: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEchoNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    echoesByUsername(
      username: $username
      newNotifications: $newNotifications
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        newNotifications
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getErrorCatch = /* GraphQL */ `
  query GetErrorCatch($id: ID!) {
    getErrorCatch(id: $id) {
      createdAt
      error
      function
      id
      notes
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const listErrorCatchs = /* GraphQL */ `
  query ListErrorCatchs(
    $filter: ModelErrorCatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listErrorCatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        error
        function
        id
        notes
        resolved
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      banner
      categories
      createdBy
      description
      id
      isOfficial
      isOwnerOnly
      isVerified
      listeners
      locations
      name
      preferredName
      posts
      requestedList
      createdAt
      updatedAt
      owner
      postIds {
        items {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        banner
        categories
        createdBy
        description
        id
        isOfficial
        isOwnerOnly
        isVerified
        listeners
        locations
        name
        preferredName
        posts
        requestedList
        createdAt
        updatedAt
        owner
        postIds {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGroupSubscriptions = /* GraphQL */ `
  query GetGroupSubscriptions($id: ID!) {
    getGroupSubscriptions(id: $id) {
      cannotRemove
      groupId
      id
      lastCheckedPostNumber
      status
      username
      createdAt
      updatedAt
      group {
        items {
          banner
          categories
          createdBy
          description
          id
          isOfficial
          isOwnerOnly
          isVerified
          listeners
          locations
          name
          preferredName
          posts
          requestedList
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listGroupSubscriptionss = /* GraphQL */ `
  query ListGroupSubscriptionss(
    $filter: ModelGroupSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupSubscriptionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cannotRemove
        groupId
        id
        lastCheckedPostNumber
        status
        username
        createdAt
        updatedAt
        group {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const groupSubscriptionsByUsername = /* GraphQL */ `
  query GroupSubscriptionsByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupSubscriptionsByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cannotRemove
        groupId
        id
        lastCheckedPostNumber
        status
        username
        createdAt
        updatedAt
        group {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getHashtag = /* GraphQL */ `
  query GetHashtag($id: ID!) {
    getHashtag(id: $id) {
      id
      createdAt
      updatedAt
      owner
      hashtagInfo {
        items {
          createdAt
          hashtagId
          id
          postId
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listHashtags = /* GraphQL */ `
  query ListHashtags(
    $filter: ModelHashtagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHashtags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        owner
        hashtagInfo {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getHashtagNotifications = /* GraphQL */ `
  query GetHashtagNotifications($hashtag: String!, $username: String!) {
    getHashtagNotifications(hashtag: $hashtag, username: $username) {
      hashtag
      id
      newNotifications
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listHashtagNotificationss = /* GraphQL */ `
  query ListHashtagNotificationss(
    $hashtag: String
    $username: ModelStringKeyConditionInput
    $filter: ModelHashtagNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHashtagNotificationss(
      hashtag: $hashtag
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        hashtag
        id
        newNotifications
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const hashtagsByUsername = /* GraphQL */ `
  query HashtagsByUsername(
    $username: String
    $hashtag: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHashtagNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hashtagsByUsername(
      username: $username
      hashtag: $hashtag
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hashtag
        id
        newNotifications
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getHashtagPosts = /* GraphQL */ `
  query GetHashtagPosts($id: ID!) {
    getHashtagPosts(id: $id) {
      createdAt
      hashtagId
      id
      postId
      updatedAt
      owner
    }
  }
`;
export const listHashtagPostss = /* GraphQL */ `
  query ListHashtagPostss(
    $filter: ModelHashtagPostsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHashtagPostss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        hashtagId
        id
        postId
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const hashtagPostsByHashtag = /* GraphQL */ `
  query HashtagPostsByHashtag(
    $hashtagId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHashtagPostsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hashtagPostsByHashtag(
      hashtagId: $hashtagId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        hashtagId
        id
        postId
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getListen = /* GraphQL */ `
  query GetListen($id: ID!) {
    getListen(id: $id) {
      id
      listeners
      listenersNum
      listeningTo
      listeningToNum
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listListens = /* GraphQL */ `
  query ListListens(
    $filter: ModelListenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listeners
        listenersNum
        listeningTo
        listeningToNum
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMentionNotifications = /* GraphQL */ `
  query GetMentionNotifications($id: ID!) {
    getMentionNotifications(id: $id) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMentionNotificationss = /* GraphQL */ `
  query ListMentionNotificationss(
    $filter: ModelMentionNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentionNotificationss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        postNumber
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const mentionNotificationsByUsername = /* GraphQL */ `
  query MentionNotificationsByUsername(
    $username: String
    $postNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMentionNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mentionNotificationsByUsername(
      username: $username
      postNumber: $postNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        postNumber
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMentions = /* GraphQL */ `
  query GetMentions($id: ID!) {
    getMentions(id: $id) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMentionss = /* GraphQL */ `
  query ListMentionss(
    $filter: ModelMentionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postId
        postNumber
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const mentionsByUsername = /* GraphQL */ `
  query MentionsByUsername(
    $username: String
    $postNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMentionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mentionsByUsername(
      username: $username
      postNumber: $postNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        postNumber
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOfficialPost = /* GraphQL */ `
  query GetOfficialPost($id: ID!) {
    getOfficialPost(id: $id) {
      createdAt
      id
      isOfficial
      postId
      updatedAt
      owner
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
    }
  }
`;
export const listOfficialPosts = /* GraphQL */ `
  query ListOfficialPosts(
    $filter: ModelOfficialPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOfficialPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        isOfficial
        postId
        updatedAt
        owner
        post {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const officialPostsByDate = /* GraphQL */ `
  query OfficialPostsByDate(
    $isOfficial: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfficialPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    officialPostsByDate(
      isOfficial: $isOfficial
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        isOfficial
        postId
        updatedAt
        owner
        post {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      createdAt
      editReason
      exists
      deleteReason
      generationZeroId
      groupId
      id
      isHidden
      isOfficial
      isVerified
      image
      message
      originalPost
      parentId
      profileIcon
      reportReason
      type
      username
      updatedAt
      owner
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $id: ID
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPosts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const postsByGenerationZeroId = /* GraphQL */ `
  query PostsByGenerationZeroId(
    $generationZeroId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByGenerationZeroId(
      generationZeroId: $generationZeroId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const postsByDate = /* GraphQL */ `
  query PostsByDate(
    $exists: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByDate(
      exists: $exists
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byGroup = /* GraphQL */ `
  query ByGroup(
    $groupId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGroup(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const postsByParentId = /* GraphQL */ `
  query PostsByParentId(
    $parentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByParentId(
      parentId: $parentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const postsByUsername = /* GraphQL */ `
  query PostsByUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPostNumbers = /* GraphQL */ `
  query GetPostNumbers($id: ID!) {
    getPostNumbers(id: $id) {
      echoes
      exists
      groupId
      id
      listens
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPostNumberss = /* GraphQL */ `
  query ListPostNumberss(
    $id: ID
    $filter: ModelPostNumbersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPostNumberss(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        echoes
        exists
        groupId
        id
        listens
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byAllBoth = /* GraphQL */ `
  query ByAllBoth(
    $exists: String
    $echoesListens: ModelPostNumbersByAllBothCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAllBoth(
      exists: $exists
      echoesListens: $echoesListens
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        echoes
        exists
        groupId
        id
        listens
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byAllEchoes = /* GraphQL */ `
  query ByAllEchoes(
    $exists: String
    $echoes: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAllEchoes(
      exists: $exists
      echoes: $echoes
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        echoes
        exists
        groupId
        id
        listens
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byAllListens = /* GraphQL */ `
  query ByAllListens(
    $exists: String
    $listens: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAllListens(
      exists: $exists
      listens: $listens
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        echoes
        exists
        groupId
        id
        listens
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byGroupBoth = /* GraphQL */ `
  query ByGroupBoth(
    $groupId: String
    $echoesListens: ModelPostNumbersByGroupBothCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGroupBoth(
      groupId: $groupId
      echoesListens: $echoesListens
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        echoes
        exists
        groupId
        id
        listens
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byUsernameBoth = /* GraphQL */ `
  query ByUsernameBoth(
    $username: String
    $echoesListens: ModelPostNumbersByUsernameBothCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUsernameBoth(
      username: $username
      echoesListens: $echoesListens
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        echoes
        exists
        groupId
        id
        listens
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byUsernameEchoes = /* GraphQL */ `
  query ByUsernameEchoes(
    $username: String
    $echoes: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUsernameEchoes(
      username: $username
      echoes: $echoes
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        echoes
        exists
        groupId
        id
        listens
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const byUsernameListens = /* GraphQL */ `
  query ByUsernameListens(
    $username: String
    $listens: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostNumbersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUsernameListens(
      username: $username
      listens: $listens
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        echoes
        exists
        groupId
        id
        listens
        postId
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPostStatus = /* GraphQL */ `
  query GetPostStatus($id: ID!) {
    getPostStatus(id: $id) {
      addedListen
      id
      isHidden
      postId
      reportReason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPostStatuss = /* GraphQL */ `
  query ListPostStatuss(
    $filter: ModelPostStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        addedListen
        id
        isHidden
        postId
        reportReason
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      addShadow
      banner
      blurb
      cannotRemove
      firstName
      groupImageViewOption
      iconLocation
      id
      imageViewOption
      isOfficial
      isVerified
      lastName
      listeners
      listeningTo
      profileIcon
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        addShadow
        banner
        blurb
        cannotRemove
        firstName
        groupImageViewOption
        iconLocation
        id
        imageViewOption
        isOfficial
        isVerified
        lastName
        listeners
        listeningTo
        profileIcon
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const searchProfiles = /* GraphQL */ `
  query SearchProfiles(
    $filter: SearchableProfileFilterInput
    $sort: SearchableProfileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        addShadow
        banner
        blurb
        cannotRemove
        firstName
        groupImageViewOption
        iconLocation
        id
        imageViewOption
        isOfficial
        isVerified
        lastName
        listeners
        listeningTo
        profileIcon
        username
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;
export const getReportGroup = /* GraphQL */ `
  query GetReportGroup($id: ID!) {
    getReportGroup(id: $id) {
      actionTaken
      createdAt
      groupId
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      group {
        banner
        categories
        createdBy
        description
        id
        isOfficial
        isOwnerOnly
        isVerified
        listeners
        locations
        name
        preferredName
        posts
        requestedList
        createdAt
        updatedAt
        owner
        postIds {
          nextToken
        }
      }
      owner
    }
  }
`;
export const listReportGroups = /* GraphQL */ `
  query ListReportGroups(
    $filter: ModelReportGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        actionTaken
        createdAt
        groupId
        id
        notes
        reportedBy
        reportReason
        resolved
        username
        updatedAt
        group {
          banner
          categories
          createdBy
          description
          id
          isOfficial
          isOwnerOnly
          isVerified
          listeners
          locations
          name
          preferredName
          posts
          requestedList
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const reportGroupByUsername = /* GraphQL */ `
  query ReportGroupByUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportGroupByUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        actionTaken
        createdAt
        groupId
        id
        notes
        reportedBy
        reportReason
        resolved
        username
        updatedAt
        group {
          banner
          categories
          createdBy
          description
          id
          isOfficial
          isOwnerOnly
          isVerified
          listeners
          locations
          name
          preferredName
          posts
          requestedList
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getReportPost = /* GraphQL */ `
  query GetReportPost($id: ID!) {
    getReportPost(id: $id) {
      actionTaken
      createdAt
      id
      notes
      postId
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listReportPosts = /* GraphQL */ `
  query ListReportPosts(
    $filter: ModelReportPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        actionTaken
        createdAt
        id
        notes
        postId
        reportedBy
        reportReason
        resolved
        username
        updatedAt
        post {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const reportPostByUsername = /* GraphQL */ `
  query ReportPostByUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportPostByUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        actionTaken
        createdAt
        id
        notes
        postId
        reportedBy
        reportReason
        resolved
        username
        updatedAt
        post {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getReportProfile = /* GraphQL */ `
  query GetReportProfile($id: ID!) {
    getReportProfile(id: $id) {
      actionTaken
      createdAt
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const listReportProfiles = /* GraphQL */ `
  query ListReportProfiles(
    $filter: ModelReportProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        actionTaken
        createdAt
        id
        notes
        reportedBy
        reportReason
        resolved
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const reportProfileByUsername = /* GraphQL */ `
  query ReportProfileByUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportProfileByUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        actionTaken
        createdAt
        id
        notes
        reportedBy
        reportReason
        resolved
        username
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getReportUser = /* GraphQL */ `
  query GetReportUser($id: ID!) {
    getReportUser(id: $id) {
      banned
      count
      id
      notes
      username
      createdAt
      updatedAt
      reportGroup {
        items {
          actionTaken
          createdAt
          groupId
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportPost {
        items {
          actionTaken
          createdAt
          id
          notes
          postId
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportProfile {
        items {
          actionTaken
          createdAt
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listReportUsers = /* GraphQL */ `
  query ListReportUsers(
    $filter: ModelReportUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        banned
        count
        id
        notes
        username
        createdAt
        updatedAt
        reportGroup {
          nextToken
        }
        reportPost {
          nextToken
        }
        reportProfile {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const reportUserByCount = /* GraphQL */ `
  query ReportUserByCount(
    $count: Int
    $sortDirection: ModelSortDirection
    $filter: ModelReportUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportUserByCount(
      count: $count
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        banned
        count
        id
        notes
        username
        createdAt
        updatedAt
        reportGroup {
          nextToken
        }
        reportPost {
          nextToken
        }
        reportProfile {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTrending = /* GraphQL */ `
  query GetTrending($id: ID!) {
    getTrending(id: $id) {
      createdAt
      description
      icon
      id
      type
      value
      updatedAt
      owner
    }
  }
`;
export const listTrendings = /* GraphQL */ `
  query ListTrendings(
    $filter: ModelTrendingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrendings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        description
        icon
        id
        type
        value
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const trendingByDate = /* GraphQL */ `
  query TrendingByDate(
    $type: TrendingType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTrendingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    trendingByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        description
        icon
        id
        type
        value
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
