




















import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import ListenerType from '@/enums/calls/listener';
import OpenLink from '@/mixins/OpenLink.vue';

@Component
export default class NoResults extends OpenLink {
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({default: false}) isMine!: boolean;
  @Prop({required: true}) title!: string;

  openListenTo() {
    const path = `/listeners/${ListenerType.LISTENERS}`;

    if (this.$route.fullPath === path) return;
    this.$router.push(path);
  }
}
