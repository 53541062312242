


















import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
import GroupForm from '@/components/group/GroupForm.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    GroupForm,
  },
})
export default class AddNewGroup extends UserMixin {
  isLoading = false;
  isModalActive = false;

  get isNewGroupModalActive() {
		return this.$store.getters['isNewGroupModalActive'];
	}

  @Watch('isNewGroupModalActive')
	modalActiveChanged(newVal: boolean) {
		this.isModalActive = newVal;
	}

  close() {
    this.$store.dispatch('closeNewGroupModal');
  }

  uploadImage(file: File, groupId: string) {
    if (!file || !groupId) {
      this.isLoading = false;
      this.close();
      this.$router.go(0);
      return;
    }

    this.isLoading = true;

    this.$store.dispatch('uploadGroupBanner', {file, groupId,})
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          this.close();
          this.$router.go(0);
        } else {
          failure(this, {message: 'Error uploading the image. Please try again later.'});
        }
      })
      .finally(() => this.isLoading = false);
  }

  validForm(group: Group) {
    if (!group) return;

    this.isLoading = true;

    const file:File = group.banner as File;

    this.$store.dispatch('addNewGroup', group)
      .then((group: Group) => {
        if (group) {
          success(this, {message: 'Successfully created the Group!'});
          return group.id;
        } else {
          failure(this, {message: 'Error creating the Group. Please try again later.'});
          return '';
        }
      })
      .then((groupId: string) => {
        if (groupId) {
          this.uploadImage(file, groupId);
        } else {
          this.close();
        }
      })
      .catch(() => {
        failure(this, {message: 'Error creating the Group. Please try again later.'});
        this.isLoading = false;
      });
  }
}
