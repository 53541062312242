
























import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import { MyHashtags as ModelMyHashtags, Notification } from '@/models/user/MyHashtags';
import AbcHashtag from '@/components/hashtag/AbcHashtag.vue';
import AbcMenu from '@/components/menu/AbcMenu.vue';
import OpenUser from '@/mixins/OpenUser.vue';
import TrendingSkeleton from '@/components/skeletons/TrendingSkeleton.vue';

@Component({
  components: {
    AbcHashtag,
    AbcMenu,
    TrendingSkeleton,
  },
})
export default class MyHashtags extends OpenUser {
  isLoading = false;
  lettersSelected: Array<string> = [];
  myHashtags: Array<string> = [];
  myNewhashtags: Array<Notification> = [];

  created() {
    this.getData();
  }

  abcChosen(letters: Array<string>) {
    this.lettersSelected = letters;
  }

  getData() {
    this.isLoading = true;

    this.$store.dispatch('getHashtags')
      .then((myHashtags: ModelMyHashtags) => {
        if (myHashtags) {
          this.myHashtags = myHashtags.hashtags;
          this.myNewhashtags = myHashtags.notifications;
        } else {
          failure(this, {message: 'Error retrieving my hashtags.'})
        }
      })
      .catch(() => failure(this, {message: 'Error retrieving my hashtags.'}))
      .finally(() => this.isLoading = false);
  }
}
