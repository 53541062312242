import FullPost from '@/models/shout/FullPost';

export default class HashtagResults {
  fullPosts: Array<FullPost> = [];
  hashtag = '';
  isSubscribed = false;

  constructor({fullPosts, hashtag, isSubscribed}: any) {
    this.fullPosts = fullPosts ? fullPosts : [];
    this.hashtag = hashtag ? hashtag : '';
    this.isSubscribed = isSubscribed != null ? isSubscribed : false;
  }
}
