import { render, staticRenderFns } from "./AddNewGroup.vue?vue&type=template&id=ffd0c666&scoped=true&"
import script from "./AddNewGroup.vue?vue&type=script&lang=ts&"
export * from "./AddNewGroup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffd0c666",
  null
  
)

export default component.exports