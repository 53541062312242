import Hashtag from "@/models/trending/Hashtag";
import HashtagResults from "@/models/search/HashtagResults";
import Mention from "@/models/trending/Mention";
import Mentions from "@/models/user/Mentions";
import ModelSearch from '@/models/search/Search';
import Post from "@/models/shout/Post";
import Trending from "@/models/trending/Trending";

const Search = {
  getters: {
    search: (state: any) => state.search,
  },
  actions: {
    getHashtag(context: any, hashtag: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetHashtag', hashtag)
            .then((results: HashtagResults) => resolve(results));
        } catch {
          reject(null);
        }
      });
    },
    getMentions(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMentions')
            .then((mentions: Mentions) => resolve(mentions));
        } catch {
          reject([]);
        }
      });
    },
    getTrending(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetTrending')
            .then((trending: Trending) => resolve(trending));
        } catch {
          reject(null);
        }
      });
    },
    removeMentionNotifications(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverRemoveMentionNotifications')
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    search(context: any, search: ModelSearch) {
      return new Promise((resolve, reject) => {
        if (!search || !search.searchString) {
          resolve(null);
        } else {
          context.dispatch('serverSearch', search)
            .then((foundSearch: ModelSearch) => {
              context.commit('search', foundSearch);
              resolve(foundSearch);
            })
            .catch((err: any) => reject(err));
        }
      });
    },
    updateHashtagSub(context: any, hashtag: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateHashtagSub', hashtag)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
  },
  mutations: {
    search(state: any, search: Array<any>) {
      state.search = search;
    },
  },
  state: {
    search: [],
  },
};

export default Search;
