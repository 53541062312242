












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ShoutHeader extends Vue {
  @Prop({default: false}) isOfficial!: boolean;
  @Prop({required: true}) posted!: string;
  @Prop({required: true}) username!: string;

  showUser() {
    this.$router.push(`/profile/${this.username.toLowerCase()}`);
  }
}
