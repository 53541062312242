import { v4 as uuidv4 } from 'uuid';
import ReportedTypes from '@/enums/shout/reportedTypes';
import Type from "@/enums/shout/type";

export default class Post {
  public editReason: string = '';
  public exists: string = 'true'; // Always set this to true. DynamoDB sorting.
  public deleteReason: string = '';
  public generationZeroId: string = '';
  public groupId: string = ' '; // Need this to be at least a space for indexing purposes.
  public id: string = '';
  public isHidden: boolean = false;
  public isOfficial: boolean = false;
  public isVerified: boolean = false;
  public image: string|File = '';
	public message: string = '';
  public originalPost: string = '';
  public parentId: string = '';
	public profileIcon: string = '';
  public reportReason!: ReportedTypes;
  public type: Type = Type.Created;
	public username: string = '';

  constructor({editReason, deleteReason, generationZeroId, groupId, id, image, isOfficial, isVerified, message, originalPost, parentId, profileIcon, reportReason, type, username}: any) {
    const UUID = uuidv4();

    this.editReason = editReason ? editReason : '';
    this.deleteReason = deleteReason ? deleteReason : '';
    this.generationZeroId = generationZeroId ? generationZeroId : UUID;
    this.groupId = groupId ? groupId : ' ';
    this.id = id ? id : UUID;
    this.image = image ? image : '';
    this.isOfficial = isOfficial ? isOfficial : false;
    this.isVerified = isVerified ? isVerified : false;
		this.message = message ? message : '';
    this.originalPost = originalPost ? originalPost : '';
    this.parentId = parentId ? parentId : '';
		this.profileIcon = profileIcon ? profileIcon : '';
    this.reportReason = reportReason ? reportReason : '';
    this.type = type ? type : Type.Created;
		this.username = username ? username : '';
  }
}
