
























import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
import NewSearchSection from '@/components/group/NewSearchSection.vue';
import OpenUser from '@/mixins/OpenUser.vue';
import SmallGroupContainer from '@/components/group/SmallGroupContainer.vue';
import TrendingSkeleton from '@/components/skeletons/TrendingSkeleton.vue';

@Component({
  components: {
    NewSearchSection,
    SmallGroupContainer,
    TrendingSkeleton,
  },
})
export default class Groups extends OpenUser {
  groups: Array<Group> = [];
  isLoading = false;

  get isLoggedIn() {
    return this.user != null;
  }

  get isMobile() {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'];
  }

  get username() {
    return this.user ? this.user.username : '';
  }

  @Watch('user')
  userChanged(newVal) {
    if (newVal) this.getData();
  }

  created() {
    this.getData();
  }

  getData() {
    this.isLoading = true;

    this.$store.dispatch('getMyGroups')
      .then((groups: Array<Group>) => {
        const groupOrder = [
          ...groups.filter((group) => group.isOfficial).sort((a, b) => a.preferredName > b.preferredName ? 1 : -1),
          ...groups.filter((group) => !group.isOfficial).sort((a, b) => a.preferredName > b.preferredName ? 1 : -1),
        ];
        this.groups = groupOrder;
      })
      .catch(() => failure(this, {message: 'Error retrieving my groups.'}))
      .finally(() => this.isLoading = false);
  }
}
