






























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import { FILE_SIZE_LIMIT } from '@/config/main';
// eslint-disable-next-line no-unused-vars
import Profile from '@/models/user/Profile';

@Component
export default class UploadProfileImage extends Vue {
  @Prop({required: true}) title!: string;
  @Prop({required: true}) type!: string; //banner OR icon, nothing else will work!

  file: File | null = null;
  fileErrorMsg = '';
  isLoading = false;

  @Watch('file')
  fileChanged(newVal: File) {
    if (!newVal) return;
    this.checkForImage();
  }
 
  mounted() {
    if (!this.type || (this.type !== 'banner' && this.type !== 'icon')) this.close();
  }

  checkForImage() {
    this.fileErrorMsg = '';
    if (!this.file) return false;

    try {
      const type = this.file.type.split('/')[0];
      const size = this.file.size;

      if (type !== 'image') {
        this.fileErrorMsg = 'File must be an image.';
        return false;
      } else if (size > FILE_SIZE_LIMIT) {
        this.fileErrorMsg = `File must be smaller than ${FILE_SIZE_LIMIT / 1_000_000}MB.`;
        return false;
      }
    } catch {
      this.fileErrorMsg = 'Error attaching file.';
      return false;
    }

    return true;
  }

  close(isSuccess = false) {
    this.$emit('close', isSuccess);
    this.file = null;
  }

  uploadImage() {
    if (!this.file || !this.checkForImage()) return;
    
    this.isLoading = true;

    const storeCall = this.type === 'banner' ? 'uploadBannerImage' : 'uploadIconImage';

    this.$store.dispatch(storeCall, this.file)
      .then((profile: Profile) => {
        if (profile) {
          success(this, {message: 'Successfully uploaded the image.'});
          this.$emit('uploaded-image', {
            type: this.type,
            image: this.type === 'banner' ? profile.banner : profile.profileIcon,
          });
          this.close(true);
        } else {
          failure(this, {message: 'Error uploading the image. Please try again later.'});
        }
      })
      .finally(() => this.isLoading = false);
  }
}
