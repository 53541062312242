const Notifications = {
  getters: {
    addedNewGroup: (state: any) => state.addedNewGroup,
    newGroupNotificationNames: (state: any) => state.newGroupNotificationNames,
  },
  actions: {
    addedNewGroup(context: any, { isAdded, groupId, name }: any) {
      context.commit('addedNewGroup', {isAdded, groupId, name});
    },
    setNewGroupNotificationNames(context: any, names: Array<string>) {
      context.commit('setNewGroupNotificationNames', names);
    },
  },
  mutations: {
    addedNewGroup(state: any, { isAdded, groupId, name }: any) {
      state.addedNewGroup = {
        isAdded,
        groupId,
        name,
      };
    },
    setNewGroupNotificationNames(state: any, names: Array<string>) {
      state.newGroupNotificationNames = [...names];
    },
  },
  state: {
    addedNewGroup: { isAdded: false, groupId: '', name: '' },
    newGroupNotificationNames: [],
  },
};

export default Notifications;
