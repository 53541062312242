



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
import NoResults from '@/components/search/NoResults.vue';
import SmallGroupContainer from '@/components/group/SmallGroupContainer.vue';

@Component({
  components: {
    MediaSkeleton,
    NoResults,
    SmallGroupContainer,
  },
})
export default class GroupTab extends Vue {
  @Prop({required: true}) icon!: string;
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({default: false}) isMobile!: boolean;
  @Prop({required: true}) label!: string;
  @Prop({required: false}) noResultsTitle!: string;
  @Prop({default: ''}) reload!: string;
  @Prop({default: ''}) username!: string;

  groups: Array<Group> = [];
  isLoading = false;

  @Watch('reload')
  reloadChanged(newVal: string) {
    if (newVal && newVal === this.label) this.getData();
  }

  getData() {
    if (!this.username) return;

    this.isLoading = true;

    this.$store.dispatch('getGroupsByUser', this.username)
      .then((groups: Array<Group>) => {
        const groupOrder = [
          ...groups.filter((group) => group.isOfficial).sort((a, b) => a.preferredName > b.preferredName ? 1 : -1),
          ...groups.filter((group) => !group.isOfficial).sort((a, b) => a.preferredName > b.preferredName ? 1 : -1),
        ];
        this.groups = groupOrder;
      })
      .catch(() => failure(this, {message: 'Error loading groups. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
