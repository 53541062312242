













import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { anchor, removeHtml } from '@/utility/main';

@Component
export default class ShoutMessage extends Vue {
	@Prop({required: true}) shoutMessage: any;

  fullMessage: string = '';
	message: string = '';
	shortNum: number = 150;
	showFullMessage: boolean = false;
	tooLong: boolean = false;

	get showEllipsis() {
		return this.tooLong && !this.showFullMessage;
	}

  get showFullSpacing() {
    return !this.tooLong || this.showFullMessage;
  }

	@Watch('shoutMessage')
	shoutMessageChanged(newVal: string) {
    this.setMessageData(newVal);
	}

	mounted() {
    this.setMessageData(this.shoutMessage);
	}
	
	expandRetractMessage(): void {
		this.showFullMessage = !this.showFullMessage;

		if (this.showFullMessage) {
			this.message = this.shoutMessage;
		} else {
			this.message = this.showSubStr();
		}

    this.message = anchor(removeHtml(this.message));
	}

  setMessageData(fullMsg: string) {
	this.fullMessage = fullMsg;

	this.tooLong = this.fullMessage.length > this.shortNum;

    if (!this.showFullMessage && this.tooLong) {
      this.message = this.showSubStr();
	} else {
      this.message = this.fullMessage;
	}


    this.message = anchor(removeHtml(this.message));
  }

	showSubStr() {
		return this.fullMessage.substr(0, this.shortNum);
	}
}
