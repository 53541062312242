import ViewOption from '@/enums/profile/viewOption';

export default class User {
  public groupImageViewOption: string = ViewOption.NONE;
  public groups: Array<string> = [];
  public imageViewOption: string = ViewOption.NONE;
  public isOfficial: boolean = false;
  public isVerified: boolean = false;
  public preferredUsername: string = '';
  public profileIcon: string = '';
  public username: string = '';

  constructor({groupImageViewOption, groups, imageViewOption, isOfficial, isVerified, preferredUsername, profileIcon, username}: any) {
    this.groupImageViewOption = groupImageViewOption ? groupImageViewOption : ViewOption.NONE;
    this.groups = groups ? groups : [];
    this.imageViewOption = imageViewOption ? imageViewOption : ViewOption.NONE;
    this.isOfficial = isOfficial ? isOfficial : false;
    this.isVerified = isVerified ? isVerified : false;
    this.preferredUsername = preferredUsername ? preferredUsername : username ? username : '';
    this.profileIcon = profileIcon ? profileIcon : '';
    this.username = username ? username : '';
  }
}
