// Server side errors will be caught and sent to the appropriate table.
const SERVER_SIDE_ERROR_CATCHING = true;

// Client side errors will be less generic to the user.
const CLIENT_SIDE_ERROR_CATCHING = true;

// File size limit.
const FILE_SIZE_LIMIT = 2_000_000; // 2MB

export {
  CLIENT_SIDE_ERROR_CATCHING,
  FILE_SIZE_LIMIT,
  SERVER_SIDE_ERROR_CATCHING,
};
