






























import { Component, Vue } from 'vue-property-decorator';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import Amplify from 'aws-amplify';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import awsconfig from '@/aws-exports';

import AddNewGroup from '@/components/group/AddNewGroup.vue';
import AddShoutList from '@/components/ui/AddShoutList.vue';
import CreateEcho from '@/components/ui/CreateEcho.vue';
import DeletePost from '@/components/ui/DeletePost.vue';
import EditGroup from '@/components/group/EditGroup.vue';
import EditPost from '@/components/ui/EditPost.vue';
import LoginModal from '@/components/login/LoginModal.vue';
import ReportPost from '@/components/ui/ReportPost.vue';
import ReportProfile from '@/components/ui/ReportProfile.vue';
import SignUp from '@/components/login/SignUp.vue';
import TopBar from '@/components/ui/TopBar.vue';
import TopScroll from '@/components/ui/TopScroll.vue';
import User from '@/mixins/User.vue';

Vue.use(Buefy, {
  defaultIconPack: 'fal',
  defaultContainerElement: '#content',
});

@Component({
  components: {
    AddNewGroup,
    AddShoutList,
    CreateEcho,
    DeletePost,
    EditGroup,
    EditPost,
    LoginModal,
    ReportPost,
    ReportProfile,
    SignUp,
    TopBar,
    TopScroll,
  },
})
export default class App extends User {
  get hasColumns() {
    return this.isLargeMode && !this.isLandingPage;
  }

  get isGlobalLoading() {
    return this.$store.getters['isGlobalLoading'];
  }

  get isLandingPage() {
    return this.$route.fullPath === '/';
  }

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get profileProgress() {
    return this.$store.getters['getProfileProgress'];
  }

  get progressText() {
    switch (this.profileProgress) {
      case 25: return 'Creating User Profile';
      case 50: return 'Adding Default Groups';
      case 75: return 'Tweaking Some Magic';
      case 100: 
        setTimeout(() => this.$store.dispatch('setProfileProgress', 0), 2000);
        return 'Wishing You a Good Time!';
      default: return '';
    }
  }

  get size() {
    return this.isLargeMode ? 'is-medium' : 'is-small';
  }

  created() {
    // Amplify backend setup
    Amplify.configure(awsconfig);

    this.$store.dispatch('setScreenWidth', window.innerWidth);

    window.addEventListener('resize', () => {
      this.$store.dispatch('setScreenWidth', window.innerWidth);
    });
  }

  mounted() {
    const ref: any = this.$refs['progress'];
    const height = window.innerHeight / 2;
    ref.style.top = `${height}px`;
  }
}
