



























import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';

import { FILE_SIZE_LIMIT } from '@/config/main';

@Component
export default class ImagePicker extends Vue {
  @Prop({default: true}) clear!: boolean;
  @Prop({default: ''}) label!: string;
  @Prop({default: ''}) prevImageName!: string;
  @Prop({default: 'Remove the image.'}) tooltipLabel!: string;

  @Emit('is-valid')
  isValid(): {file: File|null, isValid: boolean} {
    const isValid = this.checkForImage();

    return {
      file: isValid ? this.file : null,
      isValid,
    };
  }

  @Emit('remove-image')
  removeImage() {
    this.file = null;
    this.fileErrorMsg = '';
    return true;
  }

  file: File | null = null;
  fileErrorMsg = '';

  @Watch('clear')
  clearChanged(newVal: boolean) {
    if (newVal) {
      this.file = null;
      this.fileErrorMsg = '';
    }
  }

  @Watch('file')
  fileChanged(newVal: File) {
    if (!newVal) return;
    this.isValid();
  }

  checkForImage() {
    this.fileErrorMsg = '';
    if (!this.file && this.prevImageName) return true;

    try {
      if (this.file) {
        const type = this.file.type.split('/')[0];
        const size = this.file.size;

        if (type !== 'image') {
          this.fileErrorMsg = 'File must be an image.';
          return false;
        } else if (size > FILE_SIZE_LIMIT) {
          this.fileErrorMsg = `File must be smaller than ${FILE_SIZE_LIMIT / 1_000_000}MB.`;
          return false;
        }
      }
    } catch {
      this.fileErrorMsg = 'Error attaching file.';
      return false;
    }

    return true;
  }
}
