class Notification {
  hashtag = '';
  newPosts = 0;

  constructor({hashtag, newPosts}: any) {
    this.hashtag = hashtag ? hashtag : '';
    this.newPosts = newPosts != null ? newPosts : 0;
  }
}

class MyHashtags {
  hashtags: Array<string> = [];
  notifications: Array<Notification> = [];

  constructor({hashtags, notifications}: any) {
    this.hashtags = hashtags ? hashtags : [];
    this.notifications = notifications ? notifications : [];
  }
}

export { MyHashtags, Notification };
