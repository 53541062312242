/* tslint:disable */
/* eslint-disable */

export const getGroupForEdit = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      banner
      categories
      createdBy
      description
      id
      isOfficial
      isOwnerOnly
      isVerified
      listeners
      locations
      name
      preferredName
      posts
      requestedList
      createdAt
      updatedAt
      owner
      postIds {
        nextToken
      }
    }
  }
`;
export const groupSubscriptionsByUsername = /* GraphQL */ `
  query GroupSubscriptionsByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupSubscriptionsByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        id
        lastCheckedPostNumber
        status
        username
        createdAt
        updatedAt
        group {
          nextToken
          items {
            banner
            categories
            createdBy
            description
            id
            isOfficial
            isOwnerOnly
            isVerified
            listeners
            locations
            name
            preferredName
            owner
            posts
            requestedList
          }
        }
        owner
      }
      nextToken
    }
  }
`;
