import Profile from "@/models/user/Profile";

export default class Listening {
  public isListening: boolean = false;
  public profile: Profile;

  constructor({isListening, profile}: Listening) {
    this.isListening = isListening;
    this.profile = profile;
  }
}
