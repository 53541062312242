import FullPost from '@/models/shout/FullPost';

export default class PostFamily {
  children: Array<FullPost> = [];
  fullPost: FullPost| null = null;
  parent: FullPost|null = null;

  constructor({children, fullPost, parent}: any) {
    this.children = children ? children : [];
    this.fullPost = fullPost ? fullPost : null;
    this.parent = parent ? parent : null;
  }
}
