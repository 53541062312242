























































import { Component, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
import Contacts from '@/components/admin/Contacts.vue';
import Errors from '@/components/admin/Errors.vue';
import ReportedGroups from '@/components/admin/ReportedGroups.vue';
// eslint-disable-next-line no-unused-vars
import ReportedNumbers from '@/models/admin/ReportedNumbers';
import ReportedPosts from '@/components/admin/ReportedPosts.vue';
import ReportedProfiles from '@/components/admin/ReportedProfiles.vue';
import ReportedUsers from '@/components/admin/ReportedUsers.vue';
import User from '@/mixins/User.vue';

@Component({
  components: {
    Contacts,
    Errors,
    ReportedGroups,
    ReportedPosts,
    ReportedProfiles,
    ReportedUsers,
  },
})
export default class Admin extends User {
  isLoading = false;
  reloadName = '';
  reportedNumbers = new ReportedNumbers({groups: 0, posts: 0, profiles: 0, users: 0});

  get size() {
    return this.$store.getters['isLargeMode'] ? 'is-normal' : 'is-small';
  }

  @Watch('user')
  userChanged(newVal: User) {
    if (!newVal) return;
    this.reloadName = '';
    setTimeout(() => this.reloadName = 'Contacts', 1000);
  }

  created() {
    this.getData();
  }

  mounted() {
    this.reloadName = 'Contacts';
  }

  getData() {
    this.$store.dispatch('adminGetReportedNumbers')
      .then((reportedNums: ReportedNumbers) => {
        if (reportedNums) {
          this.reportedNumbers = reportedNums;
        } else {
          this.reportedNumbers = new ReportedNumbers({groups: 0, posts: 0, profiles: 0, users: 0});
          failure(this, {message: 'Error retrieving Reported Numbers.'});
        }
      })
      .catch(() => failure(this, {message: 'Error retrieving Reported Numbers.'}));
  }

  tabChanged(name: string) {
    this.isLoading = true;
    this.reloadName = name;
  }
}
