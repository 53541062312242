export default class BasicLogin {
  public email: string = '';
  public password: string = '';
  public preferredUsername: string = '';
  public username: string = '';

  constructor({email, password, preferredUsername, username}: any) {
    this.email = email ? email : '';
    this.password = password ? password : '';
    this.preferredUsername = preferredUsername ? preferredUsername : username ? username : '';
    this.username = username ? username : '';
  }
}
