


































import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
import Echo from '@/components/shout/Echo.vue';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
// eslint-disable-next-line no-unused-vars
import MyEchoNotifications from '@/models/shout/MyEchoNotifications';
import OpenUser from '@/mixins/OpenUser.vue';
import Shout from '@/components/shout/Shout.vue';

@Component({
  components: {
    Echo,
    MediaSkeleton,
    Shout,
  },
})
export default class MyEchoes extends OpenUser {
  notifications: Array<MyEchoNotifications> = [];
  isLoading = false;

  get isLoggedIn() {
    return this.user != null;
  }

  created() {
    this.getData();
  }

  getData() {
    this.isLoading = true;

    this.$store.dispatch('getMyEchoNotifications')
      .then((notifications: Array<MyEchoNotifications>) => this.notifications = notifications)
      .catch(() => failure(this, {message: 'Error retrieving my echoes. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
