const MainStore = {
  getters: {
    isDesktop: (state: any) => state.isDesktop,
    isFullHd: (state: any) => state.isFullHd,
    isGlobalLoading: (state: any) => state.isGlobalLoading,
    isLargeMode: (state: any) => !state.isMobile && !state.isTablet,
    isMobile: (state: any) => state.isMobile,
    isTablet: (state: any) => state.isTablet,
    isWidescreen: (state: any) => state.isWidescreen,
  },
  actions: {
    setAllWidthsFalse(context: any) {
      context.commit('setIsDesktop', false);
      context.commit('setIsFullHd', false);
      context.commit('setIsMobile', false);
      context.commit('setIsTablet', false);
      context.commit('setIsWidescreen', false);
    },
    setGlobalLoading(context: any, isLoading: boolean) {
      context.commit('setGlobalLoading', isLoading);
    },
    setScreenWidth(context: any, width: number) {
      // These numbers are grabbed from responsive numbers of Bulma
      context.dispatch('setAllWidthsFalse');
      if (!width) {
        return;
      } else if (width <= 768) {
        context.commit('setIsMobile', true);
      } else if (width <= 769) {
        context.commit('setIsTablet', true);
      } else if (width <= 1024) {
        context.commit('setIsDesktop', true);
      } else if (width <= 1216) {
        context.commit('setIsWidescreen', true);
      } else { // 1408
        context.commit('setIsFullHd', true);
      }
    }
  },
  mutations: {
    setGlobalLoading(state: any, isLoading: boolean) {
      state.isGlobalLoading = isLoading;
    },
    setIsDesktop(state: any, isDesktop: boolean) {
      state.isDesktop = isDesktop;
    },
    setIsFullHd(state: any, isFullHd: boolean) {
      state.isFullHd = isFullHd;
    },
    setIsMobile(state: any, isMobile: boolean) {
      state.isMobile = isMobile;
    },
    setIsTablet(state: any, isTablet: boolean) {
      state.isTablet = isTablet;
    },
    setIsWidescreen(state: any, isWidescreen: boolean) {
      state.isWidescreen = isWidescreen;
    },
  },
  state: {
    isDesktop: false,
    isFullHd: false,
    isGlobalLoading: false,
    isMobile: false,
    isTablet: false,
    isWidescreen: false,
  },
};

export default MainStore;
