







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import ListenerType from '@/enums/calls/listener';

@Component
export default class ListenerQuickNums extends Vue {
  @Prop({required: true}) listenersNum: any;
  @Prop({required: true}) listeningToNum: any;

  showListeners() {
    this.$router.push(`/listeners/${ListenerType.LISTENERS}`);
  }

  showListeningTo() {
    this.$router.push(`/listeners/${ListenerType.LISTENING_TO}`);
  }
}
