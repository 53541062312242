













import Vue from 'vue';
import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
import Search from '@/models/search/Search';

@Component
export default class LargeSearchBar extends Vue {
  public isDisabled = false;
  public searchText: string = '';

  public search() {
    if (!this.searchText) return;

    this.isDisabled = true;

    const newSearch = new Search({searchString: this.searchText});

    this.$store.dispatch('search', newSearch)
      .then(() => {
        if (this.$route.fullPath !== '/search') {
          this.$router.push('/search');
        }
      })
      .catch(() => failure(this, {message: 'Error with your search. Please try again later.'}))
      .finally(() => setTimeout(() => this.isDisabled = false, 2000));
  }
}
