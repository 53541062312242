import FullPost from '@/models/shout/FullPost';

export default class Mentions {
  mentions: Array<FullPost> = [];
  newMentions: Array<FullPost> = [];

  constructor({mentions, newMentions}: any) {
    this.mentions = mentions ? mentions : [];
    this.newMentions = newMentions ? newMentions : [];
  }
}
