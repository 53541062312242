



















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class FullImage extends Vue {
  @Prop({required: true}) isActive!: boolean;
  @Prop({required: true}) src!: string;

  isModalActive = false;

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
  }

  close() {
    this.$emit('close', true);
  }
}
