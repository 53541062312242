import anchorme from 'anchorme';

import { Sort } from "@/enums/calls/sortBy";
import Post from "@/models/shout/Post";

const anchor = (str: string) => {
  if (!str) return '';

  return anchorme({
    input: str,
    options: {
      attributes: {
        target: '_blank',
      },
      middleTruncation: true,
      truncate: 20,
    },
    extensions: [
      {
        test: /#(\w|_)+/gi,
        transform: (str) => `<a href="/hashtag/${str.substr(1)}">${str}</a>`,
      },
      {
        test: /@(\w|_)+/gi,
        transform: (str) => `<a href="/profile/${str.substr(1)}">${str}</a>`,
      },
      {
        test: /&(\w|_)+/gi,
        transform: (str) => `<a href="/group/${str.substr(1)}">${str}</a>`,
      },
    ],
  });
};

const getMonthString = (monthNum: number, full = false) => {
  switch (monthNum) {
    case 0: return full ? 'January' : 'Jan';
    case 1: return full ? 'February' : 'Feb';
    case 2: return full ? 'March' : 'Mar';
    case 3: return full ? 'April' : 'Apr';
    case 4: return full ? 'May' : 'May';
    case 5: return full ? 'June' : 'Jun';
    case 6: return full ? 'July' : 'Jul';
    case 7: return full ? 'August' : 'Aug';
    case 8: return full ? 'September' : 'Sep';
    case 9: return full ? 'October' : 'Oct';
    case 10: return full ? 'November' : 'Nov';
    case 11: return full ? 'December' : 'Dec';
    default: return 'N/A';
  }
};

const joinGroupLocations = (locations: Array<string>) => {
  return locations.join('\\s\\s\\s');
};

const parseGroupLocations = (locations: string) => {
  const locs = locations.split('\\s\\s\\s');
  return locs.length > 0 && locs[0] ? locs : [];
};

const removeHtml = (str: string): string => {
  const regex = /(<([^>]+)>)/gi;
  return str.replace(regex, '');
};

const setDateFormat = (ms: number) => {
  return ms && !isNaN(ms) ? new Date(Number(ms)) : new Date();
};

const setShortDateFormat = (ms: number) => {
  const addZero = (num: number) => num < 10 ? `0${num}` : num;

  const date = new Date(ms);
  const mon = getMonthString(date.getMonth());
  const h = addZero(date.getHours());
  const m = addZero(date.getMinutes());
  
  return date ? `${mon} ${date.getDate()}, ${date.getFullYear()} ${h}:${m}` : '';
};

const sortByField = (sortBy: Sort): string => {
  switch (sortBy) {
    case Sort.Echoes: return 'echoes';
    case Sort.Listens: return 'listens';
    case Sort.Recent: return 'posted';
    default: return 'posted';
  }
};

const sortPostsBy = (posts: Array<Post>, sortBy: Sort) => {
  const field = sortByField(sortBy);
  return posts.sort((a: any, b: any) => a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0);
};

export {
  anchor,
  getMonthString,
  joinGroupLocations,
  parseGroupLocations,
  removeHtml,
  setDateFormat,
  setShortDateFormat,
  sortPostsBy,
};
