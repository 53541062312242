



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import '@aws-amplify/ui-vue';

import IconLocation from '@/enums/profile/iconLocation';

@Component
export default class ImageContainer extends Vue {
  @Prop({default: true}) addShadow!: boolean;
  @Prop({required: false}) banner!: string;
  @Prop({required: false}) fullName!: string;
  @Prop({default: IconLocation.TOP_LEFT}) iconLocation!: IconLocation;
  @Prop({required: false}) profileIcon!: string;
  @Prop({required: true}) username!: string;
  
  iconToUse = '';
  shadow = true;

  get icon() {
    return this.profileIcon;
  }

  get IconLocation() {
    return IconLocation;
  }

  get isLargeScreen() {
    return !this.$store.getters['isMobile'] && !this.$store.getters['isTablet'];
  }

  get lowerUsername() {
    return this.username.toLowerCase();
  }

  @Watch('addShadow')
  addShadowChanged(newVal: boolean) {
    this.shadow = newVal;
  }

  @Watch('iconLocation')
  iconLocationChanged(newVal: IconLocation) {
    this.updateIconLocation(newVal);
  }

  created() {
    this.iconToUse = this.profileIcon;
    this.shadow = this.addShadow;
  }

  mounted() {
    this.updateIconLocation(this.iconLocation);
  }

  onError() {
    this.iconToUse = require('@/assets/anonymous_user.png');
  }

  updateIconLocation(loc: IconLocation) {
    const icon: any = this.$refs.icon;
    const width = (window.innerWidth / 2) - (this.isLargeScreen ? 128 : 32);

    const setVals = ({l, r, t, b}: any) => {
      icon.style.top = null;
      icon.style.bottom = null;
      icon.style.left = null;
      icon.style.right = null;
      if (l != null) icon.style.left = `${l}px`;
      if (r != null) icon.style.right = `${r}px`;
      if (t != null) icon.style.top = `${t}px`;
      if (b != null) icon.style.bottom = `${b}px`;
    };

    switch (loc) {
      case IconLocation.TOP_MIDDLE: setVals({l: width, t: 0}); break;
      case IconLocation.BOTTOM_MIDDLE: setVals({l: width, b: 0}); break;
      case IconLocation.TOP_LEFT: setVals({l: 0, t: 0}); break;
      case IconLocation.TOP_RIGHT: setVals({r: 0, t: 0}); break;
      case IconLocation.BOTTOM_LEFT: setVals({l: 0, b: 0}); break;
      case IconLocation.BOTTOM_RIGHT: setVals({r: 0, b: 0}); break;
      default: break;
    }
  }
}
