

























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
import TagPicker from '@/components/ui/TagPicker.vue';

@Component({
  components: {
    TagPicker,
  },
})
export default class EditPost extends Vue {
  editedMessage: string = '';
  editReason: string = this.reasons[0];
  fullPost: FullPost|null = null;
  isLoading = false;
  isMessageTouched = false;
  isModalActive = false;
  isTagModalActive = false;

  get isEditModalActive() {
		return this.$store.getters['isEditModalActive'];
	}

  get isMessageValid() {
		return (this.isMessageTouched && this.editedMessage && this.editedMessage.length > 0 && this.editedMessage.length <= 500)
			|| !this.isMessageTouched;
	}

  get reasons() {
    return ['Typo', 'Spelling', 'Grammer', 'Data Changed', 'Wrong Info', 'Mistake', 'Updated'];
  }

  get title() {
    return this.fullPost && this.fullPost.post ? `Edit ${this.fullPost.post.message.substr(0, 14)}...` : 'Edit?';
  }

  @Watch('isEditModalActive')
	modalActiveChanged(newVal: any) {
		this.isModalActive = newVal.isOpen;
		this.fullPost = newVal.post;
    this.editedMessage = this.fullPost && this.fullPost.post ? this.fullPost.post.message : '';
    this.$store.dispatch('setGlobalLoading', false);
	}

  close() {
    this.editReason = this.reasons[0];
    this.isMessageTouched = false;
		this.$store.dispatch('closeEditModal');
	}

  edit() {
    this.isMessageTouched = true;
    if (!this.isMessageValid || !this.fullPost || !this.fullPost.post) return;

    this.isLoading = true;

    this.$store.dispatch('editPost', {id: this.fullPost.post['id'], message: this.editedMessage, reason: this.editReason})
      .then((post: FullPost) => {
        if (post) {
          this.$store.dispatch('addEditedPost', post);
          success(this, {message: 'Post updated!'});
          this.close();
        } else {
          failure(this, {message: 'Error editing the post. Please try again later.'})
        }
      })
      .catch(() => failure(this, {message: 'Error editing the post. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  reset() {
    if (!this.fullPost || !this.fullPost.post) return;
    this.editedMessage = this.fullPost.post.message;
  }

  tagSelected(tag: string) {
    if (!tag) return;
    this.editedMessage = `${this.editedMessage.trim()} ${tag}`;
  }
}
