
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import ModelMention from '@/models/trending/Mention';

@Component
export default class Mention extends Vue {
  @Prop({required: true}) mention!: ModelMention;

  get anchor() {
    return `/profile/${this.name}`;
  }

  get profileIcon() {
    return this.mention.profileIcon;
  }

  get name() {
    return this.mention.mention;
  }

  openProfile() {
    if (this.$route.fullPath === this.anchor) return;
    this.$router.push(this.anchor);
  }
}
