



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Profile from '@/models/user/Profile';
import ReportedTypes from '@/enums/shout/reportedTypes';
import TermsOfUse from '@/components/login/TermsOfUse.vue';

@Component({
  components: {
    TermsOfUse,
  },
})
export default class ReportProfile extends Vue {
  isFieldTouched = false;
  isLoading = false;
  isModalActive = false;
  profile: Profile|null = null;
  reportReason: ReportedTypes|null = null;
  termsOpen = false;

  get isReportProfileModalActive() {
		return this.$store.getters['isReportProfileModalActive'];
	}

  get isReportValid() {
    return (this.isFieldTouched && this.reportReason != null) || !this.isFieldTouched;
  }

  get reasons() {
    return Object.values(ReportedTypes).map((val: string) => val);
  }

  get title() {
    return this.profile ? `Report ${this.profile.username}` : 'Report?';
  }

  @Watch('isReportProfileModalActive')
	modalActiveChanged(newVal: any) {
		this.isModalActive = newVal.isOpen;
		this.profile = newVal.profile;
	}

  close() {
    this.reportReason = null;
    this.isFieldTouched = false;
		this.$store.dispatch('closeReportProfileModal');
	}

  report() {
    this.isFieldTouched = true;
    if (!this.isReportValid || !this.profile) return;

    this.isLoading = true;

    this.$store.dispatch('reportProfile', {username: this.profile.username, reportReason: this.reportReason})
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: 'Reported the profile.'});
          this.close();
        } else {
          failure(this, {message: 'Error reporting the profile. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error reporting the profile. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
