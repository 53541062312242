















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import ErrorCatch from '@/models/admin/ErrorCatch';
import ErrorsEdit from '@/components/admin/ErrorsEdit.vue';

@Component({
  components: {
    ErrorsEdit,
  },
})
export default class Errors extends Vue {
  @Prop({required: true}) reload!: boolean;

  data: Array<ErrorCatch> = [];
  defaultSortDirection = 'desc';
  dontOpen = false;
  filteredData: Array<ErrorCatch> = [];
  isModalActive = false;
  record: ErrorCatch|null = null;
  selected: any = null;
  viewResolved = 'unresolved';

  get size() {
    return this.$store.getters['isLargeMode'] ? 'is-normal' : 'is-small';
  }

  @Watch('reload')
  reloadChanged(newVal: boolean) {
    if (newVal) this.getData();
  }

  @Watch('selected')
  selectedChanged(newVal: ErrorCatch) {
    if (this.dontOpen) {
      this.dontOpen = false;
      return;
    } else {
      this.record = newVal;
      this.isModalActive = true;
    }
  }

  @Watch('viewResolved')
  viewResolvedChanged(newVal: string) {
    if (!newVal) return;

    this.filterData();
  }

  close(isSuccess: boolean) {
    this.record = null;
    this.isModalActive = false;

    if (isSuccess) this.getData();
  }

  filterData() {
    this.filteredData = [...this.data.filter((error) => this.viewResolved === 'resolved' ? error.resolved : !error.resolved)];
  }

  getData() {
    this.$store.dispatch('getAdminErrors')
      .then((errors: Array<ErrorCatch>) => {
        this.data = errors;
        this.filterData();
      })
      .catch(() => failure(this, {message: 'Error retrieving the errors. Please try again later.'}))
      .finally(() => this.$emit('done-loading', true));
  }

  open(username: string) {
    this.dontOpen = true;
    if (!username) return;

    this.$router.push(`/profile/${username}`);
  }
}
