
























































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
import Category from '@/enums/group/category';
// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
import Location from '@/components/group/Location.vue';
import Search from '@/models/search/Search';
import SmallGroupContainer from '@/components/group/SmallGroupContainer.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    Location,
    SmallGroupContainer,
  },
})
export default class SearchGroups extends UserMixin {
  category: string = '';
  groups: Array<Group> = [];
  includeRadio = 'include';
  isAdvancedOpen = false;
  isLoading = false;
  locations: Array<string> = [];
  searchText = '';

  get categories() {
    return Object.values(Category).map((val: string) => val);
  }

  get isLoggedIn() {
    return this.user != null;
  }

  get isMobile() {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'];
  }

  get size() {
    return this.isMobile ? 'is-small' : 'is-medium';
  }

  get username() {
    return this.user ? this.user.username : '';
  }

  @Watch('category')
  categoryChanged() {
    this.search();
  }

  @Watch('includeRadio')
  includeRadioChanged() {
    this.search();
  }

  @Watch('locations')
  locationsChanged() {
    this.search();
  }

  search() {
    this.isLoading = true;

    const search = new Search({
      category: this.category,
      include: this.includeRadio === 'include',
      locations: this.locations,
      searchString: this.searchText,
    });

    this.$store.dispatch('searchGroups', search)
      .then((results: Search) => {
        if (results) {
          this.groups = [...results.groups];
        } else {
          failure(this, {message: 'Error searching for Groups. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error searching for Groups. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  updateLocations(locs: Array<string>) {
    this.locations = locs;
  }
}
