
































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import ImagePicker from '@/components/image/ImagePicker.vue';
import Post from '@/models/shout/Post';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    ImagePicker,
  },
})
export default class BigShout extends UserMixin {
  file: File | null = null;
  isImageModalActive = false;
  isLoading = false;
  isMessageTouched = false;
  message = '';

  get addListItems() {
    return this.$store.getters['getAddListItems'];
  }

  get isMessageValid() {
    return (this.isMessageTouched && this.message && this.message.length > 0 && this.message.length <= 500) || !this.isMessageTouched;
  }
  
  @Watch('addListItems')
  addListItemsChanged(newVal: Array<string>) {
    if (!newVal || newVal.length === 0) return;

    this.addItemsToMessage(newVal);
  }

  addImage() {
    if (!this.file) return;

    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.hideShowImage(false, event.target.result);
    });
    reader.readAsDataURL(this.file);
    this.closeModal();
  }

  addItemsToMessage(list: Array<string>) {
    if (!list || list.length === 0) return;

    const addEnter = this.message.length > 0 ? '\n' : '';

    this.message += `${addEnter}${list.join(' ')}`;
  }

  checkForImage() {
    if (!this.file) return false;

    try {
      const type = this.file.type.split('/')[0];
      if (type !== 'image') return false;
    } catch {
      return false;
    }

    return true;
  }

  closeModal() {
    this.isImageModalActive = false;
  }

  createShout() {
    if (!this.user) return;

    this.isLoading = true;

    const newShout = new Post({
      image: this.file ? this.file : '',
      message: this.message,
    });

    this.$store.dispatch('createNewShout', newShout)
    .then(() => {
      success(this, {message: 'Successfully Shouted!'});
      this.reset();
    })
    .catch(() => failure(this, {message: 'Error creating the Shout. Please try again later.'}))
    .finally(() => this.isLoading = false);
  }

  hideShowImage(isHide: boolean, src: string = '') {
    const elementContainer: any = document.getElementById('addImageToBigPostContainer');
    const element: any = document.getElementById('addImageToBigPost');

    if (isHide) {
      element.src = '';
      elementContainer.style.display = 'none';
      this.file = null;
    } else {
      element.src = src;
      elementContainer.style.display = 'block';
    }
  }

  incomingFile(obj: any) {
    this.file = obj.file ?? null;
  }

  open(category: string) {
    this.$store.dispatch('openAddListModal', category);
  }

  removeImage() {
    this.hideShowImage(true);
  }

  reset() {
    this.isMessageTouched = false;
    this.message = '';
    this.hideShowImage(true);
  }
}
