





























































































































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import { parseGroupLocations } from '@/utility/main';
import CategoryTab from '@/components/search/CategoryTab.vue';
import GroupSkeleton from '@/components/skeletons/GroupSkeleton.vue';
import GroupStats from '@/components/group/GroupStats.vue';
// eslint-disable-next-line no-unused-vars
import ListeningGroup from '@/models/group/ListeningGroup';
// eslint-disable-next-line no-unused-vars
import ModelGroup from '@/models/group/Group';
import ReportedTypes from '@/enums/shout/reportedTypes';
import SubscriptionStatus from '@/enums/group/subscriptionStatus';
import TermsOfUse from '@/components/login/TermsOfUse.vue';
import UserGroup from '@/components/group/UserGroup.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    CategoryTab,
    GroupSkeleton,
    GroupStats,
    TermsOfUse,
    UserGroup,
  },
})
export default class Group extends UserMixin {
  group: ModelGroup|null = null;
  isFieldTouched = false;
  isLoading = false;
  isModalActive = false;
  listeningGroup: ListeningGroup|null = null;
  reloadName = 'Recent';
  reportReason: string|null = null;
  termsOpen = false;
  totalListeners = 0;

  get APPROVED() {
    return SubscriptionStatus.APPROVED;
  }

  get canChangeFollowing() {
    const status = this.listeningGroup && this.listeningGroup.status ? this.listeningGroup.status : '';
    return status !== SubscriptionStatus.DENIED;
  }

  get canClearNotification() {
    return this.listeningGroup && (this.listeningGroup.isFollowing || this.listeningGroup.isOwner);
  }

  get DENIED() {
    return SubscriptionStatus.DENIED;
  }

  get hasNewListeners() {
    return this.listeningGroup && this.listeningGroup.newListenerRequests;
  }

  get isLargeScreen() {
    return !this.$store.getters['isMobile'] && !this.$store.getters['isTablet'];
  }

  get isLoggedIn() {
    return this.user != null;
  }

  get isOwner() {
    return this.listeningGroup && this.listeningGroup.isOwner;
  }

  get isReportValid() {
    return (this.isFieldTouched && this.reportReason != null) || !this.isFieldTouched;
  }

  get label() {
    if (!this.listeningGroup) return 'Listen To...';
    else if (this.listeningGroup.status === this.REQUESTED) return 'Requested';
    else if (this.listeningGroup.status === this.DENIED) return 'Denied';
    else return this.listeningGroup.isFollowing || this.listeningGroup.status === this.APPROVED ? 'Listening' : 'Listen To...';
  }

  get locations() {
    return this.group ? parseGroupLocations(this.group.locations) : [];
  }

  get reasons() {
    return Object.values(ReportedTypes).map((val: string) => val);
  }

  get reportTitle() {
    return this.group ? `Report ${this.group.preferredName}` : 'Report';
  }

  get REQUESTED() {
    return SubscriptionStatus.REQUESTED;
  }

  get size() {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'] ? 'is-small' : 'is-normal';
  }

  get title() {
    return this.group ? `&${this.group.preferredName}` : 'Group';
  }

  get type() {
    return this.listeningGroup && (this.listeningGroup.isFollowing || this.listeningGroup.status === this.APPROVED) ? 'is-success' :
      this.listeningGroup && this.listeningGroup.status === this.DENIED ? 'is-danger' : 'is-primary';
  }

  @Watch('user')
  userChanged() {
    if (!this.user) return;
    
    const params = this.$route.params;
    const name = params ? params.name : '';

    this.getData(name);
  }

  created() {
    const params = this.$route.params;
    const name = params ? params.name : '';

    this.getData(name);
  }

  changeFollowing() {
    if (!this.isLoggedIn || !this.group || !this.canChangeFollowing) return;

    this.$store.dispatch('updateFollowingGroup', this.group.id)
      .then((isSuccess: boolean) => {
        if (isSuccess && this.listeningGroup) {
          if (this.listeningGroup.status === SubscriptionStatus.APPROVED) {
            this.listeningGroup.isFollowing = false;
            this.listeningGroup.status = '';
          } else if (this.listeningGroup.status === SubscriptionStatus.REQUESTED) {
            this.listeningGroup.status = '';
          } else {
            this.listeningGroup.status = SubscriptionStatus.REQUESTED;
          }
          this.totalListeners += this.listeningGroup.isFollowing ? 0 : -1;
        }
      })
      .catch(() => failure(this, {message: 'Error changing listening status. Please try again later.'}));
  }

  clearAnyNotification(name: string) {
    if (!this.isLoggedIn || !name || !this.listeningGroup || !this.canClearNotification) return;

    this.$store.dispatch('updateGroupSubscriptionNotification', name);
  }

  close() {
    this.reportReason = null;
    this.isFieldTouched = false;
    this.isModalActive = false;
  }

  deleteConfirmation() {
    if (!this.isOwner || !this.group || !this.group.name) return;

    const name = this.group.preferredName;

    this.$buefy.dialog.confirm({
      title: 'Deleting Group',
      message: `Are you sure you want to <b>delete ${name}</b>? This cannot be undone and will remove all Listeners!`,
      confirmText: 'Delete Group',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteGroup(name),
    });
  }

  deleteGroup(name: string) {
    if (!this.isOwner || !this.group) return;

    this.isLoading = true;
    
    this.$store.dispatch('deleteGroup', this.group.id)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: `Successfully deleted the Group ${name}.`});
          this.$router.push('/groups');
        } else {
          failure(this, {message: 'Error deleting the Group. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error deleting the Group. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  editGroup() {
    if (!this.group || !this.group.name) return;

    this.$store.dispatch('openEditGroupModal', this.group.id);
  }

  editUsers() {
    if (!this.user || !this.isOwner || !this.group || !this.group.name) return;

    this.$store.dispatch('openUserGroupModal', {name: this.group.name, groupId: this.group.id});
  }

  getData(name: string) {
    if (!name) return;

    this.isLoading = true;

    this.$store.dispatch('getGroup', name)
      .then((listGroup: ListeningGroup) => {
        if (listGroup) {
          this.listeningGroup = listGroup;
          this.group = listGroup.group;
          this.totalListeners = this.group ? this.group.listeners : 0;
          this.clearAnyNotification(name);
        } else {
          failure(this, {message: 'Error loading Group. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error loading Group. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  openProfile() {
    if (!this.group || !this.group.createdBy) return;
    this.$router.push(`/profile/${this.group?.createdBy}`);
  }

  report() {
    this.isFieldTouched = true;
    if (!this.isReportValid || !this.group) return;

    this.isLoading = true;

    this.$store.dispatch('reportGroup', {id: this.group.id, reportReason: this.reportReason, username: this.group.createdBy})
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: 'Reported the Group.'});
          this.close();
        } else {
          failure(this, {message: 'Error reporting the Group. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error reporting the Group. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  tabChanged(name: string) {
    this.reloadName = name;
  }
}
