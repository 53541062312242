enum Sort {
  Recent = 'Recent',
  Echoes = 'Echoes',
  Listens = 'Listens',
}

enum Type {
  All = 'All',
  Edited = 'Edited',
  Deleted = 'Deleted',
}

export { Sort, Type };
