export default class hashtagNotification {
  hashtag = '';
  id = '';
  newNotifications = 0;
  username = '';

  constructor({hashtag, id, newNotifications, username}: any) {
    this.hashtag = hashtag ? hashtag : '';
    this.id = id ? id : '';
    this.newNotifications = newNotifications != null ? newNotifications : 0;
    this.username = username ? username : '';
  }
}
