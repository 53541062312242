
































































import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
import Echo from '@/components/shout/Echo.vue';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
// eslint-disable-next-line no-unused-vars
import Mentions from '@/models/user/Mentions';
import OpenUser from '@/mixins/OpenUser.vue';
import Shout from '@/components/shout/Shout.vue';

@Component({
  components: {
    Echo,
    MediaSkeleton,
    Shout,
  },
})
export default class MyMentions extends OpenUser {
  isLoading = false;
  mentions: Array<FullPost> = [];
  newMentions: Array<FullPost> = [];

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get isLoggedIn() {
    return this.user != null ? true : false;
  }

  created() {
    this.getData();
  }

  getData() {
    if (!this.user) return;

    this.isLoading = true;

    this.$store.dispatch('getMentions')
      .then((mentions: Mentions) => {
        if (mentions) {
          this.mentions = mentions.mentions || [];
          this.newMentions = mentions.newMentions || [];
          this.$store.dispatch('removeMentionNotifications').catch(() => null); // Silently fail.
        } else {
          failure(this, {message: 'Error retrieving my mentions. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error retrieving my mentions. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
