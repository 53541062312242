import { v4 as uuidv4 } from 'uuid';

export default class PostNumbers {
  echoes = 0;
  exists = 'true';  // Keep this true! Used with DynamoDB.
  groupId = ' '; // Need to have this as a blank string if not using.
  id = '';
  listens = 0;
  postId = '';
  username = '';

  constructor({echoes, groupId, id, listens, postId, username}: any) {
    this.echoes = echoes ? echoes : 0;
    this.groupId = groupId ? groupId : ' ';
    this.id = id ? id : uuidv4();
    this.listens = listens ? listens : 0;
    this.postId = postId ? postId : '';
    this.username = username ? username : '';
  }
}
