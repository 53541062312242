














































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import Contact from '@/models/admin/Contact';
import { failure, success } from '@/utility/messages';

@Component
export default class ContactsEdit extends Vue {
  @Prop({required: true}) contact!: Contact;
  @Prop({required: true}) isActive!: boolean;

  createdAt = '';
  isLoading = false;
  isModalActive = false;
  message = '';
  notes = '';
  resolved = false;
  topic = '';
  username = '';

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
    if (newVal) this.resetData();
  }

  @Watch('contact')
  contactChanged(newVal: Contact) {
    if (newVal) this.resetData();
  }

  created() {
    this.isModalActive = this.isActive;
    this.resetData();
  }

  close(isSuccess: boolean = false) {
    this.$emit('close', isSuccess);
  }

  deleteContact() {
    const deleteContact = () => {
      this.isLoading = true;
      this.$store.dispatch('adminDeleteContact', this.contact.id)
        .then((isSuccess: boolean) => {
          if (isSuccess) {
            success(this, {message: 'Contact deleted!'});
            this.close(true);
          } else {
            failure(this, {message: 'Error deleting the Contact.'});
          }
        })
        .catch(() => failure(this, {message: 'Error deleting the Contact.'}))
        .finally(() => this.isLoading = false);
    };

    this.$buefy.dialog.confirm({
      title: 'Deleting Contact',
      message: 'Are you sure you want to <b>delete</b> this Contact? This cannot be undone.',
      confirmText: 'Delete Contact',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: deleteContact,
    });
  }

  resetData() {
    if (!this.contact) return;

    this.createdAt = this.contact['createdAt'];
    this.message = this.contact.message;
    this.notes = this.contact.notes;
    this.resolved = this.contact.resolved;
    this.topic = this.contact.topic;
    this.username = this.contact.username;
  }

  updateContact() {
    this.isLoading = true;

    const updatedContact = new Contact({
      id: this.contact.id,
      message: this.contact.message,
      notes: this.notes,
      resolved: this.resolved,
      topic: this.contact.topic,
      username: this.contact.username,
    });

    this.$store.dispatch('updateAdminContact', updatedContact)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: 'Contact updated!'});
          this.close(true);
        } else {
          failure(this, {message: 'Error updating the contact. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the contact. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
