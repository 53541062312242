










import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AbcMenu extends Vue {
  clicked(range: string) {
    this.$emit('chosen', range);
  }
}
