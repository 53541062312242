















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import ReportedGroup from '@/models/admin/ReportedGroup';
import ReportedGroupEdit from '@/components/admin/ReportedGroupEdit.vue';

@Component({
  components: {
    ReportedGroupEdit,
  },
})
export default class ReportedGroups extends Vue {
  @Prop({required: true}) reload!: boolean;

  data: Array<ReportedGroup> = [];
  defaultSortDirection = 'asc';
  dontOpen = false;
  filteredData: Array<ReportedGroup> = [];
  isModalActive = false;
  record: ReportedGroup|null = null;
  selected: any = null;
  viewResolved = 'unresolved';

  get size() {
    return this.$store.getters['isLargeMode'] ? 'is-normal' : 'is-small';
  }

  @Watch('reload')
  reloadChanged(newVal: boolean) {
    if (newVal) this.getData();
  }

  @Watch('selected')
  selectedChanged(newVal: ReportedGroup) {
    if (this.dontOpen) {
      this.dontOpen = false;
      return;
    } else {
      this.record = newVal;
      this.isModalActive = true;
    }
  }

  @Watch('viewResolved')
  viewResolvedChanged(newVal: string) {
    if (!newVal) return;

    this.filterData();
  }

  close(isSuccess: boolean) {
    this.record = null;
    this.isModalActive = false;

    if (isSuccess) this.getData();
  }

  filterData() {
    this.filteredData = [...this.data.filter((group) => this.viewResolved === 'resolved' ? group.resolved : !group.resolved)];
  }

  getData() {
    this.$store.dispatch('getAdminReportedGroups')
      .then((groups: Array<ReportedGroup>) => {
        this.data = groups;
        this.filterData();
      })
      .catch(() => failure(this, {message: 'Error retrieving the groups. Please try again later.'}))
      .finally(() => this.$emit('done-loading', true));
  }

  openGroup(group: string) {
    this.dontOpen = true;
    if (!group) return;

    this.$router.push(`/group/${group}`);
  }

  openProfile(username: string) {
    this.dontOpen = true;
    if (!username) return;

    this.$router.push(`/profile/${username}`);
  }
}
