








































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';

import { joinGroupLocations, parseGroupLocations } from '@/utility/main';
import Category from '@/enums/group/category';
import Group from '@/models/group/Group';
import ImagePicker from '@/components/image/ImagePicker.vue';
import Location from '@/components/group/Location.vue';
// eslint-disable-next-line no-unused-vars
import User from '@/models/user/User';

@Component({
  components: {
    ImagePicker,
    Location,
  },
})
export default class GroupForm extends Vue {
  @Prop({default: null}) group!: Group;
  @Prop({default: false}) isEdit!: boolean;
  @Prop({required: true}) user!: User;

  banner = '';
  category = '';
  clearBanner = false;
  description = '';
  file: File | null = null;
  groupName = '';
  isCategoryTouched = false;
  isDescriptionTouched = false;
  isFileValid = true;
  isGroupNameTouched = false;
  isLoading = false;
  isTaken = false;
  locations: string = '';
  ownerOnly = 'No';

  get BANNER_GROUP() {
    return 'Group Banner';
  }

  get buttonTitle() {
    return this.isEdit ? 'Update' : 'Create';
  }

  get categories() {
    return Object.values(Category).map((val: string) => val);
  }

  get currentLocations() {
    return parseGroupLocations(this.locations);
  }

  get isCategoryValid() {
    return (this.isCategoryTouched && this.category) || !this.isCategoryTouched;
  }

  get isDescriptionValid() {
    return (this.isDescriptionTouched && this.description.trim().length > 0 && this.description.length <= 200) || !this.isDescriptionTouched;
  }

  get isGroupNameTaken() {
    return this.isTaken;
  }

  get isGroupNameValid() {
    return (this.isGroupNameTouched && this.groupName && this.groupName.trim().length > 0 && this.groupName.length <= 30 && this.passedNameTest)
      || !this.isGroupNameTouched;
  }

  get isMobile() {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'];
  }

  get passedNameTest() {
    const regex = /[^0-9a-zA-Z]+/g;
    return this.groupName.replaceAll(regex, '').length === this.groupName.length;
  }

  get subtitle() {
    return this.isEdit ? 'Update your Group' : 'Create a Group that you monitor';
  }

  get title() {
    return this.isEdit ? this.group.preferredName : 'Create New Group';
  }

  get tooltipPos() {
    return this.isMobile ? 'is-left' : 'is-right';
  }

  @Watch('isEdit')
  isEditChanged(newVal) {
    if (!newVal || !this.group) return;
    this.setGroupData();
  }

  created() {
    if (this.isEdit && this.group) this.setGroupData();
  }

  checkGroupName() {
    this.isGroupNameTouched = true;
    this.isLoading = true;

    this.$store.dispatch('checkGroupName', this.groupName)
      .then((isTaken: boolean) => this.isTaken = isTaken)
      .catch(() => this.isTaken = true)
      .finally(() => this.isLoading = false);
  }

  close() {
    this.reset();
    this.$emit('close', true);
	}

  createGroup() {
    this.isCategoryTouched = true;
    this.isDescriptionTouched = true;
    this.isGroupNameTouched = true;

    if (!this.user || !this.isCategoryValid || !this.isGroupNameValid || this.isGroupNameTaken || !this.isDescriptionValid || !this.isFileValid) return;

    const id = this.isEdit ? this.group.id : uuidv4();
    const group = new Group({
      banner: this.file ? this.file : this.banner ? this.banner : '',
      categories: this.category,
      createdBy: this.user.preferredUsername,
      description: this.description,
      id,
      isOwnerOnly: this.ownerOnly === 'No' ? false : true,
      listeners: 0,
      locations: this.locations,
      preferredName: this.groupName,
      posts: 0,
    });

    this.reset();
    this.$emit('valid-form', group);
  }

  incomingFile(obj: any) {
    this.file = obj.file ?? null;
    this.isFileValid = obj.isValid;
  }

  removeBanner() {
    this.banner = '';
    this.file = null;
    this.isFileValid = true;
  }

  reset() {
    this.category = '';
    this.clearBanner = false;
    this.description = '';
    this.file = null;
    this.groupName = '';
    this.isCategoryTouched = false;
    this.isDescriptionTouched = false;
    this.isFileValid = true;
    this.isGroupNameTouched = false;
    this.isTaken = false;
    this.locations = '';
    this.ownerOnly = 'No';

    this.$nextTick(() => this.clearBanner = true);
  }

  setGroupData() {
    this.banner = this.group.banner ? this.BANNER_GROUP : '';
    this.category = this.group.categories;
    this.description = this.group.description ? this.group.description : '';
    this.groupName = this.group.preferredName ? this.group.preferredName : '';
    this.locations = this.group.locations ? this.group.locations : '';
    this.ownerOnly = this.group.isOwnerOnly ? 'Yes' : 'No';
  }

  updateLocations(locs: Array<string>) {
    this.locations = joinGroupLocations(locs);
  }
}
