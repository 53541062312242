export default class ReportedNumbers {
  groups = 0;
  posts = 0;
  profiles = 0;
  users = 0;

  constructor({groups, posts, profiles, users}: ReportedNumbers) {
    this.groups = groups ?? 0;
    this.posts = posts ?? 0;
    this.profiles = profiles ?? 0;
    this.users = users ?? 0;
  }
}
