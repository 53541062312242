


























import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
import ListenersSkeleton from '@/components/skeletons/ListenersSkeleton.vue';
import ListenerType from '@/enums/calls/listener';
// eslint-disable-next-line no-unused-vars
import Listening from '@/models/user/Listening';
import NoResults from '@/components/listening/NoResults.vue';
import SmallProfile from '@/components/profile/SmallProfile.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    ListenersSkeleton,
    NoResults,
    SmallProfile,
  },
})
export default class Listeners extends UserMixin {
  isLoading = false;
  listening: Array<Listening> = [];
  LISTENING_TO = 'Listening To';
  title = '';
  username = '';

  get isListeningTo() {
    return this.title === this.LISTENING_TO;
  }

  get noResultsTitle() {
    return this.title === this.LISTENING_TO ? 'Not Listening to Anyone!' : 'No Listeners!';
  }

  @Watch('user')
  userChanged(newVal: boolean) {
    if (newVal) this.getParams();
  }

  created() {
    this.getParams();
  }

  getData(type: string) {
    if (type === ListenerType.LISTENERS.toString()) {
      this.title = 'Your Listeners';
      this.getMyListeners();
    } else if (type === ListenerType.LISTENING_TO.toString()) {
      this.title = this.LISTENING_TO;
      this.getMyListeningTo();
    } else {
      this.title = 'USERNAME HERE';
    }
  }

  getMyListeners() {
    if (!this.user) return;

    this.isLoading = true;

    this.$store.dispatch('getMyListeners', this.user.username)
      .then((listenings: Array<Listening>) => this.listening = listenings)
      .catch(() => failure(this, {message: 'Error retrieving my listeners.'}))
      .finally(() => this.isLoading = false);
  }

  getMyListeningTo() {
    if (!this.user) return;

    this.isLoading = true;

    this.$store.dispatch('getMyListeningTo', this.user.username)
      .then((listenings: Array<Listening>) => this.listening = listenings)
      .catch(() => failure(this, {message: 'Error retrieving my listening to.'}))
      .finally(() => this.isLoading = false);
  }

  getParams() {
    const params = this.$route.params;
    const type = params && params.type ? params.type : '';
    this.username = params && params.username ? params.username : '';

    this.getData(type);
  }

  setIsListening(username: string) {
    if (!this.user || !username) return;

    this.$store.dispatch('updateListeningTo', username)
      .then((isSuccess: boolean) => {
        if (!isSuccess) {
          failure(this, {message: 'Error listening. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error listening. Please try again later.'}));
  }
}
