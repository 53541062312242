














































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import ErrorCatch from '@/models/admin/ErrorCatch';

@Component
export default class ErrorsEdit extends Vue {
  @Prop({required: true}) error!: ErrorCatch;
  @Prop({required: true}) isActive!: boolean;

  createdAt = '';
  errorMsg = '';
  func = '';
  isLoading = false;
  isModalActive = false;
  notes = '';
  resolved = false;
  username = '';

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
    if (newVal) this.resetData();
  }

  @Watch('error')
  contactChanged(newVal: ErrorCatch) {
    if (newVal) this.resetData();
  }

  created() {
    this.isModalActive = this.isActive;
    this.resetData();
  }

  close(isSuccess: boolean = false) {
    this.$emit('close', isSuccess);
  }

  deleteError() {
    const deleteError = () => {
      this.isLoading = true;
      this.$store.dispatch('adminDeleteError', this.error.id)
        .then((isSuccess: boolean) => {
          if (isSuccess) {
            success(this, {message: 'Error deleted!'});
            this.close(true);
          } else {
            failure(this, {message: 'Error deleting the Error.'});
          }
        })
        .catch(() => failure(this, {message: 'Error deleting the Error.'}))
        .finally(() => this.isLoading = false);
    };

    this.$buefy.dialog.confirm({
      title: 'Deleting Error',
      message: 'Are you sure you want to <b>delete</b> this Error? This cannot be undone.',
      confirmText: 'Delete Error',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: deleteError,
    });
  }

  resetData() {
    if (!this.error) return;

    this.createdAt = this.error.createdAt;
    this.errorMsg = this.error.error;
    this.func = this.error.function;
    this.notes = this.error.notes;
    this.resolved = this.error.resolved;
    this.username = this.error.username;
  }

  updateError() {
    this.isLoading = true;

    const updatedError = {
      id: this.error.id,
      error: this.error.error,
      function: this.error.function,
      notes: this.notes,
      resolved: this.resolved,
      username: this.error.username,
    };

    this.$store.dispatch('updateAdminError', updatedError)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: 'Error updated!'});
          this.close(true);
        } else {
          failure(this, {message: 'Error updating the error. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the error. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
