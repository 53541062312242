




















import { Component, Prop } from 'vue-property-decorator';

import FullImage from '@/components/image/FullImage.vue';
import User from '@/mixins/User.vue';
import ViewOption from '@/enums/profile/viewOption';

@Component({
  components: {
    FullImage,
  },
})
export default class ShoutImage extends User {
  @Prop({required: true}) src!: string;
  @Prop({default: ''}) username!: string;

  isModalActive = false;
  tempShowImage = false;

  get canView() {
    return (this.hasViewOption && this.tempShowImage) || this.isOwner || this.tempShowImage;
  }

  get hasViewOption() {
    return this.isOwner || (this.user && this.user.imageViewOption === ViewOption.ALL) || this.isViewingListeningToUser;
  }

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get isOwner() {
    return this.user && this.user.username.toLowerCase() === this.username.toLowerCase();
  }

  get isViewingListeningToUser() {
    if (this.user && this.user.imageViewOption === ViewOption.LISTENING_TO) {
      return this.listeningTo.some((username: string) => username === this.username);
    }
    return false;
  }

  get listeningTo() {
    return this.$store.getters['getListeningTo'];
  }

  get needsTooltip() {
    return !this.hasViewOption && this.user != null;
  }

  get style() {
    return this.isLargeMode ? 'width: 400px;' : `width: ${window.innerWidth/2}px`;
  }

  mounted() {
    this.tempShowImage = this.hasViewOption;
  }
}
