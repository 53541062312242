
























import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
import GroupForm from '@/components/group/GroupForm.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    GroupForm,
  },
})
export default class EditGroup extends UserMixin {
  group: Group|null = null;
  isEdit = false;
  isLoading = true;
  isModalActive = false;

  get isEditGroupModalActive() {
		return this.$store.getters['isEditGroupModalActive'];
	}

  @Watch('isEditGroupModalActive')
	modalActiveChanged(newVal: any) {
		this.isModalActive = newVal.isOpen;
    if (this.isModalActive) {
      this.getData(newVal.group);
    }
	}

  close() {
    this.isEdit = false;
    this.$store.dispatch('closeEditGroupModal');
  }

  getData(id: string) {
    if (!id) this.close();

    this.isLoading = true;

    this.$store.dispatch('getGroupForEdit', id)
      .then((group: Group) => {
        if (group) {
          this.group = group;
          this.isEdit = true;
        } else {
          failure(this, {message: 'Error loading Group. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error loading Group. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  uploadImage(file: File|null, groupId: string) {
    if (!file || !groupId) {
      this.isLoading = false;
      this.close();
      this.$router.go(0);
      return;
    }

    this.isLoading = true;

    this.$store.dispatch('uploadGroupBanner', {file, groupId,})
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          this.close();
          this.$router.go(0);
        } else {
          failure(this, {message: 'Error uploading the image. Please try again later.'});
        }
      })
      .finally(() => this.isLoading = false);
  }

  validForm(group: Group) {
    if (!group) return;

    this.isLoading = true;

    const file:File|null = group.banner && group.banner !== 'Group Banner' ? group.banner as File : null;

    this.$store.dispatch('updateGroup', group)
      .then((group: Group) => {
        if (group) {
          success(this, {message: 'Updated the Group.'});
          return group.id;
        } else {
          failure(this, {message: 'Error updating the Group. Please try again later1.'});
          return '';
        }
      })
      .then((groupId: string) => {
        if (groupId) {
          this.uploadImage(file, groupId);
        } else {
          this.close();
        }
      })
      .catch(() => {
        failure(this, {message: 'Error updating the Group. Please try again later.'});
        this.isLoading = false;
      });
  }
}
