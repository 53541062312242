import Vue from 'vue';
import Vuex from 'vuex';

import Admin from '@/stores/admin';
import App from './App.vue';
import Contact from '@/stores/contact';
import Groups from '@/stores/groups';
import Login from '@/stores/login';
import MainStore from '@/stores/main';
import Notifications from '@/stores/notifications';
import Post from '@/stores/post';
import router from '@/routes/main';
import Search from '@/stores/search';
import Server from '@/stores/server';
import User from '@/stores/user';

import { setShortDateFormat } from '@/utility/main';

Vue.config.productionTip = false;
Vue.use(Vuex);

const store: any = [Admin, Contact, Groups, Login, Notifications, Search, Post, Server, User, MainStore].reduce((stores: any, store: any) => {
  Object.keys(store).forEach((key) => stores[key] = {...stores[key], ...store[key]});
  return stores;
});

Vue.filter('toDate', (val: number) => {
  return val ? setShortDateFormat(val) : '';
});

new Vue({
  router,
  render: h => h(App),
  store: new Vuex.Store(store),
}).$mount('#app');
