import FullPost from '@/models/shout/FullPost';

export default class Chain {
  public echoes: Array<FullPost> = [];
  public originalShout: FullPost|null;

  constructor({echoes, originalShout}: Chain) {
    this.echoes = echoes ? echoes : [];
    this.originalShout = originalShout ? originalShout : null;
  }
}
