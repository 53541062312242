import Group from '@/models/group/Group';

export default class ListeningGroup {
  public group: Group|null = null;
  public groupId: string = '';
  public id: string = '';
  public isFollowing: boolean = false;
  public isOwner: boolean = false;
  public newListenerRequests: number = 0;
  public postsBehind: number = 0;
  public status: string = '';

  constructor({group, groupId, id, isFollowing, isOwner, newListenerRequests, postsBehind, status,}: any) {
    this.group = group ? group : null;
    this.groupId = groupId ? groupId : '';
    this.id = id ? id : '';
    this.isFollowing = isFollowing ? isFollowing : false;
    this.isOwner = isOwner ? isOwner : false;
    this.newListenerRequests = newListenerRequests != null ? newListenerRequests : 0;
    this.postsBehind = postsBehind != null ? postsBehind : 0;
    this.status = status ? status : '';
  }
}
