












import Vue from 'vue';
import Component from 'vue-class-component';

import SearchResults from '@/components/search/SearchResults.vue';

@Component({
  components: {
    SearchResults,
  },
})
export default class SearchTab extends Vue {
  isLoading = false;
  results: any;
  TITLE = 'No Current Search';

  get hasResults() {
    return this.results && ((this.results.hashtags && this.results.hashtags.length) || 
      (this.results.posts && this.results.posts.length) || (this.results.profiles && this.results.profiles.length));
  }

  get hasTriedASearch() {
    return this.title !== this.TITLE;
  }

  get title() {
    return this.results && this.results.searchString ? this.results.searchString : this.TITLE;
  }

  created() {
    this.getData();
  }

  getData() {
    this.isLoading = true;

    this.results = this.$store.getters['search'];

    this.isLoading = false;
  }
}
