











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class EditedHeader extends Vue {
  @Prop({required: true}) reason!: string;

  showOriginal = false;

  get title() {
    return this.showOriginal ? 'OG' : 'ED';
  }

  showOriginalPost() {
    this.showOriginal = !this.showOriginal;
    this.$emit('show-original', this.showOriginal);
  }
}
