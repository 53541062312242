



































































import Vue from 'vue';
import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
import BasicLogin from '@/models/login/BasicLogin';
import TermsOfUse from '@/components/login/TermsOfUse.vue';

@Component({
  components: {
    TermsOfUse,
  },
})
export default class SignUpForm extends Vue {
  email: string = '';
  isEmailServerError: boolean = false;
  isEmailTouched: boolean = false;
  isLoading: boolean = false;
  isPasswordServerError = false;
  isPasswordTouched: boolean = false;
  isRealUsername: boolean = false;
  isTermsActive: boolean = false;
  isUsernameServerError: boolean = false;
  isUsernameTouched: boolean = false;
  password: string = '';
  termsOfUse: boolean = false;
  username = '';

  get isAllValid() {
    return this.isEmailValid && this.isPasswordValid && this.isUsernameValid && this.termsOfUse;
  }

  get isEmailValid() {
    return !this.isEmailTouched || (!this.isEmailServerError && this.email.length > 0);
  }

  get isPasswordValid() {
    return !this.isPasswordTouched || this.password.length >= 8;
  }

  get isUsernameValid() {
    return !this.isUsernameTouched || (this.username.length > 0 && this.username.length <= 20);
  }

  agreedToTerms(agreed: boolean) {
    this.isTermsActive = false;
    this.termsOfUse = agreed !== true ? false : true;
  }

  close() {
    this.$emit('close', true);
    this.email = '';
    this.isEmailServerError = false;
    this.isEmailTouched =  false;
    this.isLoading = false;
    this.isPasswordServerError = false;
    this.isPasswordTouched = false;
    this.isRealUsername = false;
    this.isUsernameServerError = false;
    this.isUsernameTouched = false;
    this.password = '';
    this.termsOfUse = false;
    this.username = '';
  }

  setErrorResults(obj: any) {
    if (!obj) return;

    const {message} = obj;
    const errorMsg = message.split(': ');

    if (errorMsg.length === 1) {
      if (errorMsg[0].indexOf('email') > -1) this.isEmailServerError = true;
      else if (errorMsg[0].indexOf('IllegalRealUsername') > -1) this.isRealUsername = true;
      else if (errorMsg[0].indexOf('User') > -1) this.isUsernameServerError = true;
    } else if (errorMsg.length >= 2 && errorMsg[1].indexOf('Password') > -1) {
      this.isPasswordServerError = true;
    }
  }

  validateSignUp() {
    this.isEmailServerError = false;
    this.isPasswordServerError = false;
    this.isRealUsername = false;
    this.isUsernameServerError = false;
    this.isEmailTouched = true;
    this.isPasswordTouched = true;
    this.isUsernameTouched = true;

    if (!this.isAllValid) return;

    this.isLoading = true;

    const newLogin = new BasicLogin({
      email: this.email,
      password: this.password,
      username: this.username,
    });

    this.$store.dispatch('signUp', newLogin)
      .then((user: any) => {
        if (user && user.code && user.code.indexOf('Exception') > -1) {
          this.setErrorResults(user);
        } else {
          this.$emit('validated-user', newLogin);
        }
      })
      .catch(() => failure(this, {message: 'Error creating new user. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
