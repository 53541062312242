


































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import DeletedHeader from '@/components/shout/DeletedHeader.vue';
import EditedHeader from '@/components/shout/EditedHeader.vue';
// eslint-disable-next-line no-unused-vars
import ModelEcho from '@/models/shout/Echo';
// eslint-disable-next-line no-unused-vars
import Post from '@/models/shout/Post';
import ReportedHeader from '@/components/shout/ReportedHeader.vue';
import Shout from '@/components/shout/Shout.vue';
import ShoutHeader from '@/components/shout/ShoutHeader.vue';
import ShoutImage from '@/components/shout/ShoutImage.vue';
import ShoutMessage from '@/components/shout/ShoutMessage.vue';

@Component({
  components: {
    DeletedHeader,
    EditedHeader,
    ReportedHeader,
    Shout,
    ShoutHeader,
    ShoutImage,
    ShoutMessage,
  },
})
export default class Echo extends Vue {
  @Prop({default: false}) addedListen!: boolean;
  @Prop({default: 0}) echoes!: number;
  @Prop({required: true}) echo!: ModelEcho;
  @Prop({default: false}) isHidden!: boolean;
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({default: 0}) listens!: number;
  @Prop({default: 0}) newEchoes!: number;
  @Prop({required: true}) shout!: Post;

  message = '';

  get hasChain() {
    return this.echo.parentId !== this.echo.generationZeroId;
  }

  created() {
    this.message = this.shout.message;
    this.checkForReportedReason();
  }

  checkForReportedReason() {
    if (this.shout.reportReason) {
      this.message = 'REPORTED AND REMOVED';
    }
  }

  showOriginalPost(show: boolean) {
    if (show && this.shout.originalPost) {
      this.message = this.shout.originalPost;
    } else {
      this.message = this.shout.message;
    }
    this.checkForReportedReason();
  }

  viewChain() {
    this.$router.push(`/shoutchain/${this.echo.generationZeroId}`);
  }
}
