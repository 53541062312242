





























































































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import IconLocation from '@/enums/profile/iconLocation';
import ImageContainer from '@/components/profile/ImageContainer.vue';
// eslint-disable-next-line no-unused-vars
import Profile from '@/models/user/Profile';
import ProfileSkeleton from '@/components/skeletons/ProfileSkeleton.vue';
import UploadProfileImage from '@/components/profile/UploadProfileImage.vue';
import UserMixin from '@/mixins/User.vue';
import ViewOption from '@/enums/profile/viewOption';

@Component({
  components: {
    ImageContainer,
    ProfileSkeleton,
    UploadProfileImage,
  },
})
export default class ProfileEdit extends UserMixin {
  addShadow = true;
  banner: string | null = null;
  blurb = '';
  firstName = '';
  groupImageOption: string | ViewOption = ViewOption.NONE;
  iconLocation = IconLocation.TOP_LEFT;
  isLoading = false;
  isUploadImage = false;
  lastName = '';
  postImageOption: string | ViewOption = ViewOption.NONE;
  profile: Profile | null = null;
  uploadTitle = '';
  uploadType = '';

  get fullName() {
    return `${this.profile?.firstName} ${this.profile?.lastName}`;
  }

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get locations() {
    return Object.values(IconLocation).map((val: string) => val);
  }

  get viewBannerOptions() {
    return this.viewOptions.filter((opt: any) => opt !== ViewOption.LISTENING_TO);
  }

  get viewOptions() {
    return Object.values(ViewOption).map((opt: string) => opt);
  }

  @Watch('user')
  userChanged() {
    this.getData();
  }

  created() {
    this.getData();
  }

  closeUpload(isSuccess = false) {
    this.isUploadImage = false;
    this.uploadTitle = '';
    this.uploadType = '';

    if (isSuccess) {
      //Change the image... reload it, essentially...
      this.$store.dispatch('getProfile', this.user.username)
        .then((profile: Profile) => {
          if (profile && this.profile) {
            this.profile.banner = profile.banner;
          }
        });
    }
  }

  editBanner() {
    this.uploadTitle = 'Upload Banner Image';
    this.uploadType = 'banner';
    this.isUploadImage = true;
  }

  editIcon() {
    this.uploadTitle = 'Upload Icon Image';
    this.uploadType = 'icon';
    this.isUploadImage = true;
  }

  getData() {
    this.isLoading = true;
    if (!this.user) return;

    this.$store.dispatch('getProfile', this.user.username)
      .then((profile: Profile) => {
        this.profile = profile;
        this.reset();
      })
      .catch(() => failure(this, {message: 'Error retrieving my profile. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  reset() {
    if (!this.profile) return;

    this.addShadow = this.profile.addShadow;
    this.banner = this.profile.banner;
    this.blurb = this.profile.blurb;
    this.firstName = this.profile.firstName;
    this.groupImageOption = this.profile.groupImageViewOption || ViewOption.NONE;
    this.iconLocation = this.profile.iconLocation;
    this.lastName = this.profile.lastName;
    this.postImageOption = this.profile.imageViewOption || ViewOption.NONE;
  }

  save() {
    if (!this.profile) return;

    this.isLoading = true;

    const newProfile = new Profile({
      ...this.profile,
      addShadow: this.addShadow,
      blurb: this.blurb,
      firstName: this.firstName,
      groupImageViewOption: this.groupImageOption,
      iconLocation: this.iconLocation,
      imageViewOption: this.postImageOption,
      lastName: this.lastName,
    });

    this.$store.dispatch('updateProfile', newProfile)
      .then((profile: Profile) => {
        if (profile) {
          success(this, {message: 'Successfully saved your profile.'});
          this.$router.push(`/profile/${profile.username}`);
        } else {
          failure(this, {message: 'Error saving your profile. Try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error saving your profile. Try again later.'}))
      .finally(() => this.isLoading = false);
  }

  uploadedImage({type, image}: any) {
    if (!type || !image || !this.profile) return;

    setTimeout(() => this.$router.go(0), 1000);
  }
}
