import Group from "@/models/trending/Group";
import Hashtag from "@/models/trending/Hashtag";
import Mention from "@/models/trending/Mention";

export default class Trending {
  public groups: Array<Group> = [];
  public hashtags: Array<Hashtag> = [];
  public mentions: Array<Mention> = [];

  constructor({groups, hashtags, mentions}: Trending) {
    this.groups = groups ? groups : [];
    this.hashtags = hashtags ? hashtags : [];
    this.mentions = mentions ? mentions : [];
  }
}
