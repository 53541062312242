import Vue from 'vue';
import VueRouter from 'vue-router';

import Admin from '@/views/Admin.vue';
import BigShout from '@/views/BigShout.vue';
import Contact from '@/views/Contact.vue';
import FourOFour from '@/views/FourOFour.vue';
import Group from '@/views/Group.vue';
import Groups from '@/views/Groups.vue';
import Hashtag from '@/views/Hashtag.vue';
import Home from '@/views/Home.vue';
import Landing from '@/views/Landing.vue';
import Listeners from '@/views/Listeners.vue';
import MyEchoes from '@/views/MyEchoes.vue';
import MyHashtags from '@/views/MyHashtags.vue';
import MyMentions from '@/views/MyMentions.vue';
import MyShouts from '@/views/MyShouts.vue';
import Profile from '@/views/Profile.vue';
import ProfileEdit from '@/views/ProfileEdit.vue';
import Search from '@/views/Search.vue';
import SearchGroups from '@/views/SearchGroups.vue';
import Shout from '@/views/Shout.vue';
import ShoutChain from '@/views/ShoutChain.vue';
import TermsOfUse from '@/views/TermsOfUse.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/echoes',
    name: 'MyEchoes',
    component: MyEchoes,
  },
  {
    path: '/shout/:id',
    name: 'Shout',
    component: Shout,
  },
  {
    path: '/shouts',
    name: 'MyShouts',
    component: MyShouts,
  },
  {
    path: '/profile',
    name: 'ProfileEdit',
    component: ProfileEdit,
  },
  {
    path: '/profile/:username',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/listeners/:type',
    name: 'Listeners',
    component: Listeners,
  },
  {
    path: '/shoutchain/:id',
    name: 'ShoutChain',
    component: ShoutChain,
  },
  {
    path: '/hashtag/:hashtag',
    name: 'Hashtag',
    component: Hashtag,
  },
  {
    path: '/hashtags',
    name: 'Hashtags',
    component: MyHashtags,
  },
  {
    path: '/mentions',
    name: 'Mentions',
    component: MyMentions,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
  },
  {
    path: '/group/:name',
    name: 'Group',
    component: Group,
  },
  {
    path: '/search/groups',
    name: 'SearchGroups',
    component: SearchGroups,
  },
  {
    path: '/bigshout',
    name: 'BigShout',
    component: BigShout,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsOfUse,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/*',
    name: '404',
    component: FourOFour,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
