


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Sort, Type } from '@/enums/calls/sortBy';

@Component
export default class ShoutSorter extends Vue {
  @Prop({default: 'All'}) option!: Type;
  @Prop({required: false}) sortOption!: Sort;
  @Prop({default: ''}) title!: string;

  options = Type.All;
  selectedSortOption = Sort.Recent;

  get sorts() {
    return Object.values(Sort).map((val: string) => val);
  }

  @Watch('options')
  optionsChanged(newVal: Type) {
    this.$emit('option-changed', newVal);
  }

  @Watch('selectedSortOption')
  selectedSortOptionChanged(newVal: Sort) {
    this.$emit('sort-option-changed', newVal);
  }

  created() {
    this.options = this.option;
    this.selectedSortOption = this.sortOption ? this.sortOption : Sort.Recent;
  }

  chooseOption(val: Type) {
    this.options = val;
  }
}
