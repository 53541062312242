







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { anchor } from '@/utility/main';
// eslint-disable-next-line no-unused-vars
import Profile from '@/models/user/Profile';

@Component
export default class SmallProfile extends Vue {
  @Prop({default: true}) hasFollowingButton!: boolean;
  @Prop({default: false}) isFollowing!: boolean;
  @Prop({required: true}) profile!: Profile;

  follow = false;
  iconToUse = '';
  linkedMsg: string = '';

  get fullName() {
    const name = `${this.profile.firstName} ${this.profile.lastName}`
    return name.trim().length > 0 ? name : 'Anonymous';
  }

  get isOfficial() {
    return this.profile.isOfficial;
  }

  get label() {
    return this.follow ? 'Listening' : 'Listen To...';
  }

  get type() {
    return this.follow ? 'is-success is-light' : 'is-info is-light';
  }

  created() {
    this.iconToUse = this.profile.profileIcon;
    this.follow = this.isFollowing;
    this.linkedMsg = anchor(this.profile.blurb);
  }

  changeFollowing() {
    this.$emit('is-following', this.profile.username);
    this.follow = !this.follow;
  }

  onError() {
    this.iconToUse = require('@/assets/anonymous_user.png');
  }

  openProfile() {
    this.$router.push(`/profile/${this.profile.username}`);
  }
}
