



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import Echo from '@/components/shout/Echo.vue';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
import Hashtag from '@/components/hashtag/Hashtag.vue';
// eslint-disable-next-line no-unused-vars
import Profile from '@/models/user/Profile';
import Shout from '@/components/shout/Shout.vue';
import SmallProfile from '@/components/profile/SmallProfile.vue';

@Component({
  components: {
    Echo,
    Hashtag,
    Shout,
    SmallProfile,
  },
})
export default class SearchResults extends Vue {
  @Prop({required: true}) results!: any;

  hashtags: Array<string> = [];
  fullPosts: Array<FullPost> = [];
  profiles: Array<Profile> = [];

  get array() {
    return this.results && this.results instanceof Array ? this.results : [];
  }

  get hasHashtagsHr() {
    return this.hashtags.length && (this.fullPosts.length || this.profiles.length);
  }

  get hasProfilesHr() {
    return this.profiles.length && this.fullPosts.length;
  }

  @Watch('results')
  resultsChanged() {
    this.setFields();
  }

  created() {
    this.setFields();
  }

  setFields() {
    if (!this.results) return;

    if (this.results instanceof Array) {
      this.hashtags = [];
      this.fullPosts = [];
      this.profiles = [];

      this.array.forEach((obj: any) => {
        if (obj.post) this.fullPosts.splice(0, 0, obj);
        else if (obj.username) this.profiles.splice(0, 0, obj);
        else this.hashtags.splice(0, 0, obj);
      });
    } else {
      this.hashtags = this.results.hashtags;
      this.fullPosts = this.results.posts;
      this.profiles = this.results.profiles;
    }
  }
}
