


























































import Vue from 'vue';
import { mapGetters } from 'vuex';

import LoginForm from '@/components/login/LoginForm.vue';
import TermsOfUse from '@/components/login/TermsOfUse.vue';

export default Vue.extend({
  name: 'Landing',
  components: {
    LoginForm,
    TermsOfUse,
  },
  data() {
    return {
      isTermsActive: false,
      logoStyle: {
        opacity: 1.0,
      },
    };
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet',
    ]),
  },
  created() {
    window.addEventListener('scroll', () => {
      if (this.isMobile || this.isTablet) {
        const perc = Math.abs(window.scrollY / 224.0 - 1.0);
        this.logoStyle.opacity = window.scrollY > 224.0 ? 0.0 : perc;
      } else {
        this.logoStyle.opacity = 1.0;
      }
    });
  },
  destroyed() {
    window.removeEventListener('scroll', () => {});
  },
  methods: {
    guestLogin(agreed: boolean) {
      this.isTermsActive = false;
      if (agreed !== true) return;

      this.$store.dispatch('setUser', null);
      this.$router.push('/home');
    },
  },
});
