const Contact = {
  getters: {

  },
  actions: {
    sendContact(context: any, { message, topic }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverSendContact', {message, topic})
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
  },
  mutations: {

  },
  state: {

  },
};

export default Contact;
