export default class MyNotifications {
  echoNums = 0;
  groupNames: Array<string> = [];
  hashtags: Array<any> = []; // This one is unique since we need to keep track of the hashtags
  mentionNums = 0;
  notificationNames: Array<string> = [];

  constructor({echoNums, groupNames, hashtags, mentionNums, notificationNames}: any) {
    this.echoNums = echoNums != null ? echoNums : 0;
    this.groupNames = groupNames ? groupNames : [];
    this.hashtags = hashtags ? hashtags : [];
    this.mentionNums = mentionNums != null ? mentionNums : 0;
    this.notificationNames = notificationNames ? notificationNames : [];
  }
}
