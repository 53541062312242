







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import ModelGroup from '@/models/trending/Group';

@Component
export default class Group extends Vue {
  @Prop({required: true}) group!: ModelGroup;

  get anchor() {
    return `/group/${this.name}`;
  }

  get description() {
    return this.group.description && this.group.description.length > 50 ?
      `${this.group.description.substr(0, 50)}...` : this.group.description;
  }

  get name() {
    return this.group.group;
  }

  openGroup() {
    if (this.$route.fullPath === this.anchor) return;
    this.$router.push(this.anchor);
  }
}
