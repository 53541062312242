
























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import { setShortDateFormat } from '@/utility/main';
// eslint-disable-next-line no-unused-vars
import ActionTaken from '@/enums/admin/actionTaken';
// eslint-disable-next-line no-unused-vars
import Post from '@/models/shout/Post';
// eslint-disable-next-line no-unused-vars
import ReportedPost from '@/models/admin/ReportedPost';

@Component
export default class ReportedPostEdit extends Vue {
  @Prop({required: true}) post!: ReportedPost;
  @Prop({required: true}) isActive!: boolean;

  actionTaken = false;
  createdAt = '';
  isLoading = false;
  isModalActive = false;
  notes = '';
  postId = '';
  reportedBy = '';
  reportReason = '';
  resolved = false;
  successMsg = '';
  username = '';

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
    if (newVal) this.resetData();
  }

  @Watch('post')
  contactChanged(newVal: ReportedPost) {
    if (newVal) this.resetData();
  }

  created() {
    this.isModalActive = this.isActive;
    this.resetData();
  }

  close(isSuccess: boolean = false) {
    this.$emit('close', isSuccess);
  }

  removeAndReport() {
    // This will remove the post and report the user.
    const reportUser = () => {
      this.isLoading = true;

      this.$store.dispatch('adminReportUser', {username: this.post.username, postId: this.postId})
        .then((isSuccess: boolean) => {
          if (isSuccess) {
            this.successMsg = 'Post removed and profile reported!';
            this.removeReportedPost(true);
          } else {
            this.isLoading = false;
            failure(this, {message: 'Error reporting the user. Reporting cancelled.'});
          }
        })
        .catch(() => {
          this.isLoading = false;
          failure(this, {message: 'Error reporting the user. Reporting cancelled.'});
        });
    };

    this.$buefy.dialog.confirm({
      title: 'Reporting User',
      message: 'Are you sure you want to <b>report</b> this User? This cannot be undone.',
      confirmText: 'Report User',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: reportUser,
    });
  }

  removeReportedPost(wasReported = false) {
    // This will just remove the post.
    this.isLoading = true;

    this.$store.dispatch('adminReportPost', {postId: this.post.postId, reportReason: this.reportReason})
      .then((post: Post) => {
        if (post) {
          if (!this.successMsg) this.successMsg = 'Post removed!';
          this.actionTaken = true;
          this.updateReportedPost(wasReported);
        } else {
          this.isLoading = false;
          failure(this, {message: 'Error removing the post. Reporting cancelled.'});
        }
      })
      .catch(() => {
        this.isLoading = false;
        failure(this, {message: 'Error removing the post. Reporting cancelled.'});
      });
  }

  resetData() {
    if (!this.post) return;

    this.actionTaken = !!this.post.actionTaken;
    this.createdAt = setShortDateFormat(new Date(this.post['createdAt']).getTime());
    this.notes = this.post.notes;
    this.postId = this.post.postId;
    this.reportedBy = this.post.reportedBy;
    this.reportReason = this.post.reportReason;
    this.resolved = this.post.resolved;
    this.username = this.post.username;
  }

  updateReportedPost(wasReported = false) {
    this.isLoading = true;

    const actionTaken = wasReported ? ActionTaken.REPORTED :
      this.post.actionTaken ? this.post.actionTaken :
        this.actionTaken ? ActionTaken.EDITED : '';

    const updatedPost = {
      actionTaken,
      id: this.post.id,
      notes: this.notes,
      postId: this.post.postId,
      reportedBy: this.post.reportedBy,
      reportReason: this.post.reportReason,
      resolved: this.resolved,
      username: this.post.username,
    };

    this.$store.dispatch('updateAdminReportedPost', updatedPost)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          if (!this.successMsg) this.successMsg = 'Post updated!';
          success(this, {message: this.successMsg});
          this.close(true);
        } else {
          failure(this, {message: 'Error updating the post. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the post. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
