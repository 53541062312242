import { render, staticRenderFns } from "./ProfileSkeleton.vue?vue&type=template&id=0cde7fe4&scoped=true&"
import script from "./ProfileSkeleton.vue?vue&type=script&lang=ts&"
export * from "./ProfileSkeleton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cde7fe4",
  null
  
)

export default component.exports