export default class Listen {
  id: string = '';
  listeners: Array<string> = [];
  listenersNum: number = 0;
  listeningTo: Array<string> = [];
  listeningToNum: number = 0;
  username: string = '';

  constructor({listeners, listenersNum, listeningTo, listeningToNum, username}: any) {
    this.id = username ? username.toLowerCase() : '';
    this.listeners = listeners ? listeners : [];
    this.listenersNum = listenersNum != null ? listenersNum : 0;
    this.listeningTo = listeningTo ? listeningTo : [];
    this.listeningToNum = listeningToNum != null ? listeningToNum : 0;
    this.username = username ? username : '';
  }
}
