



































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import ReportedUser from '@/models/admin/ReportedUser';
import ReportedUserEdit from '@/components/admin/ReportedUserEdit.vue';

@Component({
  components: {
    ReportedUserEdit,
  },
})
export default class ReportedUsers extends Vue {
  @Prop({required: true}) reload!: boolean;

  data: Array<ReportedUser> = [];
  defaultSortDirection = 'desc';
  dontOpen = false;
  isModalActive = false;
  record: ReportedUser|null = null;
  selected: any = null;

  get size() {
    return this.$store.getters['isLargeMode'] ? 'is-normal' : 'is-small';
  }

  @Watch('reload')
  reloadChanged(newVal: boolean) {
    if (newVal) this.getData();
  }

  @Watch('selected')
  selectedChanged(newVal: ReportedUser) {
    if (this.dontOpen) {
      this.dontOpen = false;
      return;
    } else {
      this.record = newVal;
      this.isModalActive = true;
    }
  }

  close(isSuccess: boolean) {
    this.record = null;
    this.isModalActive = false;

    if (isSuccess) this.getData();
  }

  getData() {
    this.$store.dispatch('getAdminReportedUsers')
      .then((errors: Array<ReportedUser>) => this.data = errors)
      .catch(() => failure(this, {message: 'Error retrieving the users. Please try again later.'}))
      .finally(() => this.$emit('done-loading', true));
  }

  open(username: string) {
    this.dontOpen = true;
    if (!username) return;

    this.$router.push(`/profile/${username}`);
  }
}
