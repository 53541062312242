






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import ModelHashtag from '@/models/trending/Hashtag';

@Component
export default class Hashtag extends Vue {
  @Prop({required: true}) hashtag!: ModelHashtag;

  get anchor() {
    return `/hashtag/${this.name}`;
  }

  get name() {
    return this.hashtag.hashtag;
  }

  openHashtag() {
    if (this.$route.fullPath === this.anchor) return;
    this.$router.push(this.anchor);
  }
}
