




































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import BasicLogin from '@/models/login/BasicLogin';

@Component
export default class ConfirmForm extends Vue {
  @Prop({required: true}) basicLogin!: BasicLogin;

  isLoading = false;
  isVerificationCodeTouched: boolean = false;
  title = 'Enter Verification Code';
  verificationCode = '';

  get isVerificationCodeValid() {
    return !this.isVerificationCodeTouched || this.verificationCode.trim().length === 6;
  }

  get verificationLabel() {
    return this.basicLogin && this.basicLogin.email ? this.basicLogin.email : this.basicLogin.username;
  }

  close() {
    this.$emit('close', true);
    this.isVerificationCodeTouched = false;
    this.verificationCode = '';
  }

  confirmSignUp() {
    if (!this.isVerificationCodeValid || !this.basicLogin) return;

    this.isLoading = true;

    this.$store.dispatch('confirmConfirmationCode', {username: this.basicLogin.username, code: this.verificationCode})
      .then((confirmed: string) => {
        if (confirmed && confirmed === 'SUCCESS') {
          this.isLoading = false;
          success(this, {message: 'Account Verified!'});
          this.close();
        } else {
          failure(this, {message: 'Code Invalid. Please try again.'});
        }
      })
      .catch(() => failure(this, {message: 'There was an issue verifying the code. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  resendCode() {
    if (!this.basicLogin) return;

    this.$store.dispatch('resendConfirmationCode', this.basicLogin.username)
      .then(() => this.title = 'Verification Code Resent')
      .catch(() => failure(this, {message: 'Error resending code. Please try again later.'}));
  }
}
