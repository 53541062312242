import Post from '@/models/shout/Post';

export default class FullPost {
  addedListen = false;
  associatedPost: Post|null = null;
  echoes = 0;
  isHidden = false;
  listens = 0;
  post!: Post;
  reportReason = '';

  constructor({addedListen, associatedPost, echoes, isHidden, listens, post, reportReason}: any) {
    this.addedListen = addedListen != null ? addedListen : false;
    this.associatedPost = associatedPost ? associatedPost : null;
    this.echoes = echoes ? echoes : 0;
    this.isHidden = isHidden != null ? isHidden : false;
    this.listens = listens ? listens : 0;
    this.post = post ? post : new Post({});
    this.reportReason = reportReason ? reportReason : '';
  }
}
