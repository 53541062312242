enum IconLocation {
  BOTTOM_LEFT = 'Bottom Left',
  BOTTOM_MIDDLE = 'Bottom Middle',
  BOTTOM_RIGHT = 'Bottom Right',
  TOP_LEFT = 'Top Left',
  TOP_MIDDLE = 'Top Middle',
  TOP_RIGHT = 'Top Right',
}

export default IconLocation;
