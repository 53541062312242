











































import { Component } from 'vue-property-decorator';
import { Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import GroupUsers from '@/models/group/GroupUsers';
import OpenUser from '@/mixins/OpenUser.vue';

@Component
export default class UserGroup extends OpenUser {
  groupId = '';
  isLoading = false;
  isModalActive = false;
  users: GroupUsers = new GroupUsers({});

  get isUserGroupModalActive() {
		return this.$store.getters['isUserGroupModalActive'];
	}

  get me() {
    return this.user.username;
  }

  @Watch('isUserGroupModalActive')
	modalActiveChanged(newVal: any) {
    this.isModalActive = newVal.isOpen;
    if (this.isModalActive) {
      this.groupId = newVal.groupId;
      this.getData(newVal.group);
    }
	}

  approveUser(name: string) {
    this.isLoading = true;

    this.$store.dispatch('approveGroupUser', {username: name, groupId: this.groupId})
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          const lower = name.toLowerCase();
          const { usersRequesting } = this.users;
          const index = usersRequesting.findIndex((user) => user.toLowerCase() === lower);

          if (index > -1) {
            usersRequesting.splice(index, 1);
            this.users.usersRequesting = [...usersRequesting];

            const { currentUsers } = this.users;
            currentUsers.splice(0, 0, lower);
            this.users.currentUsers = [...currentUsers.sort()];
          }
        } else {
          failure(this, {message: 'Error updating the user\'s status.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the user\'s status.'}))
      .finally(() => this.isLoading = false);
  }

  close() {
    this.users = new GroupUsers({});
    this.$store.dispatch('closeUserGroupModal');
  }

  denyUser(name: string) {
    this.isLoading = true;

    this.$store.dispatch('denyGroupUser', {username: name, groupId: this.groupId})
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          const lower = name.toLowerCase();
          const requestedIndex = this.users.usersRequesting.findIndex((user) => user.toLowerCase() === lower);
          if (requestedIndex > -1) {
            this.users.usersRequesting.splice(requestedIndex, 1);
            return;
          }
          
          const currentIndex = this.users.currentUsers.findIndex((user) => user.toLowerCase() === lower);
          if (currentIndex > -1) {
            this.users.currentUsers.splice(currentIndex, 1);
          }
        } else {
          failure(this, {message: 'Error updating the user\'s status.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the user\'s status.'}))
      .finally(() => this.isLoading = false);
  }

  getData(name: string) {
    if (!name) return;

    this.isLoading = true;

    this.$store.dispatch('getGroupUsers', name)
      .then((users: GroupUsers) => this.users = users)
      .catch(() => failure(this, {message: 'Error retrieving user list. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
