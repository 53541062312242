



























































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import ListenerType from '@/enums/calls/listener';
import OpenLink from '@/mixins/OpenLink.vue';
// eslint-disable-next-line no-unused-vars
import User from '@/models/user/User';

@Component
export default class SmallMenu extends OpenLink {
  @Prop({default: null}) user!: any;

  openMenu = false;

  get hasAdmin() {
    const user: User = this.$store.getters['getUser'];
    return user ? user.groups.some((grp) => grp === 'admin') : false;
  }

  get listeners() {
    return ListenerType.LISTENERS;
  }

  get listeningTo() {
    return ListenerType.LISTENING_TO;
  }

  logout() {
    this.$store.dispatch('signOut')
      .finally(() => this.open('/'));
  }

  open(link: string) {
    this.openMenu = false;
    this.openLink(link);
  }

  openLogInModal() {
    this.$store.dispatch('openLogInModal');
  }

  openSignUpModal() {
    this.$store.dispatch('openSignUpModal');
  }
}
