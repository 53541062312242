/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onListenUpdateById = /* GraphQL */ `
  subscription OnListenUpdateById($id: ID!) {
    onListenUpdateById(id: $id) {
      id
      listeners
      listenersNum
      listeningTo
      listeningToNum
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateEchoNotifications = /* GraphQL */ `
  subscription OnCreateEchoNotifications($username: String) {
    onCreateEchoNotifications(username: $username) {
      id
      newNotifications
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteEchoNotifications = /* GraphQL */ `
  subscription OnDeleteEchoNotifications($username: String) {
    onDeleteEchoNotifications(username: $username) {
      id
      newNotifications
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateHashtagNotifications = /* GraphQL */ `
  subscription OnCreateHashtagNotifications($username: String) {
    onCreateHashtagNotifications(username: $username) {
      hashtag
      id
      newNotifications
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateHashtagNotifications = /* GraphQL */ `
  subscription OnUpdateHashtagNotifications($username: String) {
    onUpdateHashtagNotifications(username: $username) {
      hashtag
      id
      newNotifications
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateMentionNotifications = /* GraphQL */ `
  subscription OnCreateMentionNotifications($username: String) {
    onCreateMentionNotifications(username: $username) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteMentionNotifications = /* GraphQL */ `
  subscription OnDeleteMentionNotifications($username: String) {
    onDeleteMentionNotifications(username: $username) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($name: String) {
    onUpdateGroup(name: $name) {
      banner
      categories
      createdBy
      description
      id
      isOfficial
      isOwnerOnly
      isVerified
      listeners
      locations
      name
      preferredName
      posts
      requestedList
      createdAt
      updatedAt
      owner
      postIds {
        items {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateGroupSubscriptions = /* GraphQL */ `
  subscription OnCreateGroupSubscriptions($groupId: String) {
    onCreateGroupSubscriptions(groupId: $groupId) {
      cannotRemove
      groupId
      id
      lastCheckedPostNumber
      status
      username
      createdAt
      updatedAt
      group {
        items {
          banner
          categories
          createdBy
          description
          id
          isOfficial
          isOwnerOnly
          isVerified
          listeners
          locations
          name
          preferredName
          posts
          requestedList
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onUpdateGroupSubscriptions = /* GraphQL */ `
  subscription OnUpdateGroupSubscriptions($username: String) {
    onUpdateGroupSubscriptions(username: $username) {
      cannotRemove
      groupId
      id
      lastCheckedPostNumber
      status
      username
      createdAt
      updatedAt
      group {
        items {
          banner
          categories
          createdBy
          description
          id
          isOfficial
          isOwnerOnly
          isVerified
          listeners
          locations
          name
          preferredName
          posts
          requestedList
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const onCreateBlockUser = /* GraphQL */ `
  subscription OnCreateBlockUser {
    onCreateBlockUser {
      blackList
      id
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateBlockUser = /* GraphQL */ `
  subscription OnUpdateBlockUser {
    onUpdateBlockUser {
      blackList
      id
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteBlockUser = /* GraphQL */ `
  subscription OnDeleteBlockUser {
    onDeleteBlockUser {
      blackList
      id
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      message
      notes
      resolved
      topic
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      message
      notes
      resolved
      topic
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      message
      notes
      resolved
      topic
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateErrorCatch = /* GraphQL */ `
  subscription OnCreateErrorCatch {
    onCreateErrorCatch {
      createdAt
      error
      function
      id
      notes
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const onUpdateErrorCatch = /* GraphQL */ `
  subscription OnUpdateErrorCatch {
    onUpdateErrorCatch {
      createdAt
      error
      function
      id
      notes
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const onDeleteErrorCatch = /* GraphQL */ `
  subscription OnDeleteErrorCatch {
    onDeleteErrorCatch {
      createdAt
      error
      function
      id
      notes
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const onCreateHashtag = /* GraphQL */ `
  subscription OnCreateHashtag {
    onCreateHashtag {
      id
      createdAt
      updatedAt
      owner
      hashtagInfo {
        items {
          createdAt
          hashtagId
          id
          postId
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateHashtag = /* GraphQL */ `
  subscription OnUpdateHashtag {
    onUpdateHashtag {
      id
      createdAt
      updatedAt
      owner
      hashtagInfo {
        items {
          createdAt
          hashtagId
          id
          postId
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteHashtag = /* GraphQL */ `
  subscription OnDeleteHashtag {
    onDeleteHashtag {
      id
      createdAt
      updatedAt
      owner
      hashtagInfo {
        items {
          createdAt
          hashtagId
          id
          postId
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateHashtagPosts = /* GraphQL */ `
  subscription OnCreateHashtagPosts {
    onCreateHashtagPosts {
      createdAt
      hashtagId
      id
      postId
      updatedAt
      owner
    }
  }
`;
export const onUpdateHashtagPosts = /* GraphQL */ `
  subscription OnUpdateHashtagPosts {
    onUpdateHashtagPosts {
      createdAt
      hashtagId
      id
      postId
      updatedAt
      owner
    }
  }
`;
export const onDeleteHashtagPosts = /* GraphQL */ `
  subscription OnDeleteHashtagPosts {
    onDeleteHashtagPosts {
      createdAt
      hashtagId
      id
      postId
      updatedAt
      owner
    }
  }
`;
export const onCreateListen = /* GraphQL */ `
  subscription OnCreateListen {
    onCreateListen {
      id
      listeners
      listenersNum
      listeningTo
      listeningToNum
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateListen = /* GraphQL */ `
  subscription OnUpdateListen {
    onUpdateListen {
      id
      listeners
      listenersNum
      listeningTo
      listeningToNum
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteListen = /* GraphQL */ `
  subscription OnDeleteListen {
    onDeleteListen {
      id
      listeners
      listenersNum
      listeningTo
      listeningToNum
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateMentions = /* GraphQL */ `
  subscription OnCreateMentions {
    onCreateMentions {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateMentions = /* GraphQL */ `
  subscription OnUpdateMentions {
    onUpdateMentions {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteMentions = /* GraphQL */ `
  subscription OnDeleteMentions {
    onDeleteMentions {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateOfficialPost = /* GraphQL */ `
  subscription OnCreateOfficialPost {
    onCreateOfficialPost {
      createdAt
      id
      isOfficial
      postId
      updatedAt
      owner
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
    }
  }
`;
export const onUpdateOfficialPost = /* GraphQL */ `
  subscription OnUpdateOfficialPost {
    onUpdateOfficialPost {
      createdAt
      id
      isOfficial
      postId
      updatedAt
      owner
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
    }
  }
`;
export const onDeleteOfficialPost = /* GraphQL */ `
  subscription OnDeleteOfficialPost {
    onDeleteOfficialPost {
      createdAt
      id
      isOfficial
      postId
      updatedAt
      owner
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      createdAt
      editReason
      exists
      deleteReason
      generationZeroId
      groupId
      id
      isHidden
      isOfficial
      isVerified
      image
      message
      originalPost
      parentId
      profileIcon
      reportReason
      type
      username
      updatedAt
      owner
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      createdAt
      editReason
      exists
      deleteReason
      generationZeroId
      groupId
      id
      isHidden
      isOfficial
      isVerified
      image
      message
      originalPost
      parentId
      profileIcon
      reportReason
      type
      username
      updatedAt
      owner
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      createdAt
      editReason
      exists
      deleteReason
      generationZeroId
      groupId
      id
      isHidden
      isOfficial
      isVerified
      image
      message
      originalPost
      parentId
      profileIcon
      reportReason
      type
      username
      updatedAt
      owner
    }
  }
`;
export const onCreatePostNumbers = /* GraphQL */ `
  subscription OnCreatePostNumbers {
    onCreatePostNumbers {
      echoes
      exists
      groupId
      id
      listens
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePostNumbers = /* GraphQL */ `
  subscription OnUpdatePostNumbers {
    onUpdatePostNumbers {
      echoes
      exists
      groupId
      id
      listens
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePostNumbers = /* GraphQL */ `
  subscription OnDeletePostNumbers {
    onDeletePostNumbers {
      echoes
      exists
      groupId
      id
      listens
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePostStatus = /* GraphQL */ `
  subscription OnCreatePostStatus($owner: String) {
    onCreatePostStatus(owner: $owner) {
      addedListen
      id
      isHidden
      postId
      reportReason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePostStatus = /* GraphQL */ `
  subscription OnUpdatePostStatus($owner: String) {
    onUpdatePostStatus(owner: $owner) {
      addedListen
      id
      isHidden
      postId
      reportReason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePostStatus = /* GraphQL */ `
  subscription OnDeletePostStatus($owner: String) {
    onDeletePostStatus(owner: $owner) {
      addedListen
      id
      isHidden
      postId
      reportReason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateProfile = /* GraphQL */ `
  subscription OnCreateProfile {
    onCreateProfile {
      addShadow
      banner
      blurb
      cannotRemove
      firstName
      groupImageViewOption
      iconLocation
      id
      imageViewOption
      isOfficial
      isVerified
      lastName
      listeners
      listeningTo
      profileIcon
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateProfile = /* GraphQL */ `
  subscription OnUpdateProfile {
    onUpdateProfile {
      addShadow
      banner
      blurb
      cannotRemove
      firstName
      groupImageViewOption
      iconLocation
      id
      imageViewOption
      isOfficial
      isVerified
      lastName
      listeners
      listeningTo
      profileIcon
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteProfile = /* GraphQL */ `
  subscription OnDeleteProfile {
    onDeleteProfile {
      addShadow
      banner
      blurb
      cannotRemove
      firstName
      groupImageViewOption
      iconLocation
      id
      imageViewOption
      isOfficial
      isVerified
      lastName
      listeners
      listeningTo
      profileIcon
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateReportGroup = /* GraphQL */ `
  subscription OnCreateReportGroup($owner: String) {
    onCreateReportGroup(owner: $owner) {
      actionTaken
      createdAt
      groupId
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      group {
        banner
        categories
        createdBy
        description
        id
        isOfficial
        isOwnerOnly
        isVerified
        listeners
        locations
        name
        preferredName
        posts
        requestedList
        createdAt
        updatedAt
        owner
        postIds {
          nextToken
        }
      }
      owner
    }
  }
`;
export const onUpdateReportGroup = /* GraphQL */ `
  subscription OnUpdateReportGroup($owner: String) {
    onUpdateReportGroup(owner: $owner) {
      actionTaken
      createdAt
      groupId
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      group {
        banner
        categories
        createdBy
        description
        id
        isOfficial
        isOwnerOnly
        isVerified
        listeners
        locations
        name
        preferredName
        posts
        requestedList
        createdAt
        updatedAt
        owner
        postIds {
          nextToken
        }
      }
      owner
    }
  }
`;
export const onDeleteReportGroup = /* GraphQL */ `
  subscription OnDeleteReportGroup($owner: String) {
    onDeleteReportGroup(owner: $owner) {
      actionTaken
      createdAt
      groupId
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      group {
        banner
        categories
        createdBy
        description
        id
        isOfficial
        isOwnerOnly
        isVerified
        listeners
        locations
        name
        preferredName
        posts
        requestedList
        createdAt
        updatedAt
        owner
        postIds {
          nextToken
        }
      }
      owner
    }
  }
`;
export const onCreateReportPost = /* GraphQL */ `
  subscription OnCreateReportPost($owner: String) {
    onCreateReportPost(owner: $owner) {
      actionTaken
      createdAt
      id
      notes
      postId
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const onUpdateReportPost = /* GraphQL */ `
  subscription OnUpdateReportPost($owner: String) {
    onUpdateReportPost(owner: $owner) {
      actionTaken
      createdAt
      id
      notes
      postId
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const onDeleteReportPost = /* GraphQL */ `
  subscription OnDeleteReportPost($owner: String) {
    onDeleteReportPost(owner: $owner) {
      actionTaken
      createdAt
      id
      notes
      postId
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const onCreateReportProfile = /* GraphQL */ `
  subscription OnCreateReportProfile($owner: String) {
    onCreateReportProfile(owner: $owner) {
      actionTaken
      createdAt
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const onUpdateReportProfile = /* GraphQL */ `
  subscription OnUpdateReportProfile($owner: String) {
    onUpdateReportProfile(owner: $owner) {
      actionTaken
      createdAt
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const onDeleteReportProfile = /* GraphQL */ `
  subscription OnDeleteReportProfile($owner: String) {
    onDeleteReportProfile(owner: $owner) {
      actionTaken
      createdAt
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const onCreateReportUser = /* GraphQL */ `
  subscription OnCreateReportUser {
    onCreateReportUser {
      banned
      count
      id
      notes
      username
      createdAt
      updatedAt
      reportGroup {
        items {
          actionTaken
          createdAt
          groupId
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportPost {
        items {
          actionTaken
          createdAt
          id
          notes
          postId
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportProfile {
        items {
          actionTaken
          createdAt
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onUpdateReportUser = /* GraphQL */ `
  subscription OnUpdateReportUser {
    onUpdateReportUser {
      banned
      count
      id
      notes
      username
      createdAt
      updatedAt
      reportGroup {
        items {
          actionTaken
          createdAt
          groupId
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportPost {
        items {
          actionTaken
          createdAt
          id
          notes
          postId
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportProfile {
        items {
          actionTaken
          createdAt
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onDeleteReportUser = /* GraphQL */ `
  subscription OnDeleteReportUser {
    onDeleteReportUser {
      banned
      count
      id
      notes
      username
      createdAt
      updatedAt
      reportGroup {
        items {
          actionTaken
          createdAt
          groupId
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportPost {
        items {
          actionTaken
          createdAt
          id
          notes
          postId
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportProfile {
        items {
          actionTaken
          createdAt
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const onCreateTrending = /* GraphQL */ `
  subscription OnCreateTrending {
    onCreateTrending {
      createdAt
      description
      icon
      id
      type
      value
      updatedAt
      owner
    }
  }
`;
export const onUpdateTrending = /* GraphQL */ `
  subscription OnUpdateTrending {
    onUpdateTrending {
      createdAt
      description
      icon
      id
      type
      value
      updatedAt
      owner
    }
  }
`;
export const onDeleteTrending = /* GraphQL */ `
  subscription OnDeleteTrending {
    onDeleteTrending {
      createdAt
      description
      icon
      id
      type
      value
      updatedAt
      owner
    }
  }
`;
