



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import LoginForm from '@/components/login/LoginForm.vue';

@Component({
  components: {
    LoginForm,
  },
})
export default class LoginModal extends Vue {
  isModalActive = false;

  get isLogInModalActive() {
		return this.$store.getters['isLogInModalActive'];
	}

  @Watch('isLogInModalActive')
	modalActiveChanged(newVal: boolean) {
		this.isModalActive = newVal;
	}

  close() {
    this.$store.dispatch('closeLogInModal');
	}
}
