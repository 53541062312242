



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Echo from '@/models/shout/Echo';
// eslint-disable-next-line no-unused-vars
import Post from '@/models/shout/Post';
import ReportedTypes from '@/enums/shout/reportedTypes';
import TermsOfUse from '@/components/login/TermsOfUse.vue';

@Component({
  components: {
    TermsOfUse,
  },
})
export default class ReportPost extends Vue {
  isFieldTouched = false;
  isLoading = false;
  isModalActive = false;
  post: Post|null = null;
  postId: string|null = null;
  reportReason: ReportedTypes|null = null;
  termsOpen = false;

  get isReportPostModalActive() {
		return this.$store.getters['isReportPostModalActive'];
	}

  get isReportValid() {
    return (this.isFieldTouched && this.reportReason != null) || !this.isFieldTouched;
  }

  get postType() {
    return this.post && this.post instanceof Echo ? 'Echo' : 'Shout';
  }

  get reasons() {
    return Object.values(ReportedTypes).map((val: string) => val);
  }

  get title() {
    return this.post ? `Report ${this.post.message.substr(0, 14)}...` : 'Report?';
  }

  @Watch('isReportPostModalActive')
	modalActiveChanged(newVal: any) {
		this.isModalActive = newVal.isOpen;
		this.postId = newVal.postId;

    this.loadPost();
	}

  close() {
    this.reportReason = null;
    this.isFieldTouched = false;
		this.$store.dispatch('closeReportPostModal');
	}

  loadPost() {
    if (!this.postId) return;

    this.isLoading = true;

    this.$store.dispatch('serverGetPost', this.postId)
      .then((post: Post) => this.post = post)
      .catch(() => failure(this, {message: 'Error loading the post. Please try again later.'}))
      .finally(() => {
        this.$store.dispatch('setGlobalLoading', false);
        this.isLoading = false;
      });
  }

  report() {
    this.isFieldTouched = true;
    if (!this.isReportValid || !this.post) return;

    this.isLoading = true;

    this.$store.dispatch('reportPost', {id: this.post.id, reportReason: this.reportReason})
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          this.$store.dispatch('addHiddenPost', this.post);
          success(this, {message: 'Reported the post.'});
          this.close();
        } else {
          failure(this, {message: 'Error reporting the post. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error reporting the post. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
