
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class OpenLink extends Vue {
  openLink(link: string) {
    if (!link) return;
    if (this.$route.fullPath === link) return;
    this.$router.push(link);
  }
}
