























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import Profile from '@/models/user/Profile';

@Component
export default class AdminEditProfile extends Vue {
  @Prop({required: true}) isActive!: boolean;
  @Prop({required: true}) username!: string;

  banner = '';
  blurb = '';
  firstName = '';
  icon = '';
  isLoading = false;
  isModalActive = false;
  lastName = '';
  profile: Profile|null = null;

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
    if (newVal) this.getData();
  }

  created() {
    this.isModalActive = this.isActive;
    this.getData();
  }

  close(isSuccess: boolean = false) {
    this.$emit('close', isSuccess);
  }

  getData() {
    if (!this.username) return;

    this.isLoading = true;

    this.$store.dispatch('getProfile', this.username)
      .then((profile: Profile) => {
        if (profile) {
          this.profile = profile;
          this.resetData();
        } else failure(this, {message: 'Error fetching the profile. Please try again later.'});
      })
      .catch(() => failure(this, {message: 'Error fetching the profile. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  removeBanner() {
    this.banner = '';
  }

  removeIcon() {
    this.icon = '';
  }

  resetData() {
    if (!this.profile) return;

    this.banner = this.profile.banner;
    this.blurb = this.profile.blurb;
    this.firstName = this.profile.firstName;
    this.icon = this.profile.profileIcon;
    this.lastName = this.profile.lastName;
  }

  updateProfile() {
    if (!this.profile) return;

    this.isLoading = true;

    const updatedProfile = new Profile({
      addShadow: this.profile.addShadow,
      banner: this.banner,
      blurb: this.blurb,
      cannotRemove: this.profile.cannotRemove,
      firstName: this.firstName,
      groupImageViewOption: this.profile.groupImageViewOption,
      iconLocation: this.profile.iconLocation,
      imageViewOption: this.profile.imageViewOption,
      isOfficial: this.profile.isOfficial,
      isVerified: this.profile.isVerified,
      lastName: this.lastName,
      listeners: this.profile.listeners,
      listeningTo: this.profile.listeningTo,
      profileIcon: this.icon,
      username: this.username,
    });

    this.$store.dispatch('adminEditProfile', updatedProfile)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: 'Profile updated!'});
          this.close(true);
        } else {
          failure(this, {message: 'Error updating the profile. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the profile. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
