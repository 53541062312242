






































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import DeletedHeader from '@/components/shout/DeletedHeader.vue';
import EditedHeader from '@/components/shout/EditedHeader.vue';
// eslint-disable-next-line no-unused-vars
import Post from '@/models/shout/Post';
import ReportedHeader from '@/components/shout/ReportedHeader.vue';
import ShoutHeader from '@/components/shout/ShoutHeader.vue';
import ShoutImage from '@/components/shout/ShoutImage.vue';
import ShoutMenu from '@/components/shout/ShoutMenu.vue';
import ShoutMessage from '@/components/shout/ShoutMessage.vue';
import ShoutStats from '@/components/shout/ShoutStats.vue';

@Component({
  components: {
    DeletedHeader,
    EditedHeader,
    ReportedHeader,
    ShoutHeader,
    ShoutImage,
    ShoutMenu,
    ShoutMessage,
    ShoutStats,
  },
})
export default class Shout extends Vue {
  @Prop({default: false}) addedListen!: boolean;
  @Prop({default: 0}) echoes!: number;
  @Prop({default: true}) hasBox!: boolean;
  @Prop({default: true}) hasHover!: boolean;
  @Prop({default: true}) hasMenu!: boolean;
  @Prop({default: true}) hasStats!: boolean;
  @Prop({default: false}) isHidden!: boolean;
  @Prop({default: 0}) listens!: number;
  @Prop({default: 0}) newEchoes!: number;
  @Prop({required: true}) shout!: Post;

  added = false;
  hideShout = false;
  iconToUse = '';
  message = '';
  totalEchoes = 0;
  totalListens = 0;

  @Watch('isHidden')
  isHiddenChanged(newVal: boolean) {
    this.hideShout = newVal;
  }

  @Watch('shout')
  shoutChanged(newVal: Post) {
    if (!newVal) return;
    this.message = newVal.message;
  }

  created() {
    this.added = this.addedListen;
    this.hideShout = this.isHidden;
    this.iconToUse = this.shout.profileIcon;
    this.message = this.shout.message;
    this.totalEchoes = this.echoes;
    this.totalListens = this.listens;
    this.checkForReportedReason();
  }

  checkForReportedReason() {
    if (this.shout.reportReason) {
      this.message = 'REPORTED AND REMOVED';
    }
  }

  clickIt(e: any) {
    try {
      let path: string = '';
      if (e.path) {
        path = e.path[0].className.split(' ')[0];
      } else if (e.target) {
        path = e.target.className.split(' ')[0];
      }
      if (['s-shout-header', 'shout-message', 'column', 's-message', 'reason-text',].includes(path)) {
        this.$router.push(`/shout/${this.shout.id}`);
      }
    } catch {
      // Silently fail... don't do anything.
    }
  }

  onError() {
    this.iconToUse = require('@/assets/anonymous_user.png');
  }

  showOriginalPost(show: boolean) {
    if (show && this.shout.originalPost) {
      this.message = this.shout.originalPost;
    } else {
      this.message = this.shout.message;
    }
    this.checkForReportedReason();
  }

  updateListen() {
    this.added = this.added ? false : true;
    this.totalListens += this.added ? 1 : -1;
  }

  willHide(isHide: boolean) {
    this.hideShout = isHide;
  }
}
