









import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class TopScroll extends Vue {
	isVisible = false;

	created() {
		window.addEventListener('scroll', () => {
			if (window.scrollY < 1000) {
				this.isVisible = false;
			} else {
				this.isVisible = true;
			}
		});
	}

	destroyed() {
		window.addEventListener('scroll', () => {});
	}

	moveToTop() {
		window.scrollTo(0, 0);
	}
}
