




























import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import OpenLink from '@/mixins/OpenLink.vue';

@Component
export default class NoResults extends OpenLink {
  @Prop({default: false}) isListeningTo!: boolean;
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({required: true}) title!: string;
}
