


























































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
// eslint-disable-next-line no-unused-vars
import Post from '@/models/shout/Post';
import Shout from '@/components/shout/Shout.vue';
import TagPicker from '@/components/ui/TagPicker.vue';
// eslint-disable-next-line no-unused-vars
import User from '@/mixins/User.vue';
import { failure, success } from '@/utility/messages';

@Component({
	components: {
		Shout,
    TagPicker,
	},
})
export default class CreateEcho extends User {
	fullPost: FullPost|null = null;
  isLoading = false;
	isMessageTouched = false;
	isModalActive = false;
  isTagModalActive = false;
	message = '';

	get isEchoModalActive() {
		return this.$store.getters['isEchoModalActive'];
	}

	get isEchoValid() {
		return (this.isMessageTouched && this.message && this.message.length > 0 && this.message.length <= 500)
			|| !this.isMessageTouched;
	}

	get username() {
		return this.fullPost && this.fullPost.post && this.fullPost.post.username ? this.fullPost.post.username : '';
	}

	@Watch('isEchoModalActive')
	modalActiveChanged(newVal: any) {
		this.isModalActive = newVal.isOpen;
		this.fullPost = newVal.post;
		this.$store.dispatch('setGlobalLoading', false);
	}

	close() {
		this.isMessageTouched = false;
		this.message = '';
		this.$store.dispatch('closeEchoModal');
	}

	echo() {
		this.isMessageTouched = true;
		if (!this.isEchoValid || !this.fullPost || !this.fullPost.post ||!this.user) return;

		this.isLoading = true;

		const generationZeroId = this.fullPost.post.generationZeroId;
		const parentId = this.fullPost.post.id;
		const parentUsername = this.fullPost.post.username;
		const echo = new Post({
		generationZeroId,
		message: this.message,
		parentId,
		});
 
		this.$store.dispatch('createNewEcho', {echo, parentUsername})
			.then((post: FullPost) => {
				if (post) {
				this.$store.dispatch('addRecentPost', post);
				success(this, {message: 'Echo created!'});
				this.close();
				} else {
				failure(this, {message: 'Error adding an Echo. Please try again later.'});
				}
			})
			.catch(() => failure(this, {message: 'Error adding an Echo. Please try again later.'}))
			.finally(() => this.isLoading = false);
	}

  tagSelected(tag: string) {
    if (!tag) return;
    this.message = `${this.message.trim()} ${tag}`;
  }
}
