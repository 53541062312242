import FullPost from '@/models/shout/FullPost';

export default class MyEchoNotifications {
  fullPost: FullPost|null = null;
  notifications = 0;

  constructor({fullPost, notifications}: any) {
    this.fullPost = fullPost ? fullPost : null;
    this.notifications = notifications != null ? notifications : 0;
  }
}
