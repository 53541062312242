














































































import { Component } from 'vue-property-decorator';

import OpenLink from '@/mixins/OpenLink.vue';

@Component
export default class TermsOfUse extends OpenLink {
  
}
