







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';

@Component
export default class ForgotForm extends Vue {
  @Prop({default: ''}) username!: string;

  code = '';
  isCodeServerError = false;
  isCodeTouched = false;
  isLoading = false;
  isPasswordTouched = false;
  isPasswordServerError = false;
  password = '';
  sentEmail = false;
  user = '';

  get info() {
    return this.sentEmail ? 'Email sent. Enter your verification code below.' : 'Enter your username below and an email will be sent to the address.';
  }

  get isCodeValid() {
    return (this.code && this.code.length === 6) || !this.isCodeTouched;
  }

  get isPasswordValid() {
    return (this.password && this.password.length >= 8) || !this.isPasswordTouched;
  }

  created() {
    this.user = this.username;
  }

  close() {
    this.reset();
    this.$emit('close', true);
  }

  reset() {
    this.code = '';
    this.isCodeServerError = false;
    this.isCodeTouched = false;
    this.isPasswordServerError = false;
    this.isPasswordTouched = false;
    this.password = '';
    this.sentEmail = false;
  }

  sendEmail() {
    if (!this.user || this.user.trim().length <= 0) return;

    this.isLoading = true;

    this.$store.dispatch('sendForgotEmail', this.user)
      .then((res: any) => {
        if (res && res.CodeDeliveryDetails && res.CodeDeliveryDetails.Destination) {
          success(this, {message: `Successfully sent the email to ${res.CodeDeliveryDetails.Destination}`});
          this.sentEmail = true;
        } else {
          failure(this, {message: 'Error sending the email. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error sending the email. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  validateNewPassword() {
    this.isCodeTouched = true;
    this.isPasswordTouched = true;

    if (!this.isCodeValid || !this.isPasswordValid) return;

    this.isCodeServerError = false;
    this.isPasswordServerError = false;

    this.isLoading = true;

    this.$store.dispatch('confirmForgotCode', {code: this.code, password: this.password, username: this.user})
      .then((res: any) => {
        if (res && res.code) {
          if (res.code === 'InvalidPasswordException') {
            this.isPasswordServerError = true;
          } else if (res.code === 'CodeMismatchException') {
            this.isCodeServerError = true;
          }
        } else {
          success(this, {message: 'Successfully changed your password.'});
          this.close();
        }
      })
      .catch(() => failure(this, {message: 'Error changing the password or the code was invalid. Please try again.'}))
      .finally(() => this.isLoading = false);
  }
}
