import Contact from '@/models/admin/Contact';
import ErrorCatch from '@/models/admin/ErrorCatch';
import Group from '@/models/group/Group';
import Post from '@/models/shout/Post';
import Profile from '@/models/user/Profile';
import ReportedGroup from '@/models/admin/ReportedGroup';
import ReportedNumbers from '@/models/admin/ReportedNumbers';
import ReportedPost from '@/models/admin/ReportedPost';
import ReportedProfile from '@/models/admin/ReportedProfile';
import ReportedUser from '@/models/admin/ReportedUser';

const Admin = {
  getters: {

  },
  actions: {
    adminDeleteContact(context: any, id: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAdminDeleteContact', id)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    adminDeleteError(context: any, id: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAdminDeleteError', id)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    adminEditGroup(context: any, updatedGroup: Group) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAdminEditGroup', updatedGroup)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    adminEditProfile(context: any, updatedProfile: Profile) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAdminEditProfile', updatedProfile)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    adminGetReportedNumbers(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAdminGetReportedNumbers')
            .then((reportedNums: ReportedNumbers) => resolve(reportedNums));
        } catch {
          reject(null);
        }
      });
    },
    adminGetReportedUser(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAdminGetReportedUser', username)
            .then((reportedUser: ReportedUser) => resolve(reportedUser));
        } catch {
          reject(null);
        }
      });
    },
    adminReportPost(context: any, { postId, reportReason }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetPost', postId)
            .then((post: Post) => {
              if (post) {
                const updatedPost = {
                  editReason: post.editReason,
                  exists: post.exists,
                  deleteReason: post.deleteReason,
                  generationZeroId: post.generationZeroId,
                  groupId: post.groupId,
                  id: post.id,
                  isHidden: post.isHidden,
                  isOfficial: post.isOfficial,
                  isVerified: post.isVerified,
                  image: post.image,
                  message: post.message,
                  originalPost: post.originalPost,
                  parentId: post.parentId,
                  profileIcon: post.profileIcon,
                  reportReason: reportReason,
                  type: post.type,
                  username: post.username.toLowerCase(),
                };
                context.dispatch('serverUpdatePost', updatedPost)
                  .then((post: Post) => resolve(post))
                  .catch(() => reject(null));
              } else {
                reject(null);
              }
            });
        } catch {
          reject(null);
        }
      });
    },
    adminReportUser(context: any, { username, groupId, postId, profileId }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAdminReportUser', {username, groupId, postId, profileId})
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    getAdminContacts(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAdminContacts')
            .then((contacts: Array<Contact>) => resolve(contacts));
        } catch {
          reject([]);
        }
      });
    },
    getAdminErrors(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAdminErrors')
            .then((errors: Array<ErrorCatch>) => resolve(errors));
        } catch {
          reject([]);
        }
      });
    },
    getAdminReportedGroups(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAdminReportedGroups')
            .then((groups: Array<ReportedGroup>) => resolve(groups));
        } catch {
          reject([]);
        }
      });
    },
    getAdminReportedPosts(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAdminReportedPosts')
            .then((posts: Array<ReportedPost>) => resolve(posts));
        } catch {
          reject([]);
        }
      });
    },
    getAdminReportedProfiles(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAdminReportedProfiles')
            .then((profiles: Array<ReportedProfile>) => resolve(profiles));
        } catch {
          reject([]);
        }
      });
    },
    getAdminReportedUsers(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAdminReportedUsers')
            .then((users: Array<ReportedUser>) => resolve(users));
        } catch {
          reject([]);
        }
      });
    },
    updateAdminContact(context: any, updatedContact: Contact) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateAdminContact', updatedContact)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    updateAdminError(context: any, updatedError: ErrorCatch) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateAdminError', updatedError)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    updateAdminReportedGroup(context: any, updatedGroup: ReportedGroup) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateAdminReportedGroup', updatedGroup)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    updateAdminReportedPost(context: any, updatedPost: ReportedPost) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateAdminReportedPost', updatedPost)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    updateAdminReportedProfile(context: any, updatedProfile: ReportedProfile) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateAdminReportedProfile', updatedProfile)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    updateAdminReportedUser(context: any, updatedUser: ReportedUser) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateAdminReportedUser', updatedUser)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
  },
  mutations: {

  },
  state: {

  },
};

export default Admin;
