















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import ReportedProfile from '@/models/admin/ReportedProfile';
import ReportedProfileEdit from '@/components/admin/ReportedProfileEdit.vue';

@Component({
  components: {
    ReportedProfileEdit,
  },
})
export default class ReportedProfiles extends Vue {
  @Prop({required: true}) reload!: boolean;

  data: Array<ReportedProfile> = [];
  defaultSortDirection = 'asc';
  dontOpen = false;
  filteredData: Array<ReportedProfile> = [];
  isModalActive = false;
  record: ReportedProfile|null = null;
  selected: any = null;
  viewResolved = 'unresolved';

  get size() {
    return this.$store.getters['isLargeMode'] ? 'is-normal' : 'is-small';
  }

  @Watch('reload')
  reloadChanged(newVal: boolean) {
    if (newVal) this.getData();
  }

  @Watch('selected')
  selectedChanged(newVal: ReportedProfile) {
    if (this.dontOpen) {
      this.dontOpen = false;
      return;
    } else {
      this.record = newVal;
      this.isModalActive = true;
    }
  }

  @Watch('viewResolved')
  viewResolvedChanged(newVal: string) {
    if (!newVal) return;

    this.filterData();
  }

  close(isSuccess: boolean) {
    this.record = null;
    this.isModalActive = false;

    if (isSuccess) this.getData();
  }

  filterData() {
    this.filteredData = [...this.data.filter((profile) => this.viewResolved === 'resolved' ? profile.resolved : !profile.resolved)];
  }

  getData() {
    this.$store.dispatch('getAdminReportedProfiles')
      .then((profiles: Array<ReportedProfile>) => {
        this.data = profiles;
        this.filterData();
      })
      .catch(() => failure(this, {message: 'Error retrieving the profiles. Please try again later.'}))
      .finally(() => this.$emit('done-loading', true));
  }

  openProfile(username: string) {
    this.dontOpen = true;
    if (!username) return;

    this.$router.push(`/profile/${username}`);
  }
}
