









































import Component from 'vue-class-component';

import NotificationIcon from '@/components/notification/NotificationIcon.vue';
import NotificationSubs from '@/mixins/NotificationSubs.vue';
import SmallMenu from '@/components/menu/SmallMenu.vue';
import SmallSearchBar from '@/components/search/SmallSearchBar.vue';

@Component({
  components: {
    NotificationIcon,
    SmallMenu,
    SmallSearchBar,
  }
})
export default class NotificationBar extends NotificationSubs {
  openEchoes() {
    if (this.$route.fullPath === '/echoes') return;
    this.$router.push('/echoes');
  }

  openGroups() {
    if (this.$route.fullPath === '/groups') return;
    this.$router.push('/groups');
  }

  openHashtags() {
    if (this.$route.fullPath === '/hashtags') return;
    this.$router.push('/hashtags');
  }

  openMentions() {
    if (this.$route.fullPath === '/mentions') return;
    this.$router.push('/mentions');
  }
}
