import { v4 as uuidv4 } from 'uuid';

export default class Blocks {
  blackList: Array<string> = [];
  id: string = '';
  username: string = '';

  constructor({blackList, id, username}: any) {
    this.blackList = blackList ? blackList : [];
    this.id = id ? id : uuidv4;
    this.username = username ? username.toLowerCase() : '';
  }
}
