











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class ListenButton extends Vue {
  @Prop({required: true}) isFollowing!: boolean;

  follows = false;

  get listeningText() {
    return this.follows ? 'Listening' : 'Listen To...';
  }

  get listeningType() {
    return this.follows ? 'is-success is-light' : 'is-info is-light';
  }

  @Watch('isFollowing')
  isFollowingChanged(newVal: boolean) {
    this.follows = newVal;
  }

  created() {
    this.follows = this.isFollowing;
  }

  listen() {
    this.follows = !this.follows;
    this.$emit('is-following', this.follows);
  }
}
