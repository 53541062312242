import { render, staticRenderFns } from "./TrendingSkeleton.vue?vue&type=template&id=12bc712a&scoped=true&"
import script from "./TrendingSkeleton.vue?vue&type=script&lang=ts&"
export * from "./TrendingSkeleton.vue?vue&type=script&lang=ts&"
import style0 from "./TrendingSkeleton.vue?vue&type=style&index=0&id=12bc712a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12bc712a",
  null
  
)

export default component.exports