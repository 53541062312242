





























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { API } from 'aws-amplify';
import * as subscriptions from '@/graphql/subscriptions';

import LargeMenu from '@/components/menu/LargeMenu.vue';
import LargeSearchBar from '@/components/search/LargeSearchBar.vue';
import Listen from '@/models/user/Listen';
import ListenerQuickNums from '@/components/notification/ListenerQuickNums.vue';
import NotificationBar from '@/components/notification/NotificationBar.vue';
import ShoutBar from '@/components/ui/ShoutBar.vue';
// eslint-disable-next-line no-unused-vars
import User from '@/models/user/User';

@Component({
  components: {
    LargeMenu,
    LargeSearchBar,
    ListenerQuickNums,
    NotificationBar,
    ShoutBar,
  },
})
export default class TopBar extends Vue {
  @Prop({required: true}) user!: User;

  listenersNum = 0;
  listenersSub: any = null;
  listeningToNum = 0;

  get isMobile() {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'];
  }

  @Watch('user')
  userChanged() {
    this.getData();
  }

  destroy() {
    if (!this.listenersSub) return;
    this.listenersSub.unsubscribe();
  }

  getData() {
    if (!this.user || this.listenersSub) return;

    // Get a subscription to the listen data for this user.
    const lower = this.user.username.toLowerCase();
    this.listenersSub = (API.graphql({
      query: subscriptions.onListenUpdateById,
      variables: {id: lower},
    }) as any)
      .subscribe({
        next: (res: any) => this.handleUpdatedData(res)
      });

    // Get the actual data...
    this.$store.dispatch('getListenData', lower)
      .then((listen: Listen) => {
        if (listen) {
          this.handleUpdatedData({value: {data: {onListenUpdateById: listen}}});
        }
      });
  }

  handleUpdatedData(data: any) {
    if (!data || !data.value || !data.value.data || !data.value.data.onListenUpdateById) return;

     try {
       const listenData = new Listen({...data.value.data.onListenUpdateById});
       this.listenersNum = listenData.listenersNum;
       this.listeningToNum = listenData.listeningToNum;
     } catch {
       this.listenersNum = 0;
       this.listeningToNum = 0;
     }
  }
}
