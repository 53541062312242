
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Prop, Watch } from 'vue-property-decorator';

import { API } from 'aws-amplify';
import * as subscriptions from '@/graphql/subscriptions';

// eslint-disable-next-line no-unused-vars
import MyNotifications from '@/models/user/MyNotifications';

@Component
export default class NotificationSubs extends Vue {
  @Prop({default: null}) user!: any;

  echoesRemovedSub: any = null;
  echoesSub: any = null;
  groupNames: Array<string> = [];
  groupSubs: Array<any> = [];
  groupRemoveSub: any = null;
  hashtags: Array<string> = []; // Keeps track of the hashtags to get a proper hashtag count
  hashtagsUpdatedSub: any = null; // Hashtags aren't removed, just updated to "0"
  mentionsRemovedSub: any = null;
  mentionsSub: any = null;
  newEchoesNum = 0;
  newGroupsNum = 0;
  newHashtagsNum = 0;
  newMentionsNum = 0;
  notificationNames: Array<string> = [];

  get isLoggedIn() {
    return this.user != null;
  }

  @Watch('addedNewGroup')
  addedNewGroupChanged(newVal: any) {
    if (newVal && newVal.isAdded) {
      this.createGroupSub(newVal);
      setTimeout(() => this.$store.dispatch('addedNewGroup', {isAdded: false, groupId: '', name: ''}), 0.5);
    }
  }

  @Watch('user')
  userChanged() {
    this.createSubs();
  }

  mounted() {
    this.createSubs();
  }

  destroy() {
    if (this.echoesRemovedSub) this.echoesRemovedSub.unsubscribe();
    if (this.echoesSub) this.echoesSub.unsubscribe();
    if (this.hashtagsUpdatedSub) this.hashtagsUpdatedSub.unsubscribe();
    if (this.mentionsRemovedSub) this.mentionsRemovedSub.unsubscribe();
    if (this.mentionsSub) this.mentionsSub.unsubscribe();
    if (this.groupRemoveSub) this.groupRemoveSub.unsubscribe();
    if (this.groupSubs && this.groupSubs.length > 0) {
      this.groupSubs.forEach((grp) => grp.unsubscribe());
    }
    this.groupSubs = [];
  }

  checkForUpdatedData(data: any) {
    if (!data) return false;

    const myLgs = this.$store.getters['myGroups'];
    const lg = myLgs.find((lg) => lg.group.id === data.id);
    const group = lg ? lg.group : null;

    if (!group) return false;

    // We really only want to return true if a new post is added or
    // if this is the owner and a new request has been added.
    if (data.owner === this.user.username && data.requestedList.length > group.requestedList.length) {
      return true;
    } else if (data.posts !== group.posts) {
      return true;
    }

    return false;
  }

  createSubs() {
    if (!this.isLoggedIn || this.mentionsSub) return;

    // Get the actual notification data first...
    this.$store.dispatch('getMyNotifications')
      .then((notifications: MyNotifications) => {
        if (notifications) {
          this.newMentionsNum = notifications.mentionNums;
          this.newEchoesNum = notifications.echoNums;
          this.newGroupsNum = notifications.notificationNames.length;
          this.groupNames = notifications.groupNames;
          this.notificationNames = notifications.notificationNames;

          this.$store.dispatch('setNewGroupNotificationNames', [...this.groupNames]);

          notifications.hashtags.forEach((tag: any) => {
            const { hashtag, newNotifications } = tag;
            if (hashtag && newNotifications != null && newNotifications > 0) {
              this.hashtags.splice(0, 0, hashtag);
              this.newHashtagsNum++;
            }
          });

          // We need to make the group subs here...
          this.groupNames.forEach((name: string) => {
            this.groupSubs.push(
              (API.graphql({
                query: subscriptions.onUpdateGroup,
                variables: {name},
              }) as any)
                .subscribe({
                  next: (res: any) => this.handleUpdatedData(res)
                })
            );
          });
        }
      });

    // Make the subscriptions to the notification data...
    this.mentionsSub = (API.graphql({
      query: subscriptions.onCreateMentionNotifications,
      variables: {username: this.user.username},
    }) as any)
      .subscribe({
        next: (res: any) => this.handleUpdatedData(res)
      });

    this.mentionsRemovedSub = (API.graphql({
      query: subscriptions.onDeleteMentionNotifications,
      variables: {username: this.user.username},
    }) as any)
      .subscribe({
        next: () => this.newMentionsNum = 0
      });

    this.echoesSub = (API.graphql({
      query: subscriptions.onCreateEchoNotifications,
      variables: {username: this.user.username},
    }) as any)
      .subscribe({
        next: (res: any) => this.handleUpdatedData(res)
      });

    this.echoesRemovedSub = (API.graphql({
      query: subscriptions.onDeleteEchoNotifications,
      variables: {username: this.user.username},
    }) as any)
      .subscribe({
        next: () => this.newEchoesNum -= 1
      });

    this.hashtagsUpdatedSub = (API.graphql({
      query: subscriptions.onUpdateHashtagNotifications,
      variables: {username: this.user.username},
    }) as any)
      .subscribe({
        next: (res: any) => this.handleUpdatedData(res)
      });

    this.groupRemoveSub = (API.graphql({
      query: subscriptions.onUpdateGroupSubscriptions,
      variables: {username: this.user.username},
    }) as any)
      .subscribe({
        next: (res: any) => this.handleUpdatedData(res)
      });
  }

  createGroupSub({ name }) {
    if (!name) return;

    this.groupSubs.push(
      (API.graphql({
        query: subscriptions.onUpdateGroup,
        variables: {name},
      }) as any)
        .subscribe({
          next: (res: any) => this.handleUpdatedData(res)
        })
    );
    this.$store.dispatch('setNewGroupNotificationNames', [name, ...this.$store.getters['newGroupNotificationNames']]);
  }

  handleUpdatedData(data: any) {
    if (!data || !data.value || !data.value.data) return;
    const { 
      onCreateMentionNotifications,
      onCreateEchoNotifications,
      onUpdateGroup,
      onUpdateGroupSubscriptions,
      onUpdateHashtagNotifications,
    } = data.value.data;

    if (onCreateMentionNotifications) this.newMentionsNum++;
    else if (onCreateEchoNotifications) this.newEchoesNum++;
    else if (onUpdateHashtagNotifications) {
      const { hashtag } = onUpdateHashtagNotifications;
      const { newNotifications } = onUpdateHashtagNotifications;

      if (hashtag) {
        const index = this.hashtags.findIndex((tag) => tag === hashtag);
        if (index > -1) {
          if (newNotifications != null && newNotifications === 0) {
            this.newHashtagsNum--;
            this.hashtags.splice(index, 1);
          }
        } else if (newNotifications != null && newNotifications > 0) {
          this.newHashtagsNum++;
          this.hashtags.splice(0, 0, hashtag);
        }
      }
    } else if (onUpdateGroup) {
      const { name } = onUpdateGroup;
      const shouldUpdate = this.checkForUpdatedData(onUpdateGroup);

      if (!shouldUpdate) return;

      if (!this.notificationNames.some((n: string) => n === name)) {
        this.notificationNames.push(name);
      }
      this.newGroupsNum++;
      this.$store.dispatch('setNewGroupNotificationNames', this.notificationNames);
      this.$store.dispatch('getMyGroups');
    } else if (onUpdateGroupSubscriptions) {
      const group = onUpdateGroupSubscriptions.group.items[0];
      const { name } = group;
      const index = this.notificationNames.findIndex((n) => n === name);

      if (index > -1) {
        this.newGroupsNum--;
        if (this.newGroupsNum < 0) this.newGroupsNum = 0;
        this.notificationNames.splice(index, 1);
        this.$store.dispatch('setNewGroupNotificationNames', this.notificationNames);
      }
    }
  }

  openLink(link: string) {
    if (!link) return;
    if (this.$route.fullPath === link) return;
    this.$router.push(link);
  }
}
