


































import Component from 'vue-class-component';

import { failure, success } from '@/utility/messages';
import Issues from '@/enums/contact/issues';
import UserMixin from '@/mixins/User.vue';

@Component
export default class Contact extends UserMixin {
  isLoading = false;
  isMessageTouched = false;
  isTopicTouched = false;
  message = '';
  topic = '';

  get isBugSelected() {
    return this.topic && this.topic === Issues.BUG;
  }

  get isMessageValid() {
    return (this.isMessageTouched && this.message && this.message.length > 0 && this.message.length <= 500) || !this.isMessageTouched;
  }

  get issues() {
    return Object.values(Issues).map((val: string) => val);
  }

  get isTopicValid() {
    return (this.isTopicTouched && this.topic) || !this.isTopicTouched;
  }

  reset() {
    this.isMessageTouched = false;
    this.isTopicTouched = false;
    this.message = '';
    this.topic = '';
  }

  submit() {
    this.isMessageTouched = true;
    this.isTopicTouched = true;
    if (!this.isMessageValid || !this.isTopicValid) return;

    this.isLoading = true;

    this.$store.dispatch('sendContact', {message: this.message, topic: this.topic})
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: 'Successfully sent!'});
          this.reset();
        } else {
          failure(this, {message: 'Error sending the message. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error sending the message. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
