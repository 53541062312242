









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import CategoryTab from '@/components/search/CategoryTab.vue';
import SearchTab from '@/components/search/SearchTab.vue';
import Trending from '@/components/search/Trending.vue';

@Component({
  components: {
    CategoryTab,
    SearchTab,
    Trending,
  },
})
export default class CategoryTabs extends Vue {
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({default: 'is-medium'}) size!: string;

  reloadName = 'Recent';
  storeCallPopular = 'getMyPopularPosts';
  storeCallRecent = 'getMyRecentPosts';
  viewWhatPosts = 'me';

  @Watch('isLoggedIn')
  isLoggedInChanged(newVal: boolean) {
    if (!newVal) return;
    this.viewWhatPosts = 'me';
  }

  @Watch('viewWhatPosts')
  viewWhatPostsChanged(newVal: string) {
    if (!newVal) return;
    this.storeCallPopular = newVal === 'all' ? 'getPopularPosts' : 'getMyPopularPosts';
    this.storeCallRecent = newVal === 'all' ? 'getRecentPosts' : 'getMyRecentPosts';
    
    // This will reload any needed tab.
    const name = this.reloadName;
    this.reloadName = 'emptytab';
    setTimeout(() => this.reloadName = name, 50);
  }

  mounted() {
    this.viewWhatPosts = this.isLoggedIn ? 'me' : 'all';
  }

  tabChanged(name: string) {
    this.reloadName = name;
  }
}
