


























































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
import ImagePicker from '@/components/image/ImagePicker.vue';
import Post from '@/models/shout/Post';
import TagPicker from '@/components/ui/TagPicker.vue';
import User from '@/mixins/User.vue';

@Component({
  components: {
    ImagePicker,
    TagPicker,
  },
})
export default class ShoutBar extends User {
  @Prop({required: true}) imageSrc!: string;

  public file: File | null = null;
  public iconToUse = '';
  public isLoading = false;
  public isImageModalActive = false;
  public isTagModalActive = false;
  public largeInput = false;
  public shoutMessage = '';

  get size() {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'] ? 'is-small' : 'is-normal';
  }

  created() {
    this.iconToUse = this.imageSrc;
  }

  public addImage() {
    if (!this.file) return;

    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.hideShowImage(false, event.target.result);
    });
    reader.readAsDataURL(this.file);
    this.closeModal();
  }

  public changeToLargeInput() {
    this.largeInput = true;
    const ref: any = this.$refs['largeInput'];
    ref.focus();
  }

  public closeModal() {
    this.isImageModalActive = false;
  }

  public closeInput() {
    this.file = null;
    this.largeInput = false;
    this.shoutMessage = '';
    this.hideShowImage(true);
  }

  public editProfile() {
    if (this.$route.fullPath !== '/profile') {
      this.$router.push('/profile');
    }
  }

  public hideShowImage(isHide: boolean, src: string = '') {
    const elementContainer: any = document.getElementById('addImageToPostContainer');
    const element: any = document.getElementById('addImageToPost');

    if (isHide) {
      element.src = '';
      elementContainer.style.display = 'none';
      this.file = null;
    } else {
      element.src = src;
      elementContainer.style.display = 'block';
    }
  }

  public incomingFile(obj: any) {
    this.file = obj.file ?? null;
  }

  public onError() {
    this.iconToUse = require('@/assets/anonymous_user.png');
  }

  public removeImage() {
    this.hideShowImage(true);
  }

  public shout() {
    if (!this.shoutMessage || !this.user) return;

    this.isLoading = true;

    this.$store.dispatch('createNewShout', new Post({
      image: this.file ? this.file : '',
      message: this.shoutMessage,
    }))
    .then((post: FullPost) => {
      if (post) {
        this.$store.dispatch('addRecentPost', post);
        this.closeInput();
      } else failure(this, {message: 'Error sending your Shout. Please try again later.'});
    })
    .catch(() => failure(this, {message: 'Error sending your Shout. Please try again later.'}))
    .finally(() => this.isLoading = false);
  }

  public tagSelected(tag: string) {
    if (!tag) return;
    this.shoutMessage = `${this.shoutMessage.trim()} ${tag}`;
  }
}
