












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class GroupStats extends Vue {
  @Prop({default: false}) hasNewListeners!: boolean;
  @Prop({default: false}) hasNewPosts!: boolean;
  @Prop({required: true}) listeners!: number;
  @Prop({required: true}) posts!: number;

  get compactListeners() {
    return this.compactNumbers(this.listeners);
  }

  get compactPosts() {
    return this.compactNumbers(this.posts);
  }

  compactNumbers(num: number) {
    if (num <= 0) return '0';
    else if (num >= 1_000_000) return `${(num/1_000_000).toFixed(1)} M`;
    else if (num >= 100_000) return `${(num/1_000).toFixed(1)} K`;
    else return num.toString();
  }
}
