





































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
import ModelSearch from '@/models/search/Search';
import SearchContainer from '@/components/search/SearchContainer.vue';
import SearchResults from '@/components/search/SearchResults.vue';
import UserMixin from '@/mixins/User.vue';


@Component({
  components: {
    MediaSkeleton,
    SearchContainer,
    SearchResults,
  },
})
export default class Search extends UserMixin {
  isLoading = false;
  results: ModelSearch|null = null;
  searchOption: string = 'All';
  searchText: string = '';

  get hasAll() {
    return this.searchOption === 'All' && this.results;
  }

  get hasResults() {
    return this.results && ((this.results.hashtags && this.results.hashtags.length) ||
      (this.results.posts && this.results.posts.length) || (this.results.profiles && this.results.profiles.length));
  }

  get size(): string {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'] ? 'is-small' : 'is-normal';
  }

  @Watch('searchOption')
  searchOptionChanged() {
    this.search();
  }

  created() {
    // Check to see if we already have some results...
    const prevSearch = this.$store.getters['search'];

    if (prevSearch) {
      this.searchText = prevSearch.searchString;
      this.searchOption = prevSearch.searchOption ? prevSearch.searchOption : 'All';
      this.results = prevSearch;
    }
  }

  search() {
    if (!this.searchText) return;

    this.isLoading = true;
    this.results = null;

    const newSearch = new ModelSearch({searchOption: this.searchOption, searchString: this.searchText});

    this.$store.dispatch('search', newSearch)
      .then((results: ModelSearch) => this.results = results)
      .catch(() => failure(this, {message: 'Error retrieving search results. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
