





























































import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
// eslint-disable-next-line no-unused-vars
import Listening from '@/models/user/Listening';
import User from '@/mixins/User.vue';

@Component
export default class TagPicker extends User {
  @Prop({required: true}) isActive!: boolean;

  category: string = 'mentions';
  isModalActive: boolean = false;
  results: any = {
    groups: [],
    hashtags: [],
    mentions: [],
  };

  get filteredResults() {
    return this.category !== 'all' ? this.results[this.category] : [
      ...this.results.mentions,
      ...this.results.hashtags,
      ...this.results.groups,
    ];
  }

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    if (newVal) this.getData();
    this.isModalActive = newVal;
  }

  created() {
    this.isModalActive = this.isActive;
  }

  addTag(tag: string) {
    this.$emit('tag', tag);
    this.close();
  }

  close() {
    this.category = 'mentions';
    this.$emit('close', true);
  }

  getData() {
    if (!this.user) return;

    this.$store.dispatch('setGlobalLoading', true);

    const storeCall = ['getMyGroups', 'getHashtags', 'getMyListeningTo'];
    let callsComplete = 0;
    let failed = false;

    storeCall.forEach((call) => {
      this.$store.dispatch(call, this.user.username)
        .then((res: any) => {
          if (!res) {
            callsComplete++;
            return;
          }

          switch (call) {
            case storeCall[0]:
              this.results.groups = res.map((obj: Group) => `&${obj.preferredName}`).sort();
              break;
            case storeCall[1]:
              this.results.hashtags = res.hashtags.map((tag: string) => `#${tag}`).sort();
              break;
            case storeCall[2]:
              this.results.mentions = res.map((obj: Listening) => `@${obj.profile.username}`).sort();
              break;
            default: break;
          }

          callsComplete++;
        })
        .catch(() => {
          callsComplete++;
          failed = true;
        })
        .finally(() => {
          if (callsComplete >= storeCall.length) {
            this.$store.dispatch('setGlobalLoading', false);
            if (failed) {
              failure(this, {message: 'Error retrieving some tags.'});
            }
          }
        });
    });
  }
}
