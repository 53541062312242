import Post from '@/models/shout/Post';

export default class Echo extends Post {
  public associatedPost: Post|null = null;
  public isParentShout: boolean = false;
  public parentId: string = '';

  constructor({associatedPost, echoes, generationZeroId, id, isParentShout, listens, message, parentId, posted, profileIcon, username}: any) {
    super({echoes, generationZeroId, id, listens, message, posted, profileIcon, username});
    this.associatedPost = associatedPost ? associatedPost : null;
    this.isParentShout = isParentShout ? isParentShout : false;
    this.parentId = parentId ? parentId : '';
  }
}
