





























































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import ActionTaken from '@/enums/admin/actionTaken';
// eslint-disable-next-line no-unused-vars
import ReportedGroup from '@/models/admin/ReportedGroup';
// eslint-disable-next-line no-unused-vars
import ReportedPost from '@/models/admin/ReportedPost';
// eslint-disable-next-line no-unused-vars
import ReportedProfile from '@/models/admin/ReportedProfile';
// eslint-disable-next-line no-unused-vars
import ReportedUser from '@/models/admin/ReportedUser';

class ReportedInfo {
  notes = '';
  reason = '';
  type = '';

  constructor({notes, reason, type}: ReportedInfo) {
    this.notes = notes;
    this.reason = reason;
    this.type = type;
  }
}

@Component
export default class ReportedUserEdit extends Vue {
  @Prop({required: true}) isActive!: boolean;
  @Prop({required: true}) user!: ReportedUser;

  banUser = false;
  count = 0;
  edited: Array<ReportedInfo> = [];
  isLoading = false;
  isModalActive = false;
  notes = '';
  reported: Array<ReportedInfo> = [];
  reportedUser: ReportedUser|null = null;
  unresolved: Array<ReportedInfo> = [];
  username = '';

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
    if (newVal) this.getData();
  }

  created() {
    this.isModalActive = this.isActive;
    this.getData();
  }

  close(isSuccess: boolean = false) {
    this.banUser = false;
    this.count = 0;
    this.edited = [];
    this.notes = '';
    this.reported = [];
    this.unresolved = [];
    this.username = '';
    this.$emit('close', isSuccess);
  }

  getData() {
    if (!this.user) return;

    this.isLoading = true;

    this.$store.dispatch('adminGetReportedUser', this.user.username)
      .then((reportedUser: ReportedUser) => {
        if (reportedUser) {
          this.reportedUser = {
            ...this.user,
            ...reportedUser,
          };
          this.setData();
        } else failure(this, {message: 'Error retrieving the user.'});
      })
      .catch(() => failure(this, {message: 'Error retrieving the user.'}))
      .finally(() => this.isLoading = false);
  }

  setData() {
    if (!this.reportedUser) return;

    this.banUser = this.user.banned;
    this.count = this.user.count;
    this.notes = this.user.notes;
    this.username = this.reportedUser.username;

    const { reportGroup, reportPost, reportProfile } = this.reportedUser;

    const createNew = (obj: ReportedGroup|ReportedPost|ReportedProfile, type: string): ReportedInfo => {
      return new ReportedInfo({
        notes: obj ? obj.notes : '',
        reason: obj ? obj.reportReason : '',
        type,
      });
    };

    const cycleThroughArray = (array: Array<ReportedGroup|ReportedPost|ReportedProfile>, type: string) => {
      if (!array) return;
      array.forEach((arr) => {
        if (!arr.resolved) this.unresolved.push(createNew(arr, type));
        else if (arr.actionTaken === ActionTaken.EDITED) this.edited.push(createNew(arr, type));
        else if (arr.actionTaken === ActionTaken.REPORTED) this.reported.push(createNew(arr, type));
      });
    };

    cycleThroughArray(reportGroup, 'Group');
    cycleThroughArray(reportPost, 'Post');
    cycleThroughArray(reportProfile, 'Profile');
  }

  updateReportedUser() {
    this.isLoading = true;

    const updatedUser = {
      banned: this.banUser,
      id: this.user.id,
      count: this.user.count,
      notes: this.notes,
      username: this.user.username,
    };

    this.$store.dispatch('updateAdminReportedUser', updatedUser)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: 'User updated!'});
          this.close(true);
        } else {
          failure(this, {message: 'Error updating the user. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the user. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
