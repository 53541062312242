














































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import User from '@/models/user/User';

@Component
export default class ShoutMenu extends Vue {
  @Prop({default: true}) canHide!: boolean;
  @Prop({required: true}) id!: string;
  @Prop({required: true}) isDeleted!: boolean;
  @Prop({default: false}) isHidden!: boolean;
  @Prop({default: false}) isOfficial!: boolean;
  @Prop({required: true}) owner!: string;

  isHide = false;
  isLoading = false;

  get belongsToUser(): boolean {
    return this.user && this.user.preferredUsername === this.owner;
  }

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get hideOrShowTitle() {
    return this.isHide ? 'Show' : 'Hide';
  }

  get user(): User {
    return this.$store.getters['getUser'];
  }

  created() {
    this.isHide = this.isHidden;
  }

  block() {
    if (this.isOfficial) return;

    this.$buefy.dialog.confirm({
      title: 'Block User Account?',
      message: `Are you sure you want to block ${this.owner}? You will no longer see posts or mentions from this user.`,
      confirmText: 'Block User',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.blockUserCall(),
    });
  }

  blockUserCall() {
    if (this.isOfficial) return;

    this.isLoading = true;

    this.$store.dispatch('blockUser', this.owner)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          this.$store.dispatch('removeUserPosts', this.owner);
          success(this, {message: 'Account blocked!'});
        } else {
          failure(this, {message: 'Error blocking the user. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error blocking the user. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  deletePost() {
    this.$store.dispatch('openDeleteModal', this.id);
  }

  echo() {
    this.$store.dispatch('openEchoModal', this.id);
  }

  edit() {
    this.$store.dispatch('openEditModal', this.id);
  }

  hideOrShow() {
    if (this.isOfficial) return;

    this.isLoading = true;

    this.$store.dispatch('hideOrShowPost', this.id)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          this.isHide = !this.isHide;
          this.$emit('hide', this.isHide);
        } else {
          failure(this, {message: 'Error hiding the post. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error hiding the post. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  listen() {
    this.$store.dispatch('updateListen', this.id)
      .then((isSuccess: boolean) => isSuccess ? this.$emit('update-listen', true) : '')
      .catch(() => failure(this, {message: 'Error updating your listen preference.'}));
  }

  report() {
    if (this.isOfficial) return;
    
    this.$store.dispatch('openReportPostModal', this.id);
  }
}
