/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlockUser = /* GraphQL */ `
  mutation CreateBlockUser(
    $input: CreateBlockUserInput!
    $condition: ModelBlockUserConditionInput
  ) {
    createBlockUser(input: $input, condition: $condition) {
      blackList
      id
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBlockUser = /* GraphQL */ `
  mutation UpdateBlockUser(
    $input: UpdateBlockUserInput!
    $condition: ModelBlockUserConditionInput
  ) {
    updateBlockUser(input: $input, condition: $condition) {
      blackList
      id
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBlockUser = /* GraphQL */ `
  mutation DeleteBlockUser(
    $input: DeleteBlockUserInput!
    $condition: ModelBlockUserConditionInput
  ) {
    deleteBlockUser(input: $input, condition: $condition) {
      blackList
      id
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      message
      notes
      resolved
      topic
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      message
      notes
      resolved
      topic
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      message
      notes
      resolved
      topic
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createEchoNotifications = /* GraphQL */ `
  mutation CreateEchoNotifications(
    $input: CreateEchoNotificationsInput!
    $condition: ModelEchoNotificationsConditionInput
  ) {
    createEchoNotifications(input: $input, condition: $condition) {
      id
      newNotifications
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEchoNotifications = /* GraphQL */ `
  mutation UpdateEchoNotifications(
    $input: UpdateEchoNotificationsInput!
    $condition: ModelEchoNotificationsConditionInput
  ) {
    updateEchoNotifications(input: $input, condition: $condition) {
      id
      newNotifications
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEchoNotifications = /* GraphQL */ `
  mutation DeleteEchoNotifications(
    $input: DeleteEchoNotificationsInput!
    $condition: ModelEchoNotificationsConditionInput
  ) {
    deleteEchoNotifications(input: $input, condition: $condition) {
      id
      newNotifications
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createErrorCatch = /* GraphQL */ `
  mutation CreateErrorCatch(
    $input: CreateErrorCatchInput!
    $condition: ModelErrorCatchConditionInput
  ) {
    createErrorCatch(input: $input, condition: $condition) {
      createdAt
      error
      function
      id
      notes
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const updateErrorCatch = /* GraphQL */ `
  mutation UpdateErrorCatch(
    $input: UpdateErrorCatchInput!
    $condition: ModelErrorCatchConditionInput
  ) {
    updateErrorCatch(input: $input, condition: $condition) {
      createdAt
      error
      function
      id
      notes
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const deleteErrorCatch = /* GraphQL */ `
  mutation DeleteErrorCatch(
    $input: DeleteErrorCatchInput!
    $condition: ModelErrorCatchConditionInput
  ) {
    deleteErrorCatch(input: $input, condition: $condition) {
      createdAt
      error
      function
      id
      notes
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      banner
      categories
      createdBy
      description
      id
      isOfficial
      isOwnerOnly
      isVerified
      listeners
      locations
      name
      preferredName
      posts
      requestedList
      createdAt
      updatedAt
      owner
      postIds {
        items {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      banner
      categories
      createdBy
      description
      id
      isOfficial
      isOwnerOnly
      isVerified
      listeners
      locations
      name
      preferredName
      posts
      requestedList
      createdAt
      updatedAt
      owner
      postIds {
        items {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      banner
      categories
      createdBy
      description
      id
      isOfficial
      isOwnerOnly
      isVerified
      listeners
      locations
      name
      preferredName
      posts
      requestedList
      createdAt
      updatedAt
      owner
      postIds {
        items {
          createdAt
          editReason
          exists
          deleteReason
          generationZeroId
          groupId
          id
          isHidden
          isOfficial
          isVerified
          image
          message
          originalPost
          parentId
          profileIcon
          reportReason
          type
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createGroupSubscriptions = /* GraphQL */ `
  mutation CreateGroupSubscriptions(
    $input: CreateGroupSubscriptionsInput!
    $condition: ModelGroupSubscriptionsConditionInput
  ) {
    createGroupSubscriptions(input: $input, condition: $condition) {
      cannotRemove
      groupId
      id
      lastCheckedPostNumber
      status
      username
      createdAt
      updatedAt
      group {
        items {
          banner
          categories
          createdBy
          description
          id
          isOfficial
          isOwnerOnly
          isVerified
          listeners
          locations
          name
          preferredName
          posts
          requestedList
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateGroupSubscriptions = /* GraphQL */ `
  mutation UpdateGroupSubscriptions(
    $input: UpdateGroupSubscriptionsInput!
    $condition: ModelGroupSubscriptionsConditionInput
  ) {
    updateGroupSubscriptions(input: $input, condition: $condition) {
      cannotRemove
      groupId
      id
      lastCheckedPostNumber
      status
      username
      createdAt
      updatedAt
      group {
        items {
          banner
          categories
          createdBy
          description
          id
          isOfficial
          isOwnerOnly
          isVerified
          listeners
          locations
          name
          preferredName
          posts
          requestedList
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteGroupSubscriptions = /* GraphQL */ `
  mutation DeleteGroupSubscriptions(
    $input: DeleteGroupSubscriptionsInput!
    $condition: ModelGroupSubscriptionsConditionInput
  ) {
    deleteGroupSubscriptions(input: $input, condition: $condition) {
      cannotRemove
      groupId
      id
      lastCheckedPostNumber
      status
      username
      createdAt
      updatedAt
      group {
        items {
          banner
          categories
          createdBy
          description
          id
          isOfficial
          isOwnerOnly
          isVerified
          listeners
          locations
          name
          preferredName
          posts
          requestedList
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createHashtag = /* GraphQL */ `
  mutation CreateHashtag(
    $input: CreateHashtagInput!
    $condition: ModelHashtagConditionInput
  ) {
    createHashtag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      hashtagInfo {
        items {
          createdAt
          hashtagId
          id
          postId
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateHashtag = /* GraphQL */ `
  mutation UpdateHashtag(
    $input: UpdateHashtagInput!
    $condition: ModelHashtagConditionInput
  ) {
    updateHashtag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      hashtagInfo {
        items {
          createdAt
          hashtagId
          id
          postId
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteHashtag = /* GraphQL */ `
  mutation DeleteHashtag(
    $input: DeleteHashtagInput!
    $condition: ModelHashtagConditionInput
  ) {
    deleteHashtag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      hashtagInfo {
        items {
          createdAt
          hashtagId
          id
          postId
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createHashtagNotifications = /* GraphQL */ `
  mutation CreateHashtagNotifications(
    $input: CreateHashtagNotificationsInput!
    $condition: ModelHashtagNotificationsConditionInput
  ) {
    createHashtagNotifications(input: $input, condition: $condition) {
      hashtag
      id
      newNotifications
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateHashtagNotifications = /* GraphQL */ `
  mutation UpdateHashtagNotifications(
    $input: UpdateHashtagNotificationsInput!
    $condition: ModelHashtagNotificationsConditionInput
  ) {
    updateHashtagNotifications(input: $input, condition: $condition) {
      hashtag
      id
      newNotifications
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteHashtagNotifications = /* GraphQL */ `
  mutation DeleteHashtagNotifications(
    $input: DeleteHashtagNotificationsInput!
    $condition: ModelHashtagNotificationsConditionInput
  ) {
    deleteHashtagNotifications(input: $input, condition: $condition) {
      hashtag
      id
      newNotifications
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createHashtagPosts = /* GraphQL */ `
  mutation CreateHashtagPosts(
    $input: CreateHashtagPostsInput!
    $condition: ModelHashtagPostsConditionInput
  ) {
    createHashtagPosts(input: $input, condition: $condition) {
      createdAt
      hashtagId
      id
      postId
      updatedAt
      owner
    }
  }
`;
export const updateHashtagPosts = /* GraphQL */ `
  mutation UpdateHashtagPosts(
    $input: UpdateHashtagPostsInput!
    $condition: ModelHashtagPostsConditionInput
  ) {
    updateHashtagPosts(input: $input, condition: $condition) {
      createdAt
      hashtagId
      id
      postId
      updatedAt
      owner
    }
  }
`;
export const deleteHashtagPosts = /* GraphQL */ `
  mutation DeleteHashtagPosts(
    $input: DeleteHashtagPostsInput!
    $condition: ModelHashtagPostsConditionInput
  ) {
    deleteHashtagPosts(input: $input, condition: $condition) {
      createdAt
      hashtagId
      id
      postId
      updatedAt
      owner
    }
  }
`;
export const createListen = /* GraphQL */ `
  mutation CreateListen(
    $input: CreateListenInput!
    $condition: ModelListenConditionInput
  ) {
    createListen(input: $input, condition: $condition) {
      id
      listeners
      listenersNum
      listeningTo
      listeningToNum
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateListen = /* GraphQL */ `
  mutation UpdateListen(
    $input: UpdateListenInput!
    $condition: ModelListenConditionInput
  ) {
    updateListen(input: $input, condition: $condition) {
      id
      listeners
      listenersNum
      listeningTo
      listeningToNum
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteListen = /* GraphQL */ `
  mutation DeleteListen(
    $input: DeleteListenInput!
    $condition: ModelListenConditionInput
  ) {
    deleteListen(input: $input, condition: $condition) {
      id
      listeners
      listenersNum
      listeningTo
      listeningToNum
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMentionNotifications = /* GraphQL */ `
  mutation CreateMentionNotifications(
    $input: CreateMentionNotificationsInput!
    $condition: ModelMentionNotificationsConditionInput
  ) {
    createMentionNotifications(input: $input, condition: $condition) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMentionNotifications = /* GraphQL */ `
  mutation UpdateMentionNotifications(
    $input: UpdateMentionNotificationsInput!
    $condition: ModelMentionNotificationsConditionInput
  ) {
    updateMentionNotifications(input: $input, condition: $condition) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMentionNotifications = /* GraphQL */ `
  mutation DeleteMentionNotifications(
    $input: DeleteMentionNotificationsInput!
    $condition: ModelMentionNotificationsConditionInput
  ) {
    deleteMentionNotifications(input: $input, condition: $condition) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMentions = /* GraphQL */ `
  mutation CreateMentions(
    $input: CreateMentionsInput!
    $condition: ModelMentionsConditionInput
  ) {
    createMentions(input: $input, condition: $condition) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMentions = /* GraphQL */ `
  mutation UpdateMentions(
    $input: UpdateMentionsInput!
    $condition: ModelMentionsConditionInput
  ) {
    updateMentions(input: $input, condition: $condition) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMentions = /* GraphQL */ `
  mutation DeleteMentions(
    $input: DeleteMentionsInput!
    $condition: ModelMentionsConditionInput
  ) {
    deleteMentions(input: $input, condition: $condition) {
      id
      postId
      postNumber
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOfficialPost = /* GraphQL */ `
  mutation CreateOfficialPost(
    $input: CreateOfficialPostInput!
    $condition: ModelOfficialPostConditionInput
  ) {
    createOfficialPost(input: $input, condition: $condition) {
      createdAt
      id
      isOfficial
      postId
      updatedAt
      owner
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
    }
  }
`;
export const updateOfficialPost = /* GraphQL */ `
  mutation UpdateOfficialPost(
    $input: UpdateOfficialPostInput!
    $condition: ModelOfficialPostConditionInput
  ) {
    updateOfficialPost(input: $input, condition: $condition) {
      createdAt
      id
      isOfficial
      postId
      updatedAt
      owner
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
    }
  }
`;
export const deleteOfficialPost = /* GraphQL */ `
  mutation DeleteOfficialPost(
    $input: DeleteOfficialPostInput!
    $condition: ModelOfficialPostConditionInput
  ) {
    deleteOfficialPost(input: $input, condition: $condition) {
      createdAt
      id
      isOfficial
      postId
      updatedAt
      owner
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      createdAt
      editReason
      exists
      deleteReason
      generationZeroId
      groupId
      id
      isHidden
      isOfficial
      isVerified
      image
      message
      originalPost
      parentId
      profileIcon
      reportReason
      type
      username
      updatedAt
      owner
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      createdAt
      editReason
      exists
      deleteReason
      generationZeroId
      groupId
      id
      isHidden
      isOfficial
      isVerified
      image
      message
      originalPost
      parentId
      profileIcon
      reportReason
      type
      username
      updatedAt
      owner
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      createdAt
      editReason
      exists
      deleteReason
      generationZeroId
      groupId
      id
      isHidden
      isOfficial
      isVerified
      image
      message
      originalPost
      parentId
      profileIcon
      reportReason
      type
      username
      updatedAt
      owner
    }
  }
`;
export const createPostNumbers = /* GraphQL */ `
  mutation CreatePostNumbers(
    $input: CreatePostNumbersInput!
    $condition: ModelPostNumbersConditionInput
  ) {
    createPostNumbers(input: $input, condition: $condition) {
      echoes
      exists
      groupId
      id
      listens
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePostNumbers = /* GraphQL */ `
  mutation UpdatePostNumbers(
    $input: UpdatePostNumbersInput!
    $condition: ModelPostNumbersConditionInput
  ) {
    updatePostNumbers(input: $input, condition: $condition) {
      echoes
      exists
      groupId
      id
      listens
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePostNumbers = /* GraphQL */ `
  mutation DeletePostNumbers(
    $input: DeletePostNumbersInput!
    $condition: ModelPostNumbersConditionInput
  ) {
    deletePostNumbers(input: $input, condition: $condition) {
      echoes
      exists
      groupId
      id
      listens
      postId
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPostStatus = /* GraphQL */ `
  mutation CreatePostStatus(
    $input: CreatePostStatusInput!
    $condition: ModelPostStatusConditionInput
  ) {
    createPostStatus(input: $input, condition: $condition) {
      addedListen
      id
      isHidden
      postId
      reportReason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePostStatus = /* GraphQL */ `
  mutation UpdatePostStatus(
    $input: UpdatePostStatusInput!
    $condition: ModelPostStatusConditionInput
  ) {
    updatePostStatus(input: $input, condition: $condition) {
      addedListen
      id
      isHidden
      postId
      reportReason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePostStatus = /* GraphQL */ `
  mutation DeletePostStatus(
    $input: DeletePostStatusInput!
    $condition: ModelPostStatusConditionInput
  ) {
    deletePostStatus(input: $input, condition: $condition) {
      addedListen
      id
      isHidden
      postId
      reportReason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      addShadow
      banner
      blurb
      cannotRemove
      firstName
      groupImageViewOption
      iconLocation
      id
      imageViewOption
      isOfficial
      isVerified
      lastName
      listeners
      listeningTo
      profileIcon
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      addShadow
      banner
      blurb
      cannotRemove
      firstName
      groupImageViewOption
      iconLocation
      id
      imageViewOption
      isOfficial
      isVerified
      lastName
      listeners
      listeningTo
      profileIcon
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      addShadow
      banner
      blurb
      cannotRemove
      firstName
      groupImageViewOption
      iconLocation
      id
      imageViewOption
      isOfficial
      isVerified
      lastName
      listeners
      listeningTo
      profileIcon
      username
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createReportGroup = /* GraphQL */ `
  mutation CreateReportGroup(
    $input: CreateReportGroupInput!
    $condition: ModelReportGroupConditionInput
  ) {
    createReportGroup(input: $input, condition: $condition) {
      actionTaken
      createdAt
      groupId
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      group {
        banner
        categories
        createdBy
        description
        id
        isOfficial
        isOwnerOnly
        isVerified
        listeners
        locations
        name
        preferredName
        posts
        requestedList
        createdAt
        updatedAt
        owner
        postIds {
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateReportGroup = /* GraphQL */ `
  mutation UpdateReportGroup(
    $input: UpdateReportGroupInput!
    $condition: ModelReportGroupConditionInput
  ) {
    updateReportGroup(input: $input, condition: $condition) {
      actionTaken
      createdAt
      groupId
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      group {
        banner
        categories
        createdBy
        description
        id
        isOfficial
        isOwnerOnly
        isVerified
        listeners
        locations
        name
        preferredName
        posts
        requestedList
        createdAt
        updatedAt
        owner
        postIds {
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteReportGroup = /* GraphQL */ `
  mutation DeleteReportGroup(
    $input: DeleteReportGroupInput!
    $condition: ModelReportGroupConditionInput
  ) {
    deleteReportGroup(input: $input, condition: $condition) {
      actionTaken
      createdAt
      groupId
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      group {
        banner
        categories
        createdBy
        description
        id
        isOfficial
        isOwnerOnly
        isVerified
        listeners
        locations
        name
        preferredName
        posts
        requestedList
        createdAt
        updatedAt
        owner
        postIds {
          nextToken
        }
      }
      owner
    }
  }
`;
export const createReportPost = /* GraphQL */ `
  mutation CreateReportPost(
    $input: CreateReportPostInput!
    $condition: ModelReportPostConditionInput
  ) {
    createReportPost(input: $input, condition: $condition) {
      actionTaken
      createdAt
      id
      notes
      postId
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateReportPost = /* GraphQL */ `
  mutation UpdateReportPost(
    $input: UpdateReportPostInput!
    $condition: ModelReportPostConditionInput
  ) {
    updateReportPost(input: $input, condition: $condition) {
      actionTaken
      createdAt
      id
      notes
      postId
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteReportPost = /* GraphQL */ `
  mutation DeleteReportPost(
    $input: DeleteReportPostInput!
    $condition: ModelReportPostConditionInput
  ) {
    deleteReportPost(input: $input, condition: $condition) {
      actionTaken
      createdAt
      id
      notes
      postId
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      post {
        createdAt
        editReason
        exists
        deleteReason
        generationZeroId
        groupId
        id
        isHidden
        isOfficial
        isVerified
        image
        message
        originalPost
        parentId
        profileIcon
        reportReason
        type
        username
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createReportProfile = /* GraphQL */ `
  mutation CreateReportProfile(
    $input: CreateReportProfileInput!
    $condition: ModelReportProfileConditionInput
  ) {
    createReportProfile(input: $input, condition: $condition) {
      actionTaken
      createdAt
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const updateReportProfile = /* GraphQL */ `
  mutation UpdateReportProfile(
    $input: UpdateReportProfileInput!
    $condition: ModelReportProfileConditionInput
  ) {
    updateReportProfile(input: $input, condition: $condition) {
      actionTaken
      createdAt
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const deleteReportProfile = /* GraphQL */ `
  mutation DeleteReportProfile(
    $input: DeleteReportProfileInput!
    $condition: ModelReportProfileConditionInput
  ) {
    deleteReportProfile(input: $input, condition: $condition) {
      actionTaken
      createdAt
      id
      notes
      reportedBy
      reportReason
      resolved
      username
      updatedAt
      owner
    }
  }
`;
export const createReportUser = /* GraphQL */ `
  mutation CreateReportUser(
    $input: CreateReportUserInput!
    $condition: ModelReportUserConditionInput
  ) {
    createReportUser(input: $input, condition: $condition) {
      banned
      count
      id
      notes
      username
      createdAt
      updatedAt
      reportGroup {
        items {
          actionTaken
          createdAt
          groupId
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportPost {
        items {
          actionTaken
          createdAt
          id
          notes
          postId
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportProfile {
        items {
          actionTaken
          createdAt
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateReportUser = /* GraphQL */ `
  mutation UpdateReportUser(
    $input: UpdateReportUserInput!
    $condition: ModelReportUserConditionInput
  ) {
    updateReportUser(input: $input, condition: $condition) {
      banned
      count
      id
      notes
      username
      createdAt
      updatedAt
      reportGroup {
        items {
          actionTaken
          createdAt
          groupId
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportPost {
        items {
          actionTaken
          createdAt
          id
          notes
          postId
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportProfile {
        items {
          actionTaken
          createdAt
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteReportUser = /* GraphQL */ `
  mutation DeleteReportUser(
    $input: DeleteReportUserInput!
    $condition: ModelReportUserConditionInput
  ) {
    deleteReportUser(input: $input, condition: $condition) {
      banned
      count
      id
      notes
      username
      createdAt
      updatedAt
      reportGroup {
        items {
          actionTaken
          createdAt
          groupId
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportPost {
        items {
          actionTaken
          createdAt
          id
          notes
          postId
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
      reportProfile {
        items {
          actionTaken
          createdAt
          id
          notes
          reportedBy
          reportReason
          resolved
          username
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createTrending = /* GraphQL */ `
  mutation CreateTrending(
    $input: CreateTrendingInput!
    $condition: ModelTrendingConditionInput
  ) {
    createTrending(input: $input, condition: $condition) {
      createdAt
      description
      icon
      id
      type
      value
      updatedAt
      owner
    }
  }
`;
export const updateTrending = /* GraphQL */ `
  mutation UpdateTrending(
    $input: UpdateTrendingInput!
    $condition: ModelTrendingConditionInput
  ) {
    updateTrending(input: $input, condition: $condition) {
      createdAt
      description
      icon
      id
      type
      value
      updatedAt
      owner
    }
  }
`;
export const deleteTrending = /* GraphQL */ `
  mutation DeleteTrending(
    $input: DeleteTrendingInput!
    $condition: ModelTrendingConditionInput
  ) {
    deleteTrending(input: $input, condition: $condition) {
      createdAt
      description
      icon
      id
      type
      value
      updatedAt
      owner
    }
  }
`;
