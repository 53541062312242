import { v4 as uuidv4 } from 'uuid';
import ReportedGroup from '@/models/admin/ReportedGroup';
import ReportedPost from '@/models/admin/ReportedPost';
import ReportedProfile from '@/models/admin/ReportedProfile';

export default class ReportedUser {
  banned = false;
  count = 0;
  id = '';
  notes = '';
  reportGroup: Array<ReportedGroup> = [];
  reportPost: Array<ReportedPost> = [];
  reportProfile: Array<ReportedProfile> = [];
  username = '';

  constructor({banned, count, id, notes, reportGroup, reportPost, reportProfile, username}: any) {
    this.banned = banned ?? false;
    this.count = count != null ? count : 0;
    this.id = id ? id : uuidv4();
    this.notes = notes ? notes : '';
    this.reportGroup = reportGroup ? reportGroup : [];
    this.reportPost = reportPost ? reportPost : [];
    this.reportProfile = reportProfile ? reportProfile : [];
    this.username = username ? username : '';
  }
}
