













































































import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
import CategoryTab from '@/components/search/CategoryTab.vue';
import GroupTab from '@/components/search/GroupTab.vue';
import HeaderInfo from '@/components/profile/HeaderInfo.vue';
import ImageContainer from '@/components/profile/ImageContainer.vue';
import ProfileSkeleton from '@/components/skeletons/ProfileSkeleton.vue';
// eslint-disable-next-line no-unused-vars
import ModelProfile from '@/models/user/Profile';
import ProfileStats from '@/components/profile/ProfileStats.vue';
import UserMixin from '@/mixins/User.vue';


@Component({
  components: {
    CategoryTab,
    GroupTab,
    HeaderInfo,
    ImageContainer,
    ProfileSkeleton,
    ProfileStats,
  },
})
export default class Profile extends UserMixin {
  hasReported = false;
  isFollowing = false;
  isLoading = false;
  listeners = 0;
  listeningTo = 0;
  profile: ModelProfile | null = null;
  reloadName = 'Shouts';
  username: string = '';

  get cannotRemove() {
    return (this.profile && this.profile.cannotRemove) ||
      (this.user && this.profile && this.user.username.toLowerCase() === this.profile.username.toLowerCase());
  }

  get firstName() {
    return this.profile && this.profile.firstName ? `${this.profile.firstName} ` : '';
  }

  get fullName() {
    return `${this.firstName}${this.lastName}`;
  }

  get isLargeScreen() {
    return !this.$store.getters['isMobile'] && !this.$store.getters['isTablet'];
  }

  get isLoggedIn() {
    return this.user != null;
  }

  get lastName() {
    return this.profile && this.profile.lastName ? `${this.profile.lastName} ` : '';
  }

  get size() {
    return this.isLargeScreen ? 'is-normal' : 'is-small';
  }

  created() {
    const params = this.$route.params;
    const name = params && params.username ? params.username : '';

    this.username = name.toLowerCase();
    this.getData();
  }

  getData() {
    this.isLoading = true;

    this.$store.dispatch('getProfile', this.username)
      .then((profile: ModelProfile) => {
        this.profile = profile;
        this.listeners = profile.listeners;
        this.listeningTo = profile.listeningTo;
        this.$store.dispatch('getAmIListeningTo', this.profile.username)
          .then((found: boolean) => this.isFollowing = found)
      })
      .catch(() => failure(this, {message: 'Error retrieving profile.'}))
      .finally(() => this.isLoading = false);
  }

  isListening() {
    if (!this.user || !this.profile || this.user.username === this.profile.username) return;

    this.$store.dispatch('updateListeningTo', this.profile.username)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          this.isFollowing = !this.isFollowing;
          this.listeners += this.isFollowing ? 1 : -1;
        } else {
          failure(this, {message: 'Error listening. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error listening. Please try again later.'}));
  }

  tabChanged(name: string) {
    this.reloadName = name;
  }
}
