











































import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
import { Sort, Type } from '@/enums/calls/sortBy';
import Echo from '@/components/shout/Echo.vue';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
import OpenUser from '@/mixins/OpenUser.vue';
import Shout from '@/components/shout/Shout.vue';
import ShoutSorter from '@/components/shout/ShoutSorter.vue';

@Component({
  components: {
    Echo,
    MediaSkeleton,
    Shout,
    ShoutSorter,
  },
})
export default class MyShouts extends OpenUser {
  fullPosts: Array<FullPost> = [];
  isLoading = false;
  option = Type.All;
  sort = Sort.Recent;

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get isLoggedIn() {
    return this.user != null;
  }

  created() {
    this.getData();
  }

  getData() {
    this.isLoading = true;

    this.$store.dispatch('getMyShouts', {sort: this.sort, sortType: this.option})
      .then((posts: Array<FullPost>) => this.fullPosts = posts)
      .catch(() => failure(this, {message: 'Error retrieving my shouts. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  optionChanged(val: Type) {
    this.option = val;
    this.getData();
  }

  sortChanged(val: Sort) {
    this.sort = val;
    this.getData();
  }
}
