import { MyHashtags } from '@/models/user/MyHashtags';
import Listen from '@/models/user/Listen';
import Listening from '@/models/user/Listening';
import MyNotifications from '@/models/user/MyNotifications';
import Profile from '@/models/user/Profile';

const User = {
  getters: {
    getListeningTo: (state: any) => state.listeningTo,
    getRemoveUser: (state: any) => state.removedUser,
    isReportProfileModalActive: (state: any) => state.isReportProfileModalActive,
  },
  actions: {
    blockUser(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverBlockUser', username)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    closeReportProfileModal(context: any) {
      context.commit('setIsReportProfileModalActive', {isOpen: false, profile: null});
    },
    getAmIListeningTo(context: any, profileUsername: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAmIListeningTo', profileUsername)
            .then((found: boolean) => resolve(found));
        } catch(err) {
          reject(err);
        }
      });
    },
    getHashtags(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetHashtags')
            .then((myHashtags: MyHashtags) => resolve(myHashtags));
        } catch {
          reject([]);
        }
      });
    },
    getListenData(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetListenData', username)
            .then((listen: Listen) => resolve(listen));
        } catch(err) {
          return err;
        }
      });
    },
    getMyListeners(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMyListeners')
            .then((listening: Listening) => resolve(listening));
        } catch(err) {
          reject(err);
        }
      });
    },
    getMyListeningTo(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMyListeningTo')
            .then((listening: Listening) => resolve(listening));
        } catch(err) {
          reject(err);
        }
      });
    },
    getMyNotifications(context: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetMyNotifications')
            .then((notifications: MyNotifications) => resolve(notifications));
        } catch {
          reject(null);
        }
      });
    },
    getProfile(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetProfile', username)
            .then((profile: Profile) => resolve(profile));
        } catch(err) {
          reject(err);
        }
      });
    },
    openReportProfileModal(context: any, username: string) {
      context.dispatch('getProfile', username)
        .then((profile: Profile) => {
          if (profile) {
            context.commit('setIsReportProfileModalActive', {isOpen: true, profile});
          }
        });
    },
    removeUserPosts(context: any, username: string|null) {
      context.commit('removeUserPosts', username);
    },
    reportProfile(context: any, { username, reportReason }: any) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverReportProfile', {username, reportReason})
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    setListeningTo(context: any, listeningTo: Array<string>) {
      context.commit('setListeningTo', listeningTo);
    },
    updateHashtagNotification(context: any, hashtag: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateHashtagNotification', hashtag)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    updateListeningTo(context: any, profileUsername: string) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateListeningTo', profileUsername)
            .then((isSuccess: boolean) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    updateProfile(context: any, profile: Profile) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateProfile', profile)
            .then((profile: Profile) => resolve(profile));
        } catch(err) {
          reject(err);
        }
      });
    },
    uploadBannerImage(context: any, file: File) {
      return new Promise((resolve, reject) => {
        try {
          resolve(context.dispatch('serverUploadBannerImage', file));
        } catch(err) {
          reject(err);
        }
      });
    },
    uploadIconImage(context: any, file: File) {
      return new Promise((resolve, reject) => {
        try {
          resolve(context.dispatch('serverUploadIconImage', file));
        } catch(err) {
          reject(err);
        }
      });
    },
  },
  mutations: {
    removeUserPosts(state: any, username: string|null) {
      const lower = username ? username.toLowerCase() : null;
      state.removedUser = lower;
    },
    setIsReportProfileModalActive(state: any, { isOpen, profile }: any) {
      state.isReportProfileModalActive = {isOpen, profile};
    },
    setListeningTo(state: any, listeningTo: Array<string>) {
      state.listeningTo = listeningTo;
    },
  },
  state: {
    isReportProfileModalActive: {isOpen: false, profile: null},
    listeningTo: [],
    removedUser: null,
  },
};

export default User;
