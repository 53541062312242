















import Component from 'vue-class-component';

// eslint-disable-next-line no-unused-vars
import Search from '@/models/search/Search';
import SearchContainer from '@/components/search/SearchContainer.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    SearchContainer,
  },
})
export default class FourOFour extends UserMixin {
  results: Search|null = null;

  get keyword(): string {
    const paths = this.$route.fullPath.split('/');
    return paths[paths.length - 1];
  }

  get size(): string {
    return this.$store.getters['isMobile'] || this.$store.getters['isTablet'] ? 'is-small' : 'is-medium';
  }

  created() {
    const newSearch = new Search({searchString: this.keyword});

    this.$store.dispatch('search', newSearch)
      .then((results: Search) => this.results = results);
  }
}
