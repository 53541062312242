




































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
import { MyHashtags } from '@/models/user/MyHashtags';
import Group from '@/models/group/Group';
import Listening from '@/models/user/Listening';
import UserMixin from '@/mixins/User.vue';

@Component
export default class AddShoutList extends UserMixin {
  category = ''; // hashtags, mentions, groups
  isModalActive = false;
  list: Array<string> = [];
  results: Array<string> = [];

  get capitalizedCategory() {
    return this.category ? `${this.category.charAt(0).toUpperCase()}${this.category.substr(1)}` : '';
  }

  get isAddListModalActive() {
		return this.$store.getters['isAddListModalActive'];
	}

  get noResultsTitle() {
    return `No ${this.capitalizedCategory}`;
  }

  get title() {
    return this.category ? `Add Your ${this.capitalizedCategory}` : 'Add';
  }

  @Watch('isAddListModalActive')
	modalActiveChanged(newVal: any) {
		this.isModalActive = newVal.isOpen;
    this.category = newVal.category;

    this.getData();
	}

  addItems() {
    if (this.list.length === 0) return;

    const pre = this.category === 'groups' ? '&' : this.category === 'hashtags' ? '#' : '@';

    this.$store.dispatch('addListItems', this.list.map((str: string) => `${pre}${str}`));
    this.close();
  }

  close() {
    this.reset();
		this.$store.dispatch('closeAddListModal');
	}

  getData() {
    if (!this.category || !this.user) return;

    let storeCall = '';

    switch (this.category.toLowerCase()) {
      case 'groups': storeCall = 'getMyGroups'; break;
      case 'hashtags': storeCall = 'getHashtags'; break;
      case 'mentions': storeCall = 'getMyListeningTo'; break;
      default: storeCall = ''; return;
    }

    this.$store.dispatch(storeCall, this.user.username)
      .then((res: Array<Group|Listening|MyHashtags>) => { 
        if (!res) return;

        if (res instanceof MyHashtags) {
          this.results = res.hashtags.map((tag: string) => tag).sort();
        } else {
          this.results = res.map((obj) => {
            if (obj instanceof Group) return obj.preferredName;
            else if (obj instanceof Listening) return obj.profile.username;
            return obj.toString();
          }).sort();
        }
      })
      .catch(() => failure(this, {message: `Error retrieving your ${this.category}`}))
      .finally(() => this.$store.dispatch('setGlobalLoading', false));
  }

  reset() {
    this.category = '';
    this.list = [];
    this.results = [];
  }
}
