













































































































import Component from 'vue-class-component';

import LargeSearchBar from '@/components/search/LargeSearchBar.vue';
import NotificationIcon from '@/components/notification/NotificationIcon.vue';
import NotificationSubs from '@/mixins/NotificationSubs.vue';
// eslint-disable-next-line no-unused-vars
import User from '@/models/user/User';

@Component({
  components: {
    LargeSearchBar,
    NotificationIcon,
  },
})
export default class LargeMenu extends NotificationSubs {
  get hasAdmin() {
    const user: User = this.$store.getters['getUser'];
    return user ? user.groups.some((grp) => grp === 'admin') : false;
  }

  logout() {
    this.$store.dispatch('signOut')
      .finally(() => this.open('/'));
  }

  open(link: string) {
    this.openLink(link);
  }

  openLogInModal() {
    this.$store.dispatch('openLogInModal');
  }

  openSignUpModal() {
    this.$store.dispatch('openSignUpModal');
  }
}
