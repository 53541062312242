








































import Component from 'vue-class-component';

import { failure } from '@/utility/messages';
import Echo from '@/components/shout/Echo.vue';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';
// eslint-disable-next-line no-unused-vars
import HashtagResults from '@/models/search/HashtagResults';
import MediaSkeleton from '@/components/skeletons/MediaSkeleton.vue';
import Shout from '@/components/shout/Shout.vue';
import UserMixin from '@/mixins/User.vue';

@Component({
  components: {
    Echo,
    MediaSkeleton,
    Shout,
  },
})
export default class Hashtag extends UserMixin {
  hasHashtag = false;
  hashtag = '';
  isLoading = false;
  fullPosts: Array<FullPost> = [];

  get isLoggedIn() {
    return this.user != null;
  }
  
  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get label() {
    return this.hasHashtag ? 'Subscribed' : 'Subscribe To...';
  }

  get title(): string {
    return this.hashtag ? `#${this.hashtag}` : '';
  }

  get type() {
    return this.hasHashtag ? 'is-success' : 'is-primary';
  }

  created() {
    const params = this.$route.params;
    const hashtag = params ? params.hashtag : '';

    this.getData(hashtag);
    this.updateNotification(hashtag);
  }

  changeSubscribe() {
    this.$store.dispatch('updateHashtagSub', this.hashtag)
      .then((isSuccess: boolean) => {
        if (isSuccess) this.hasHashtag = !this.hasHashtag;
        else failure(this, {message: 'Error changing subscription. Please try again later.'});
      })
      .catch(() => failure(this, {message: 'Error changing subscription. Please try again later.'}));
  }

  getData(hashtag: string) {
    if (!hashtag) return;

    this.hashtag = hashtag.toLowerCase();
    this.isLoading = true;

    this.$store.dispatch('getHashtag', hashtag)
      .then((results: HashtagResults) => {
        if (results) {
          this.fullPosts = results.fullPosts;
          this.hasHashtag = results.isSubscribed;
        }
      })
      .catch(() => failure(this, {message: 'Error retrieving hashtag data.'}))
      .finally(() => this.isLoading = false);
  }

  updateNotification(hashtag: string) {
    if (!hashtag) return;
    this.$store.dispatch('updateHashtagNotification', this.hashtag);
  }
}
