





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
import BasicLogin from '@/models/login/BasicLogin';
import ConfirmForm from '@/components/login/ConfirmForm.vue';
import ForgotForm from '@/components/login/ForgotForm.vue';

@Component({
  components: {
    ConfirmForm,
    ForgotForm,
  },
})
export default class LoginForm extends Vue {
  basicLogin: BasicLogin = new BasicLogin({});
  isForgotModalActive = false;
  isLoading = false;
  isLogInModalActive = false;
  password: string = '';
  passwordTouched: boolean = false;
  username: string = '';
  usernameTouched: boolean = false;

  get isUsernameValid() {
    return !this.usernameTouched || this.username.length > 0;
  }
  get isPasswordValid() {
    return !this.passwordTouched || this.password.length > 0;
  }

  close() {
    this.isForgotModalActive = false;
    this.isLogInModalActive = false;
  }

  handleFailedLogin({ code }) {
    if (!code) return;

    if (code === 'UserNotConfirmedException') {
      this.basicLogin.username = this.username;
      this.isLogInModalActive = true;
    } else if (code.indexOf('Exception') > -1) {
      failure(this, {message: 'Invalid Username or Password.'});
    }
  }

  login(login: BasicLogin) {
    if (!login) return;

    this.isLoading = true;

    this.$store.dispatch('login', login)
      .then((res: any) => {
        if (res.signInUserSession) {
          this.$store.dispatch('closeLogInModal');
          this.reset();
          if (this.$route.fullPath === '/') {
            this.$router.push('/home');
          }
        } else {
          this.handleFailedLogin(res);
        }
      })
      .catch(() => failure(this, {message: 'Error logging in. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  @Watch('username')
  onUsernameChanged() {
    this.usernameTouched = true;
  }

  @Watch('password')
  onPasswordChanged() {
    this.passwordTouched = true;
  }

  forgot(): void {
    this.isForgotModalActive = true;
  }

  reset() {
    this.basicLogin = new BasicLogin({});
    this.password = '';
    this.passwordTouched = false;
    this.username = '';
    this.usernameTouched = false;
  }

  signUp(): void {
    this.$store.dispatch('openSignUpModal');
  }

  validateLogin(): void {
    this.usernameTouched = true;
    this.passwordTouched = true;

    if (this.isUsernameValid && this.isPasswordValid) {
      const login = new BasicLogin({email: '', username: this.username, password: this.password});
      this.login(login);
    }
  }
}
