


























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import Search from '@/models/search/Search';
import SearchResults from '@/components/search/SearchResults.vue';

@Component({
  components: {
    SearchResults,
  },
})
export default class SearchContainer extends Vue {
  @Prop({required: true}) results!: Search;
  @Prop({default: 'is-medium'}) size!: string;

  get hashtagsLength() {
    const length = this.results && this.results.hashtags ? this.results.hashtags.length : -1;
    return length >= 0 ? length >= 10 ? '9+' : length : '';
  }

  get profilesLength() {
    const length = this.results && this.results.profiles ? this.results.profiles.length : -1;
    return length >= 0 ? length >= 10 ? '9+' : length : '';
  }

  get shoutsLength() {
    const length = this.results && this.results.posts ? this.results.posts.length : -1;
    return length >= 0 ? length >= 10 ? '9+' : length : '';
  }
}
