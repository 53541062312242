


































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { failure } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import Post from '@/models/shout/Post';

@Component
export default class ShoutStats extends Vue {
  @Prop({default: false}) addedListen!: boolean;
  @Prop({required: true}) echoes!: number;
  @Prop({required: true}) id!: string;
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({required: true}) listens!: number;
  @Prop({default: 0}) newEchoes!: number;

  added = false;
  isSaving = false;
  totalEchoes = 0;
  totalListens = 0;

  get echoLabel() {
    return this.newEchoes > 0 ? `${this.newEchoes} New ${this.newEchoes > 1 ? 'Echoes' : 'Echo'}` : 'Echo';
  }

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get lastUpdated(): Post|null {
    const post = this.$store.getters['lastUpdated'];
    return post && post.id && post.id === this.id ? post : null;
  }

  @Watch('addedListen')
  addedListenChanged(newVal: boolean) {
    this.added = newVal;
  }

  @Watch('echoes')
  echoesChanged(newVal: number) {
    this.totalEchoes = newVal;
  }

  @Watch('lastUpdated')
  lastUpdatedChanged(post: Post|null) {
    if (!post) return;
    this.setVariables(post);
  }

  @Watch('listens')
  listensChanged(newVal: number) {
    this.totalListens = newVal;
  }

  created() {
    this.setVariables({
      addedListen: this.addedListen,
      echoes: this.echoes,
      listens: this.listens,
    });
  }

  addListen() {
    if (this.isSaving || !this.isLoggedIn) return;

    this.isSaving = true;

    this.$store.dispatch('updateListen', this.id)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          this.$emit('update-listen', true)
        }
      })
      .catch(() => failure(this, {message: 'Error updating your listen preference.'}))
      .finally(() => this.isSaving = false);
  }

  createEcho() {
    if (!this.isLoggedIn) return;
    this.$store.dispatch('openEchoModal', this.id);
  }

  setVariables({addedListen, echoes, listens}: any) {
    this.added = addedListen;
    this.totalEchoes = echoes;
    this.totalListens = listens;
  }
}
