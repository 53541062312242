

































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import TermsOfUsePage from '@/views/TermsOfUse.vue';

@Component({
  components: {
    TermsOfUsePage,
  },
})
export default class TermsOfUse extends Vue {
  @Prop({required: true}) isActive!: boolean;

  isModalActive = false;

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
  }

  close(agreed: boolean = false) {
    this.$emit('agreed', agreed);
  }
}
