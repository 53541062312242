











































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
import GroupStats from '@/components/group/GroupStats.vue';
import SubscriptionStatus from '@/enums/group/subscriptionStatus';
import User from '@/mixins/User.vue';
import ViewOption from '@/enums/profile/viewOption';
import { parseGroupLocations } from '@/utility/main';

@Component({
  components: {
    GroupStats,
  },
})
export default class SmallGroup extends User {
  @Prop({required: true}) group!: Group;
  @Prop({default: false}) hasNewListenerRequests!: boolean;
  @Prop({default: false}) hasNewPosts!: boolean;
  @Prop({default: false}) isFollowing!: boolean;
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({default: false}) isMobile!: boolean;
  @Prop({default: false}) isOwner!: boolean;
  @Prop({default: false}) showLocations!: boolean;
  @Prop({default: ''}) status!: string;

  get canChangeFollowing() {
    return this.status !== SubscriptionStatus.DENIED;
  }

  get canView() {
    return this.isOwner || (this.user && this.user.groupImageViewOption === ViewOption.ALL);
  }

  get description() {
    return !this.isLargeMode && this.group.description.length > 50 ?
      `${this.group.description.substr(0, 50)}...` : this.group.description;
  }

  get groupName() {
    return !this.isMobile || !this.isLoggedIn ? this.group.preferredName : this.group.preferredName.length > 10 ?
      `${this.group.preferredName.substr(0, 10)}...` : this.group.preferredName;
  }

  get hasNew() {
    return (this.isOwner && (this.hasNewListenerRequests || this.hasNewPosts)) || (this.isFollowing && this.hasNewPosts);
  }

  get isLargeMode() {
    return this.$store.getters['isLargeMode'];
  }

  get label() {
    return this.isOwner ? 'Owner' :
      this.isFollowing ? 'Listening' :
      this.status === SubscriptionStatus.REQUESTED ? 'Requested' :
      this.status === SubscriptionStatus.DENIED ? 'Denied' : 'Listen To...';
  }

  get locations() {
    return parseGroupLocations(this.group.locations);
  }

  get type() {
    return this.isFollowing || this.isOwner ? 'is-success' :
      this.status === SubscriptionStatus.DENIED ? 'is-danger' : 'is-primary';
  }

  changeFollowing() {
    if (!this.isLoggedIn || this.isOwner || !this.canChangeFollowing) return;

    this.$emit('is-following', this.group.id);
  }

  clickIt(e: any) {
    try {
      let path: string = '';
      if (e.path) {
        path = e.path[0].className.split(' ')[0];
      } else if (e.target) {
        path = e.target.className.split(' ')[0];
      }
      if (['media', 'media-content', 'column', 's-small-group'].includes(path)) {
        this.openGroup();
      }
    } catch {
      // Silently fail... don't do anything.
    }
  }

  edit() {
    this.$store.dispatch('openEditGroupModal', this.group.id);
  }

  openGroup() {
    this.$router.push(`/group/${this.group.preferredName}`);
  }

  openProfile() {
    this.$router.push(`/profile/${this.group.createdBy}`);
  }
}
