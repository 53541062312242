enum Category {
  ANIMALS = 'Animals',
  ART = 'Art',
  CARS = 'Cars',
  EVENTS = 'Events',
  GAMES = 'Games',
  HOBBIES = 'Hobbies',
  LOCAL = 'Local',
  MUSIC = 'Music',
  NETWORKING = 'Networking',
  NEWS = 'News',
  OTHER = 'Other',
  PEOPLE = 'People',
  POLITICS = 'Politics',
}

export default Category;
