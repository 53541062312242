

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import { Notification } from '@/models/user/MyHashtags';
import Hashtag from '@/components/hashtag/Hashtag.vue';

@Component({
  components: {
    Hashtag,
  },
})
export default class AbcHashtag extends Vue {
  @Prop({required: true}) hashtags!: Array<string>;
  @Prop({required: false}) lettersSelected!: Array<string>;
  @Prop({required: false}) newHashtags!: Array<Notification>;

  get categories(): any {
    let cats: any = [];

    this.hashtags.forEach((tag: string) => {
      const firstLetter = tag.charAt(0);
      const upper = firstLetter.toUpperCase();
      const index = cats.findIndex((cat: any) => cat.firstLetter === upper);

      if (index > -1) {
        const cat = cats[index];
        const tags = cat.tags;
        
        cats.splice(index, 1, {firstLetter: upper, tags: [...tags, tag].sort()});
      } else {
        cats.push({firstLetter: upper, tags: [tag]});
      }
    });
    return cats.sort((a: any, b: any) => a.firstLetter > b.firstLetter ? 1 : a.firstLetter < b.firstLetter ? -1 : 0);
  }

  @Watch('lettersSelected')
  lettersSelectedChanged(newVal: Array<string>) {
    this.abcChosen(newVal);
  }

  abcChosen(letters: Array<string>) {
    for (let letter of letters) {
      const ref: any = this.$refs[letter.toUpperCase()];

      if (ref) {
        window.scrollTo(0, ref[0].offsetTop);
        return;
      }
    }
  }
}
