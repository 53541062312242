





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

// eslint-disable-next-line no-unused-vars
import Post from '@/models/shout/Post';
import ShoutHeader from '@/components/shout/ShoutHeader.vue';
import ShoutImage from '@/components/shout/ShoutImage.vue';
import ShoutMessage from '@/components/shout/ShoutMessage.vue';

@Component({
  components: {
    ShoutHeader,
    ShoutImage,
    ShoutMessage,
  },
})
export default class OfficialShout extends Vue {
  @Prop({required: true}) shout!: Post;
}
