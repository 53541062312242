

















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
import { joinGroupLocations, parseGroupLocations } from '@/utility/main';
// eslint-disable-next-line no-unused-vars
import Group from '@/models/group/Group';
// eslint-disable-next-line no-unused-vars
import ListeningGroup from '@/models/group/ListeningGroup';

@Component
export default class AdminEditGroup extends Vue {
  @Prop({required: true}) groupName!: string;
  @Prop({required: true}) isActive!: boolean;

  banner = '';
  description = '';
  group: Group|null = null;
  isLoading = false;
  isModalActive = false;
  locations = '';
  preferredName = '';
  username = '';

  @Watch('isActive')
  isActiveChanged(newVal: boolean) {
    this.isModalActive = newVal;
    if (newVal) this.getData();
  }

  created() {
    this.isModalActive = this.isActive;
    this.getData();
  }

  close(isSuccess: boolean = false) {
    this.$emit('close', isSuccess);
  }

  getData() {
    if (!this.groupName) return;

    this.isLoading = true;

    this.$store.dispatch('getGroup', this.groupName)
      .then((group: ListeningGroup) => {
        if (group && group.group) {
          this.group = group.group;
          this.resetData();
        } else failure(this, {message: 'Error fetching the group. Please try again later.'});
      })
      .catch(() => failure(this, {message: 'Error fetching the group1. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }

  removeBanner() {
    this.banner = '';
  }

  resetData() {
    if (!this.group) return;

    this.banner = this.group.banner.toString();
    this.description = this.group.description;
    this.locations = parseGroupLocations(this.group.locations).join('; ');
    this.preferredName = this.group.preferredName;
    this.username = this.group.createdBy;
  }

  updateGroup() {
    if (!this.group) return;

    this.isLoading = true;

    const updatedGroup = {
      banner: this.banner,
      categories: this.group.categories,
      createdBy: this.group.createdBy,
      description: this.description,
      id: this.group.id,
      isOfficial: this.group.isOfficial,
      isOwnerOnly: this.group.isOwnerOnly,
      isVerified: this.group.isVerified,
      listeners: this.group.listeners < 0 ? 0 : this.group.listeners,
      locations: joinGroupLocations(this.locations.split('; ')),
      preferredName: this.group.preferredName,
      posts: this.group.posts,
      requestedList: this.group.requestedList,
    };

    this.$store.dispatch('adminEditGroup', updatedGroup)
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          success(this, {message: 'Group updated!'});
          this.close(true);
        } else {
          failure(this, {message: 'Error updating the group. Please try again later.'});
        }
      })
      .catch(() => failure(this, {message: 'Error updating the group. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
