import { v4 as uuidv4 } from 'uuid';

export default class EchoNotification {
  id = '';
  newNotifications = 0;
  postId = '';
  username = '';

  constructor({id, newNotifications, postId, username}: any) {
    this.id = id ? id : uuidv4;
    this.newNotifications = newNotifications != null ? newNotifications : 0;
    this.postId = postId ? postId : '';
    this.username = username ? username : '';
  }
}
