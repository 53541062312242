















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { failure, success } from '@/utility/messages';
// eslint-disable-next-line no-unused-vars
import FullPost from '@/models/shout/FullPost';

@Component
export default class DeletePost extends Vue {
  deleteReason: string = 'Other';
  isLoading = false;
  isModalActive = false;
  fullPost: FullPost|null = null;

  get isDeleteModalActive() {
		return this.$store.getters['isDeleteModalActive'];
	}

  get postType() {
    return this.fullPost && this.fullPost.post && this.fullPost.post.parentId.trim() ? 'Echo' : 'Shout';
  }

  get reasons() {
    return [`Accidental ${this.postType}`, 'Image Issues', 'No Longer Relevant', 'Other'];
  }

  get title() {
    return this.fullPost && this.fullPost.post ? `Delete ${this.fullPost.post.message.substr(0, 14)}...` : 'Delete?';
  }

  @Watch('isDeleteModalActive')
	modalActiveChanged(newVal: any) {
		this.isModalActive = newVal.isOpen;
		this.fullPost = newVal.post;
    this.$store.dispatch('setGlobalLoading', false);
	}

  close() {
    this.deleteReason = 'Other';
		this.$store.dispatch('closeDeleteModal');
	}

  deletePost() {
    if (!this.fullPost || !this.fullPost.post) return;

    this.isLoading = true;

    this.$store.dispatch('deletePost', {id: this.fullPost.post['id'], reason: this.deleteReason})
      .then((post: FullPost) => {
        if (post) {
          this.$store.dispatch('addEditedPost', post);
          success(this, {message: 'Post deleted.'});
          this.close();
        } else {
          failure(this, {message: 'Error deleting the post. Please try again later.'})
        }
      })
      .catch(() => failure(this, {message: 'Error deleting the post. Please try again later.'}))
      .finally(() => this.isLoading = false);
  }
}
