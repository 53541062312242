import Auth from '@aws-amplify/auth';

import BasicLogin from '@/models/login/BasicLogin';
import User from '@/models/user/User';
import Profile from '@/models/user/Profile';
import ViewOption from '@/enums/profile/viewOption';

const Login = {
  getters: {
    getProfileProgress: (state: any) => state.profileProgress,
    getUser: (state: any) => state.user,
    isLogInModalActive: (state: any) => state.isLogInModalActive,
    isSignUpModalActive: (state: any) => state.isSignUpModalActive,
  },
  actions: {
    closeLogInModal(context: any) {
      context.commit('setIsLogInModalActive', false);
    },
    closeSignUpModal(context: any) {
      context.commit('setIsSignUpModalActive', false);
    },
    confirmConfirmationCode(context: any, { username, code }: any) {
      return new Promise((resolve, reject) => {
        try {
          resolve(context.dispatch('serverConfirmConfirmationCode', {username, code}));
        } catch(err) {
          reject(err);
        }
      });
    },
    confirmForgotCode(context: any, { code, password, username }: any) {
      return new Promise((resolve, reject) => {
        try {
          resolve(context.dispatch('serverConfirmForgotCode', {code, password, username}));
        } catch(err) {
          reject(err);
        }
      });
    },
    login(context: any, loginInfo: BasicLogin) {
      return new Promise((resolve, reject) => {
        try {
          resolve(context.dispatch('serverLogin', loginInfo));
        } catch(err) {
          reject(err);
        }
      });
    },
    openLogInModal(context: any) {
      context.commit('setIsLogInModalActive', true);
    },
    openSignUpModal(context: any) {
      context.commit('setIsSignUpModalActive', true);
    },
    resendConfirmationCode(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          resolve(context.dispatch('serverResendConfirmationCode', username));
        } catch(err) {
          reject(err);
        }
      });
    },
    sendForgotEmail(context: any, username: string) {
      return new Promise((resolve, reject) => {
        try {
          resolve(context.dispatch('serverSendForgotEmail', username));
        } catch(err) {
          reject(err);
        }
      });
    },
    setProfileProgress(context: any, progress: number) {
      context.commit('setProfileProgress', progress);
    },
    async setUser(context: any) {
      try {
        const user = await Auth.currentAuthenticatedUser();

        context.dispatch('serverGetProfile', user.username)
          .then((profile: Profile) => {
            const sius = user.signInUserSession;
            const groups = sius && sius.accessToken && sius.accessToken.payload && sius.accessToken.payload['cognito:groups'] ? sius.accessToken.payload['cognito:groups'] : [];
            const { attributes } = user;
            const preferredUsername = attributes ? attributes.preferred_username : null;
            const username = user ? user.username : null;
            const profileIcon = profile ? profile.profileIcon : '';
            const groupImageViewOption = profile ? profile.groupImageViewOption : ViewOption.NONE;
            const imageViewOption = profile ? profile.imageViewOption : ViewOption.NONE;
            const isOfficial = profile ? profile.isOfficial : false;
            const isVerified = profile ? profile.isVerified : false;

            context.commit('setUser', {groupImageViewOption, groups, imageViewOption, isOfficial, isVerified, preferredUsername, username, profileIcon});
          });
      } catch {
        context.commit('setUser', {groupImageViewOption: null, groups: [], imageViewOption: null, isOfficial: null, isVerified: null, preferredUsername: null, username: null, profileIcon: null});
      }
    },
    async signOut(context: any) {
      try {
        await Auth.signOut();
        context.commit('setUser', {});
      } catch {
        // Silent...
      }
    },
    signUp(context: any, loginInfo: BasicLogin) {
      return new Promise((resolve, reject) => {
        try {
          resolve(context.dispatch('serverSignUp', loginInfo));
        } catch(err) {
          reject(err);
        }
      });
    },
  },
  mutations: {
    setIsLogInModalActive(state: any, isOpen: boolean) {
      state.isLogInModalActive = isOpen;
    },
    setIsSignUpModalActive(state: any, isOpen: boolean) {
      state.isSignUpModalActive = isOpen;
    },
    setProfileProgress(state: any, progress: number) {
      state.profileProgress = progress < 0 ? 0 : progress;
    },
    setUser(state: any, { groupImageViewOption, groups, imageViewOption, isOfficial, isVerified, preferredUsername, username, profileIcon }: any) {
      if (username == null) state.user = null;
      else {
        try {
          state.user = new User({
            groupImageViewOption,
            groups,
            imageViewOption,
            isOfficial,
            isVerified,
            preferredUsername,
            profileIcon,
            username,
          });
        } catch {
          state.user = null;
        }
      }
    },
  },
  state: {
    isLogInModalActive: false,
    isSignUpModalActive: false,
    profileProgress: 0,
    user: null,
  },
};

export default Login;
