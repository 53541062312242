























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { anchor } from '@/utility/main';
import ListenButton from '@/components/profile/ListenButton.vue';

@Component({
  components: {
    ListenButton,
  },
})
export default class HeaderInfo extends Vue {
  @Prop({default: false}) cannotRemove!: boolean;
  @Prop({default: ''}) fullName!: string;
  @Prop({default: false}) hasReported!: boolean;
  @Prop({required: true}) isFollowing!: boolean;
  @Prop({default: true}) isLargeScreen!: boolean;
  @Prop({default: false}) isLoggedIn!: boolean;
  @Prop({default: false}) isOfficial!: boolean;
  @Prop({default: false}) isVerified!: boolean;
  @Prop({default: ''}) message!: string;
  @Prop({required: true}) username!: string;

  linkedMsg: string = '';

  get reportedTitle() {
    return this.hasReported ? 'Reported' : 'Report';
  }

  created() {
    this.linkedMsg = anchor(this.message).replace(/(\n)\n+/g, '<br><br>');
  }

  isListening(isListening: boolean) {
    this.$emit('is-following', isListening);
  }

  reportProfile() {
    if (this.hasReported) return;
    this.$store.dispatch('openReportProfileModal', this.username);
  }
}
